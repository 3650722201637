import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { InvoiceDynamicConfig } from '../invoice.dynamic-config';
import { InvoiceStatusService } from '../services/invoice-status.service';
import { IInvoice } from '../../model/interfaces/invoice';
import { ICustomer } from '../../model/interfaces/customer';
import { IInvoiceStatus } from '../../model/interfaces/invoice-status';
import { CustomerService } from '../../customers/services/customer.service';
import { CustomerInvoiceService } from '../services/customer-invoice.service';
import { UserService } from '../../users/user.service';
import { UserRoles } from '../../model/UserRoles';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-customer-invoice-basic-info',
    templateUrl: './customer-invoice-basic-info.component.html',
})
export class CustomerInvoiceBasicInfoComponent implements OnInit {
    @Input() invoice: IInvoice;
    @Input() canEdit: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    adminView = true;
    isEditing = false;
    isHovered: boolean;

    formFactory: InvoiceDynamicConfig<IInvoice>;
    doubleClickIsDisabled = false;

    customers: ICustomer[];
    statuses: IInvoiceStatus[];

    get isNewInvoice(): boolean {
        return this.invoice && this.invoice.Id ? false : true;
    }

    constructor(
        private invoiceService: CustomerInvoiceService,
        private invoiceStatusService: InvoiceStatusService,
        private notificationsService: NotificationsService,
        private router: Router,
        private customerInvoiceService: CustomerInvoiceService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.userService.getMyUser().subscribe((result) => {
            if (result.AuthUser.RoleId !== UserRoles.Administrator) {
                this.adminView = false;
            }
        });
        this.invoiceStatusService.getItems().subscribe((answers) => {
            this.statuses = answers;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new InvoiceDynamicConfig<IInvoice>(this.invoice, this.statuses);

        if (this.isNewInvoice) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewInvoice) {
            void this.router.navigate(['/customer-invoices']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.invoice, form.value.Invoice as IInvoice);
            this.saveInvoice();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveInvoice(): void {
        if (this.isNewInvoice) {
            this.invoiceService.create(this.invoice).subscribe((answer) => {
                this.invoice.Id = answer;
                this.success(true);
            });
        } else {
            this.invoiceService.update(this.invoice).subscribe((success) => {
                this.success();
            });
        }
    }

    private success(newInvoiceSave?: boolean): void {
        if (newInvoiceSave) {
            void this.router.navigate([`/customer-invoices/${this.invoice.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.invoiceService.emitChange(this.invoice);
        this.notificationsService.success('Invoice saved successfully.');
    }

    payInvoice(): void {
        this.customerInvoiceService.setSelectedInvoiceIdsForPayment([this.invoice.Id]);
        void this.router.navigate(['/invoice-payment']);
    }

    disputeInvoice(): void {
        void this.router.navigate(['/customer-invoices/dispute', this.invoice.Id]);
    }
}
