import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, ISelectionChangedEvent, IColumnSelectedEvent } from '@mt-ng2/entity-list-module';

import { InvoiceStatusService } from '../services/invoice-status.service';
import { CustomerInvoicesEntityListConfig } from './customer-invoices.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { IInvoice } from '../../model/interfaces/invoice';
import { CustomerInvoiceService } from '../services/customer-invoice.service';
import { InvoiceStatuses } from '../../model/InvoiceStatuses';
import { CustomerContactGroupsService } from '../../common/services/customer-contact-groups.service';
import { CustomerContactLocationsService } from '../../common/services/customer-contact-locations.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ICustomerInvoicePaymentDetail } from '../../model/partials/customer-invoice-payment-detail';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { CustomerService } from '../../customers/services/customer.service';
import { UserService } from '../../users/user.service';
import { UserRoles } from '../../model/UserRoles';
import { AdminCustomerInvoicesEntityListConfig } from './admin-customer-invoices.entity-list-config';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';

@Component({
    selector: 'app-invoices',
    templateUrl: './customer-invoices.component.html',
})
export class CustomerInvoicesComponent implements OnInit {
    invoices: IInvoice[];
    selectedInvoices: IInvoice[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    statuses: MtSearchFilterItem[] = [];
    locations: MtSearchFilterItem[] = [];
    groups: MtSearchFilterItem[] = [];
    adminView = false;
    entityListConfig = new CustomerInvoicesEntityListConfig();
    adminEntityListConfig = new AdminCustomerInvoicesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    startDate: Date = null;
    endDate: Date = null;
    paymentDialog: IModalWrapperApi;
    selectedInvoice: IInvoice;
    invoicePaymentDetails: ICustomerInvoicePaymentDetail[];
    customers: MtSearchFilterItem[] = [];
    associatedCompanyId = 0;

    canAddInvoice = false;

    constructor(
        private invoiceService: CustomerInvoiceService,
        private invoiceStatusService: InvoiceStatusService,
        private claimsService: ClaimsService,
        private router: Router,
        private route: ActivatedRoute,
        private customerContactGroupsService: CustomerContactGroupsService,
        private customerContactLocationsService: CustomerContactLocationsService,
        private customerService: CustomerService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.canAddInvoice = this.claimsService.hasClaim(ClaimTypes.Invoices, [ClaimValues.FullAccess]);
        this.userService.getMyUser().subscribe((result) => {
            if (result.AuthUser.RoleId === UserRoles.Administrator) {
                this.adminView = true;
            }
            if (result.CompanyId || this.adminView) {
                this.associatedCompanyId = result.CompanyId;
                this.customerService.getMyCustomersFilterItems().subscribe((result) => {
                    this.customers = result;
                });
            } else {
                this.getCustomerGroupsFilterItems();
                this.getCustomerLocationFilterItems();
            }
        });
        this.invoiceStatusService.getSearchFilterItems().subscribe((answer) => {
            this.statuses = answer;
            this.setDefaultSearchStatuses();
            this.getInvoices();
        });
    }

    public companyDropdownChange(): void {
        this.groups = [];
        this.locations = [];
        this.getCustomerGroupsFilterItems();
        this.getCustomerLocationFilterItems();
        this.getInvoices();
    }

    public getCustomerGroupsFilterItems(): void {
        const selectedCustomerIds: number[] = this.getSelectedFilters(this.customers);
        this.customerContactGroupsService.getSearchFilterItems(selectedCustomerIds).subscribe((answer) => {
            this.groups = answer;
        });
    }

    public getCustomerLocationFilterItems(): void {
        const selectedCustomerIds: number[] = this.getSelectedFilters(this.customers);
        const selectedGroupIds: number[] = this.getSelectedFilters(this.groups);

        this.customerContactLocationsService.getSearchFilterItems({ CustomerIds: selectedCustomerIds, GroupIds: selectedGroupIds }).subscribe((answer) => {
            this.locations = answer;
            this.filterSelectionChanged();
        });
    }

    private setDefaultSearchStatuses(): void {
        this.statuses.forEach((status) => {
            if (status.Item.Id === InvoiceStatuses.PartiallyPaid || status.Item.Id === InvoiceStatuses.Open) {
                status.Selected = true;
            }
        });
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.getInvoices();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedStatusIds: number[] = this.getSelectedFilters(this.statuses);
        const selectedCustomerIds: number[] = this.getSelectedFilters(this.customers);
        const selectedGroupIds: number[] = this.getSelectedFilters(this.groups);
        const selectedLocationIds: number[] = this.getSelectedFilters(this.locations);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: selectedStatusIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: selectedCustomerIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'GroupIds',
                valueArray: selectedGroupIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'LocationIds',
                valueArray: selectedLocationIds,
            }),
        );

        if (this.startDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StartDate',
                    value: this.startDate.toDateString(),
                }),
            );
        }

        if (this.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'EndDate',
                    value: this.endDate.toDateString(),
                }),
            );
        }

        return _extraSearchParams;
    }

    getSearchParams(): SearchParams {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return searchparams;
    }

    getInvoices(): void {
        const searchParams = this.getSearchParams();
        this.invoiceService.get(searchParams).subscribe((answer) => {
            this.invoices = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    downloadCSVExport(): void {
        const searchParams = this.getSearchParams();
        searchParams.skip = null;
        searchParams.take = null;
        this.invoiceService.getCSV(searchParams).subscribe((response) => {
            const mimeType = 'text/csv;charset=utf-8';
            const blob = new Blob([response], { type: mimeType });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'invoices.csv';
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getInvoices();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getInvoices();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getInvoices();
    }

    invoiceSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/customer-invoices', event.entity.Id]);
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10))  ? null : parseInt(id, 10);
    }

    payInvoices(): void {
        this.invoiceService.setSelectedInvoiceIdsForPayment(this.selectedInvoices.map((i) => i.Id));
        void this.router.navigate(['/invoice-payment']);
    }

    selectionChanged(selectionChanedEvent: ISelectionChangedEvent): void {
        this.selectedInvoices = selectionChanedEvent.selectedEntities as any;
    }

    viewPayments(event: IColumnSelectedEvent): void {
        const invoice = event.entity;
        if (invoice) {
            this.selectedInvoice = invoice;
            this.paymentDialog.show();
            this.invoicePaymentDetails = [];
            this.invoiceService.getInvoicePaymentDetails(invoice.Id as number).subscribe((result) => {
                this.invoicePaymentDetails = result;
            });
        }
    }
}
