import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { ICustomer } from '../interfaces/customer';
import { ICompany } from '../interfaces/company';

export interface ICustomerDynamicControlsParameters {
    formGroup?: string;
    companies?: ICompany[];
}

export class CustomerDynamicControls {
    formGroup: string;
    companies?: ICompany[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private customer?: ICustomer,
        additionalParameters?: ICustomerDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'Customer';
        this.companies = additionalParameters?.companies ?? undefined;
        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.customer?.Name?.toString() || '',
            }),
            CustomerNo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer No',
                name: 'CustomerNo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.customer?.CustomerNo?.toString() || '',
            }),
            ServiceFrequency: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Frequency',
                name: 'ServiceFrequency',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 15 },
                validation: [Validators.maxLength(15)],
                value: this.customer?.ServiceFrequency?.toString() || '',
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customer?.Archived || false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customer?.DateCreated || null,
            }),
            CompanyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company',
                name: 'CompanyId',
                options: this.companies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customer?.CompanyId || null,
            }),
        };
        this.View = {
            Name: new DynamicLabel({
                label: 'Name',
                value: this.customer?.Name?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerNo: new DynamicLabel({
                label: 'Customer No',
                value: this.customer?.CustomerNo?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ServiceFrequency: new DynamicLabel({
                label: 'Service Frequency',
                value: this.customer?.ServiceFrequency?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.customer?.Archived || false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.customer?.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            CompanyId: new DynamicLabel({
                label: 'Company',
                value: getMetaItemValue(this.companies as unknown as IMetaItem[], this.customer?.CompanyId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
