import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CustomerPaymentSourceService } from '../services/customer-payment-source.service';
import { environment } from '../../environments/environment';
import { PaymentTypes } from '../../model/PaymentTypes';

@Component({
    templateUrl: './customer-payment-source-detail.component.html',
})
export class CustomerPaymentSourceDetailComponent implements OnInit {
    customerPaymentSource: ICustomerPaymentSource;
    canEdit: boolean;
    canAdd: boolean;
    paymentTypes = PaymentTypes;

    constructor(
        private customerPaymentSourceService: CustomerPaymentSourceService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CustomerPaymentSources, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'customerPaymentSourceId');
        if (id) {
            this.getCustomerPaymentSourceById(id);
        } else {
            void this.router.navigate(['customer-payment-sources']); // if no id found, go back to list
        }
    }

    getCustomerPaymentSourceById(id: number): void {
        this.customerPaymentSourceService.getById(id).subscribe((customerPaymentSource) => {
            if (customerPaymentSource === null) {
                this.notificationsService.error('CustomerPaymentSource not found');
                void this.router.navigate(['customer-payment-sources']);
            }
            this.customerPaymentSource = customerPaymentSource;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10))  ? null : parseInt(id, 10);
    }

    navigateToPayaVault(): void {
        this.customerPaymentSourceService.updatePaymentSource(this.customerPaymentSource.Id).subscribe((result) => {
            if (result && result.Id) {
                const form = document.createElement('form');
                const element1 = document.createElement('input');
                form.method = 'POST';
                form.action = 'https://www.sageexchange.com/sevd/frmpayment.aspx';
                element1.value = result.PayaXML;
                element1.name = 'request';
                form.appendChild(element1);
                const element2 = document.createElement('input');
                element2.value = environment.baseApiUrl + `/api/v1/customer-payment-sources/${result.Id}/updatePayaVaultResponse`;
                element2.name = 'redirect_url';
                form.appendChild(element2);
                document.body.appendChild(form);
                form.submit();
            }
        });
    }
}
