/* eslint-disable @typescript-eslint/unbound-method */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IHasAddresses } from '@mt-ng2/entity-components-addresses';
import { IAddress } from '@mt-ng2/dynamic-form';
import { IAddressContainer } from '@model/interfaces/base';

@Injectable()
export class CustomerAddressesService extends BaseService<IAddress> implements IHasAddresses {
    constructor(public http: HttpClient) {
        super('/customers', http);
    }

    getAddresses(customerId: number, searchparameters: SearchParams): Observable<HttpResponse<IAddressContainer[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IAddressContainer[]>(`/customers/${customerId}/addresses/_search`, { observe: 'response', params: params })
            .pipe(catchError(this.handleError));
    }

    saveAddress(customerId: number, address: IAddressContainer): Observable<number> {
        if (!address.AddressId) {
            address.AddressId = 0;
            address.Address.Id = 0;
            return this.http.post<number>(`/customers/${customerId}/addresses`, address);
        } else {
            return this.http.put<number>(`/customers/${customerId}/addresses/${address.AddressId}`, address, { responseType: 'text' as 'json' });
        }
    }

    deleteAddress(customerId: number, addressId: number): Observable<object> {
        return this.http.delete(`/customers/${customerId}/addresses/${addressId}`, { responseType: 'text' as 'json' });
    }
}
