<div class="row">
    <br>
    <div class="col-md-12">
        <div class="text-center">
            <h2>Dispute Invoice</h2>
        </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <ngx-loading [show]="isLoading"></ngx-loading>
        <div class="miles-editable miles-card padded" *ngIf="abstractCustomerDisputeInvoiceControls && disputeInvoiceForm">
            <h4>Dispute Info</h4>
            <form [formGroup]="disputeInvoiceForm" (ngSubmit)="formSubmitted();">
                <mt-dynamic-field [field]="abstractCustomerDisputeInvoiceControls.CustomerName" [form]="disputeInvoiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerDisputeInvoiceControls.LocationName" [form]="disputeInvoiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerDisputeInvoiceControls.CustomerEmail" [form]="disputeInvoiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerDisputeInvoiceControls.CustomerPhone" [form]="disputeInvoiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerDisputeInvoiceControls.Reason" [form]="disputeInvoiceForm"></mt-dynamic-field>
                <br />
                <div>
                    <button type="submit" class="btn btn-primary" [disabled]="isLoading">Submit Dispute</button>
                    <button class="btn btn-default" (click)="cancel()" [disabled]="isLoading">Cancel</button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-3"></div>
</div>