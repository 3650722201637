import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';
import { UserService } from '../user.service';
import { IUser } from '@model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { AuthEntityService } from '../../auth-entity/auth-entity.service'; // added
import { IUserRole } from '@model/interfaces/user-role'; // added
import { IAuthUser } from '@model/interfaces/auth-user'; // added
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { UntypedFormGroup } from '@angular/forms';
@Component({
    selector: 'app-user-basic-info',
    templateUrl: './user-basic-info.component.html',
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;

    authUser: IAuthUser;
    roles: IUserRole[];
    additionalConfigs: any[] = [];
    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    userForm: any;
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private authEntitiyService: AuthEntityService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,

        private location: Location,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        if (this.isNewUser()) {
            this.authEntitiyService
                .getAllRoles() // added
                .subscribe((answer) => {
                    this.roles = answer.body;
                    this.setConfig();
                });
        } else {
            this.setConfig();
        }
    }

    private isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const authConfigControls: string[] = ['Password', 'ConfirmPassword', 'SendResetEmail', 'RoleId'];
        const authUser = this.isNewUser() ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, this.roles, authConfigControls);
        return [pwConfig];
    }

    setConfig(): void {
        this.formFactory = new UserDynamicConfig<IUser>(this.user);
        this.additionalConfigs = this.getAdditionalConfigs();
        if (this.isNewUser()) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate(this.additionalConfigs);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewUser()) {
            this.location.back();
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        let passwordmatch = true;
        if (this.isNewUser() && !this.authService.matchPassword(form)) {
            passwordmatch = false;
        }

        if (form.valid && passwordmatch) {
            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            this.saveForm(form);
        } else if (!passwordmatch) {
            this.error('Passwords do not match');
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    private saveForm(form): void {
        if (this.isNewUser()) {
            const data: ICreateUserPayload = {
                GrantAccess: true,
                Password: form.value.AuthUser.Password,
                SendEmail: form.value.AuthUser.SendResetEmail || false,
                User: this.user,
                UserRoleId: form.value.AuthUser.RoleId,
            };
            // handle new user save
            this.userService.createUser(data).subscribe((user: IUser) => {
                void this.router.navigate(['users', user.Id]);
                this.userService.emitChange(this.user);
                this.success();
            });
        } else {
            // handle existing user save
            this.userService.updateVersion(this.user).subscribe((answer) => {
                answer
                    ? ((this.user.Version = answer),
                      (this.isEditing = false),
                      this.success(),
                      this.userService.emitChange(this.user),
                      this.setConfig())
                    : this.error();
            });
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    updateVersion(version): void {
        this.user.Version = version;
    }

    onDelete(): void {
        this.userService.ArchiveUser(this.user.Id).subscribe(
            () => {
                this.notificationsService.success('User successfully deleted!');
                this.user.IsArchived = true;
                this.isEditing = false;
                this.userService.emitChange(this.user);
                this.location.back();
            },
            () => {
                this.notificationsService.error('An error occurred while deleting the user.');
            },
        );
    }
}
