import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { ICustomerGroup } from '../interfaces/customer-group';
import { ICustomer } from '../interfaces/customer';

export interface ICustomerGroupDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
}

export class CustomerGroupDynamicControls {
    formGroup: string;
    customers?: ICustomer[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private customergroup?: ICustomerGroup,
        additionalParameters?: ICustomerGroupDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'CustomerGroup';
        this.customers = additionalParameters?.customers ?? undefined;
        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.customergroup?.Name?.toString() || '',
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.customergroup?.CustomerId || null,
            }),
        };
        this.View = {
            Name: new DynamicLabel({
                label: 'Name',
                value: this.customergroup?.Name?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.customergroup?.CustomerId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
