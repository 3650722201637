import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomer } from '@model/interfaces/customer';
import { Observable } from 'rxjs';
import { IUser } from '../../model/interfaces/user';
import { ICustomerLocation } from '../../model/interfaces/customer-location';
import { ICustomerGroup } from '../../model/interfaces/customer-group';
import { map } from 'rxjs/operators';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

export const emptyCustomer: ICustomer = {
    Archived: false,
    Id: 0,
    Name: null,
    Version: null,
};

@Injectable()
export class CustomerService extends BaseService<ICustomer> {
    constructor(public http: HttpClient) {
        super('/customers', http);
    }

    getEmptyCustomer(): ICustomer {
        return { ...emptyCustomer };
    }

    getContacts(customerId: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/customers/${customerId}/contacts`);
    }

    getLocations(customerId: number): Observable<ICustomerLocation[]> {
        return this.http.get<ICustomerLocation[]>(`/customers/${customerId}/locations`);
    }

    getGroups(customerId: number): Observable<ICustomerGroup[]> {
        return this.http.get<ICustomerGroup[]>(`/customers/${customerId}/groups`);
    }

    getMyCustomers(): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/customers/me`);
    }

    getMyCustomersFilterItems(): Observable<MtSearchFilterItem[]> {
        return this.getMyCustomers().pipe(
            map((items) => {
                return items.map((item) => new MtSearchFilterItem(<any>item, false));
            }),
        );
    }
}
