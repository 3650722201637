import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { ICompany } from '../../model/interfaces/company';
import { CompanyService } from '../services/company.service';

@Component({
    templateUrl: './company-header.component.html',
})
export class CompanyHeaderComponent implements OnInit {
    header$: Observable<string>;
    readonly companyService = inject(CompanyService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('companyId');
        this.companyService.setInitialTitle(id);
        this.header$ = this.companyService.title$;
    }
}
