import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ICompany } from '../../model/interfaces/company';
import { ICustomer } from '../../model/interfaces/customer';
import { IUser } from '../../model/interfaces/user';

export const emptyCompany: ICompany = {
    Id: 0,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class CompanyService extends BaseService<ICompany> {
    constructor(public http: HttpClient) {
        super('/companies', http, null, {
            entityName: 'Company',
        });
    }

    formatTitleText(entity: ICompany): void {
        this.setTitle(`Company: ${entity.Name}`);
    }

    getEmptyCompany(): ICompany {
        return { ...emptyCompany };
    }

    getContacts(companyId: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/companies/${companyId}/contacts`);
    }

    getCustomers(companyId: number): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/companies/${companyId}/customers`);
    }

    removeCustomer(companyId: number, customerId: number): Observable<number> {
        return this.http.delete<number>(`/companies/${companyId}/customers/${customerId}`);
    }

    addCustomers(companyId: number, ids: number[]): Observable<any> {
        return this.http.post<any>(`/companies/${companyId}/customers`, ids);
    }

    getCustomersForCompanyDropdown(searchparameters: SearchParams): Observable<ICustomer[]> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<ICustomer[]>(`/customers/company-dropdown`, {
                params: params,
            })
            // eslint-disable-next-line @typescript-eslint/unbound-method
            .pipe(catchError(this.handleError));
    }
}
