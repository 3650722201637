import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IInvoicePaymentDetailPartial } from '../../model/partials/invoice-payment-detail';
import { InvoicePaymentStateService } from '../services/invoice-payment-state.service';

@Component({
    styles: [
        `
            tr > th {
                background-color: white !important;
                font-size: 15px;
                padding-bottom: 15px;
                padding-top: 20px;
            }
            tfoot tr td {
                padding-top: 25px !important;
                padding-bottom: 25px !important;
            }
            tbody tr td {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
                border: none !important;
            }
            tbody {
                display: block;
                overflow-y: auto;
                overflow-x: hidden;
                margin-bottom: 30px;
            }
            thead,
            tbody tr,
            tfoot {
                display: table;
                width: 100%;
                table-layout: fixed; /* even columns width , fix width of table too*/
            }
            .right-align {
                text-align: end !important;
            }
            .no-invoices-text {
                padding: 25px;
                font-size: 22px;
            }
        `,
    ],
    templateUrl: './payment-success-future.component.html',
})
export class PaymentSuccessFutureComponent implements OnInit {
    date: Date;
    amount: number;
    invoicePaymentDetails: IInvoicePaymentDetailPartial[];

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private invoicePaymentStateService: InvoicePaymentStateService) {}

    ngOnInit(): void {
        const savedPayment = this.invoicePaymentStateService.getSavedPayment();
        if (savedPayment) {
            this.amount = savedPayment.paymentDetails.map((pd) => pd.Amt).reduce((a, b) => a + b);
            this.date = savedPayment.paymentDate;
            this.invoicePaymentDetails = savedPayment.paymentDetails;
        }
        this.invoicePaymentStateService.clear();
    }

    navigateToDashboard(): void {
        void this.router.navigate(['/customer-dashboard']);
    }
}
