import { formatCurrency } from '@angular/common';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, SelectTypes } from '@mt-ng2/entity-list-module';
import { IInvoice } from '../../model/interfaces/invoice';
import { CustomerInvoicesListActionComponent } from './customer-invoices-list-action.component';

export class AdminCustomerInvoicesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['InvoiceNo'],
                    name: 'Invoice No',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerLocation', 'Customer', 'Name'],
                    name: 'Customer Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerLocation', 'Customer', 'CustomerNo'],
                    name: 'Customer Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (invoice: IInvoice): string {
                        return invoice.CustomerLocation ? invoice.CustomerLocation.Name : '';
                    },
                    name: 'Location',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerLocation', 'SageAccountNumber'],
                    name: 'Sage Account Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['ServiceDate'],
                    name: 'Service Date',
                    pipes: ['date:mediumDate:UTC'],
                }),
                new EntityListColumn({
                    accessors: ['InvoiceDate'],
                    name: 'Invoice Date',
                    pipes: ['date:mediumDate:UTC'],
                    sort: {
                        defaultDirection: SortDirection.Desc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (i) => formatCurrency(i.Total as number || 0, 'en_US', '$'),
                    name: 'Total',
                }),
                new EntityListColumn({
                    accessorFunction: (i) => formatCurrency(i.Balance as number|| 0, 'en_US', '$'),
                    name: 'Balance',
                }),
                new EntityListColumn({
                    accessorFunction: (i) => i.PaymentTotal !== 0 ? '<a>' + formatCurrency(i.PaymentTotal as number|| 0, 'en_US', '$') + '</a>' : '',
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Payment Amount',
                }),
                new EntityListColumn({
                    accessorFunction: function (invoice: IInvoice): string {
                        return invoice.InvoiceStatus ? invoice.InvoiceStatus.Name : '';
                    },
                    name: 'Status',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
