import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { IUserRole } from '../interfaces/user-role';

export interface IUserRoleDynamicControlsParameters {
    formGroup?: string;
}

export class UserRoleDynamicControls {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private userrole?: IUserRole,
        additionalParameters?: IUserRoleDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'UserRole';

        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.userrole?.Name?.toString() || '',
            }),
            Description: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description',
                name: 'Description',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 500 },
                validation: [Validators.maxLength(500)],
                value: this.userrole?.Description?.toString() || '',
            }),
            IsEditable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Editable',
                name: 'IsEditable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.userrole?.IsEditable || true,
            }),
        };
        this.View = {
            Name: new DynamicLabel({
                label: 'Name',
                value: this.userrole?.Name?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Description: new DynamicLabel({
                label: 'Description',
                value: this.userrole?.Description?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsEditable: new DynamicLabel({
                label: 'Is Editable',
                value: this.userrole?.IsEditable || true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
