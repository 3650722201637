import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../../model/interfaces/customer';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { common } from '@mt-ng2/common-functions';
import { ICustomerLocation } from '../../../model/interfaces/customer-location';
import { CustomerLocationsService } from '../../services/customer-locations.service';
import { CustomerLocationDynamicConfig } from '../customer-location.dynamic-config';
import { switchMap } from 'rxjs/operators';
import { AddressService } from '../../../common/services/address.service';
import { AddressDynamicConfig } from '../../../common/configs/address.dynamic.config';
import { IAddress } from '@model/interfaces/address';
import { UserService } from '../../../users/user.service';
import { IAuthUser } from '../../../model/interfaces/auth-user';
import { forkJoin } from 'rxjs';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-customer-locations-info',
    templateUrl: './customer-locations-info.component.html',
})
export class CustomerLocationsInfoComponent implements OnInit {
    @Input('customer') customer: ICustomer;
    @Input('canEdit') canEdit: boolean;

    isEditing = false;
    location: ICustomerLocation;
    showTitle = false;
    searchControl = new UntypedFormControl();
    locations: ICustomerLocation[];
    currentPage = 1;
    query = '';
    total: number;
    canAddLocation = false;
    parentId: number;

    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    locationForm: any;
    formFactory: CustomerLocationDynamicConfig<ICustomerLocation>;
    address: IAddress;
    currentAuthUser: IAuthUser;

    constructor(
        private locationService: CustomerLocationsService,
        private customerService: CustomerService,
        private claimsService: ClaimsService,
        private addressService: AddressService,
        private notificationsService: NotificationsService,
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        this.canAddLocation = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.getAuthUserAndLocations();

    }

    private isNewLocation(): boolean {
        return this.location && this.location.Id && this.location.Id > 0 ? false : true;
    }

    getAuthUserAndLocations(): void {
        forkJoin([this.customerService.getLocations(this.customer.Id), this.userService.getMyUser()]).subscribe((result) => {
            this.locations = result[0];
            this.currentAuthUser = result[1].AuthUser;
        });
    }

    setConfig(): void {
        if (!this.currentAuthUser) {
            return;
        }

        this.formFactory = new CustomerLocationDynamicConfig<ICustomerLocation>(this.location, this.currentAuthUser);

        const addConfig = this.getAdditionalConfigs();
        if (this.isNewLocation()) {
           const config = this.formFactory.getForCreate(addConfig);
           this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
           this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate(addConfig);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        }

    }

    getAdditionalConfigs(): AddressDynamicConfig<IAddress>[] {
        this.location.Address = this.location.Address || this.addressService.getEmptyAddress();
        const addressConfig = new AddressDynamicConfig<IAddress>(this.location.Address);
        return [addressConfig];
    }

    locationSelected(location: ICustomerLocation): void {
        this.location = location ? location : this.locationService.getEmptyCustomerLocation();
        this.setConfig();
        this.isEditing = true;
    }

    openLocationsList(): void {
       void this.router.navigate([this.router.url, 'locations']);
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.location, form.value.CustomerLocation as ICustomerLocation);
            this.assignFormValues(this.location.Address, form.value.Address as IAddress);
            this.saveLocation();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveLocation(): void {
        if (this.isNewLocation()) {
            this.location.CustomerId = this.customer.Id;
            this.addressService
                .create(this.location.Address)
                .pipe(
                    switchMap((addressId) => {
                        this.location.AddressId = this.location.Address.Id = addressId;
                        return this.locationService.create(this.location);
                    }),
                )
                .subscribe((answer) => {
                    this.location.Id = answer;
                    this.locations.push(this.location);
                    this.isEditing = false;
                    this.success();
                    this.locationService.emitChange(this.location);
                });
        } else {
            this.addressService
                .update(this.location.Address)
                .pipe(switchMap(() => this.locationService.update(this.location)))
                .subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.locationService.emitChange(this.location);
                    this.setConfig();
                });
        }
    }

    assignFormValues(object: IAddress, formValue: IAddress): void {
        for (const prop in formValue) {
            if (Object.prototype.hasOwnProperty.call(object, prop)) {
                object[prop] = formValue[prop];
            }
        }
    }

    error(msg?: string): void {
        msg = msg || 'Save failed. Please check the form and try again.';
        this.notificationsService.error(msg);
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
