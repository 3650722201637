import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { IInvoicePayment } from '@model/interfaces/invoice-payment';
import { AdminInvoicePaymentService } from '../services/admin-invoice-payment.service';

@Component({
    templateUrl: './invoice-payment-header.component.html',
})
export class InvoicePaymentHeaderComponent implements OnInit {
    header$: Observable<string>;
    readonly invoicePaymentService = inject(AdminInvoicePaymentService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('invoicePaymentId');
        this.invoicePaymentService.setInitialTitle(id);
        this.header$ = this.invoicePaymentService.title$;
    }
}
