import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { CustomerPaymentSourceAddComponent } from './customer-payment-source-add/customer-payment-source-add.component';
import { CustomerPaymentSourceDetailComponent } from './customer-payment-source-detail/customer-payment-source-detail.component';
import { CustomerPaymentSourcesComponent } from './customer-payment-source-list/customer-payment-sources.component';
import { CustomerPaymentSourceService } from './services/customer-payment-source.service';
import { CustomerPaymentSourceHeaderComponent } from './customer-payment-source-header/customer-payment-source-header.component';
import { ClaimTypes } from '../model/ClaimTypes';

const customerPaymentSourceEntityConfig = {
    claimType: ClaimTypes.CustomerPaymentSources,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'customerPaymentSourceId',
    service: CustomerPaymentSourceService,
    title: 'CustomerPaymentSource Detail',
};

const customerPaymentSourceListRoleGuard: any = {
    claimType: ClaimTypes.CustomerPaymentSources,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'CustomerPaymentSources',
};

const customerPaymentSourceAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.CustomerPaymentSources,
    claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
};

export const customerPaymentSourcePaths = {
    customerPaymentSources: 'customer-payment-sources',
    customerPaymentSourcesAdd: 'customer-payment-sources/add',
};

const customerPaymentSourceRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CustomerPaymentSourcesComponent,
        data: customerPaymentSourceListRoleGuard,
        path: customerPaymentSourcePaths.customerPaymentSources,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: CustomerPaymentSourceAddComponent, path: '', pathMatch: 'full' }],
        component: CustomerPaymentSourceHeaderComponent,
        data: customerPaymentSourceAddRoleGuard,
        path: customerPaymentSourcePaths.customerPaymentSourcesAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: CustomerPaymentSourceDetailComponent, path: '', pathMatch: 'full' }],
        component: CustomerPaymentSourceHeaderComponent,
        data: customerPaymentSourceEntityConfig,
        path: `customer-payment-sources/:${customerPaymentSourceEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(customerPaymentSourceRoutes)],
})
export class CustomerPaymentSourceRoutingModule {}
