import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';

export class CustomerPaymentSourcesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['PaymentSourceType', 'Name'],
                    name: 'Type',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        disableSort: true,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (p) => (p.OnlyUsedForRecurring ? 'Yes' : 'No'),
                    name: 'Only Used For Recurring',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
        this.delete = new EntityListDeleteColumn({
            text: 'Are you sure you want to delete this payment source?  Recurring payments using this source will also be deleted',
            title: 'Delete Payment Source',
        });
    }
}
