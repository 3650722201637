import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent } from '@mt-ng2/entity-list-module';

import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CustomerPaymentSourcesEntityListConfig } from './customer-payment-sources.entity-list-config';
import { CustomerPaymentSourceService } from '../services/customer-payment-source.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-customer-payment-sources',
    templateUrl: './customer-payment-sources.component.html',
})
export class CustomerPaymentSourcesComponent implements OnInit {
    customerPaymentSources: ICustomerPaymentSource[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new CustomerPaymentSourcesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddCustomerPaymentSource = false;

    constructor(
        private customerPaymentSourceService: CustomerPaymentSourceService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddCustomerPaymentSource = this.claimsService.hasClaim(ClaimTypes.CustomerPaymentSources, [
            ClaimValues.FullAccess,
            ClaimValues.ReadOnly,
        ]);
        this.getCustomerPaymentSources();
    }

    getCustomerPaymentSourcesCall(): Observable<HttpResponse<ICustomerPaymentSource[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.customerPaymentSourceService.get(searchparams);
    }

    getCustomerPaymentSources(): void {
        this.getCustomerPaymentSourcesCall().subscribe((answer) => {
            this.customerPaymentSources = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getCustomerPaymentSources();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getCustomerPaymentSources();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getCustomerPaymentSources();
    }

    customerPaymentSourceSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/customer-payment-sources', event.entity.Id]);
    }

    onItemDeleted(event: IItemDeletedEvent): void {
        this.customerPaymentSourceService.delete(event.entity.Id as number).subscribe(
            (answer) => {
                this.getCustomerPaymentSources();
                this.notificationsService.success(' Deleted');
            },
            (error) => {
                this.getCustomerPaymentSources();
                this.notificationsService.error('Delete Failed');
            },
        );
    }
}
