import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { ICustomerLocation } from '../interfaces/customer-location';
import { IAddress } from '../interfaces/address';
import { ICustomer } from '../interfaces/customer';
import { ICustomerGroup } from '../interfaces/customer-group';

export interface ICustomerLocationDynamicControlsParameters {
    formGroup?: string;
    addresses?: IAddress[];
    customers?: ICustomer[];
    customerGroups?: ICustomerGroup[];
}

export class CustomerLocationDynamicControls {
    formGroup: string;
    addresses?: IAddress[];
    customers?: ICustomer[];
    customerGroups?: ICustomerGroup[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private customerlocation?: ICustomerLocation,
        additionalParameters?: ICustomerLocationDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'CustomerLocation';
        this.addresses = additionalParameters?.addresses ?? undefined;
        this.customers = additionalParameters?.customers ?? undefined;
        this.customerGroups = additionalParameters?.customerGroups ?? undefined;
        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.customerlocation?.Name?.toString() || '',
            }),
            SageAccountNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sage Account Number',
                name: 'SageAccountNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.customerlocation?.SageAccountNumber?.toString() || '',
            }),
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customerlocation?.AddressId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.customerlocation?.CustomerId || null,
            }),
            CustomerGroupId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Group',
                name: 'CustomerGroupId',
                options: this.customerGroups,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customerlocation?.CustomerGroupId || null,
            }),
            LastServiceDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Service Date',
                name: 'LastServiceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customerlocation?.LastServiceDate || null,
            }),
            NextServiceDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Next Service Date',
                name: 'NextServiceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customerlocation?.NextServiceDate || null,
            }),
        };
        this.View = {
            Name: new DynamicLabel({
                label: 'Name',
                value: this.customerlocation?.Name?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            SageAccountNumber: new DynamicLabel({
                label: 'Sage Account Number',
                value: this.customerlocation?.SageAccountNumber?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            AddressId: new DynamicLabel({
                label: 'Address',
                value: getMetaItemValue(this.addresses as unknown as IMetaItem[], this.customerlocation?.AddressId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.customerlocation?.CustomerId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerGroupId: new DynamicLabel({
                label: 'Customer Group',
                value: getMetaItemValue(this.customerGroups as unknown as IMetaItem[], this.customerlocation?.CustomerGroupId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastServiceDate: new DynamicLabel({
                label: 'Last Service Date',
                value: this.customerlocation?.LastServiceDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            NextServiceDate: new DynamicLabel({
                label: 'Next Service Date',
                value: this.customerlocation?.NextServiceDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
        };
    }
}
