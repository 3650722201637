import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';

import { IUser } from '@model/interfaces/user';
import { IUserRole } from '@model/interfaces/user-role';
import { IAuthUser } from '@model/interfaces/auth-user';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { UserDynamicConfig } from '../../../users/user.dynamic-config';
import { UserService } from '../../../users/user.service';
import { AuthEntityService } from '../../../auth-entity/auth-entity.service';
import { AuthUserDynamicConfig } from '../../../auth-entity/auth-user/auth-user.dynamic-config';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-contact-basic-info',
    templateUrl: './contact-basic-info.component.html',
})
export class ContactBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;

    authUser: IAuthUser;
    roles: IUserRole[] = [];
    additionalConfigs: any[] = [];
    isEditing: boolean;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    userForm: any;
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    customerId: number;
    isNewUser: boolean;

    constructor(
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
    ) {}

    ngOnInit(): void {
        this.isNewUser = !this.user.Id;
        this.isEditing = false;
        this.customerId = +this.route.snapshot.parent.paramMap.get('customerId') || null;

        this.setViewConfig();
    }

    // This config is for view only -- the form will use the 'contact-info-form' component
    setViewConfig(): void {
        this.formFactory = new UserDynamicConfig<IUser>(this.user);
        this.additionalConfigs = this.getAdditionalConfigs();
        if (this.isNewUser) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate(this.additionalConfigs);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate(this.additionalConfigs);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        }
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const authConfigControls: string[] = ['SendResetEmail'];
        const authUser = this.isNewUser ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, this.roles, authConfigControls);
        return [pwConfig];
    }

    edit(): void {
        this.isEditing = this.canEdit;
    }

    cancel(): void {
        if (this.isNewUser) {
            this.location.back();
        } else {
            this.isEditing = false;
        }
    }

    success(user: IUser): void {
        if (this.isNewUser) {
            void this.router.navigate(['customers', this.customerId, 'contacts', user.Id]);
        } else {
            this.isEditing = false;
            this.setViewConfig();
        }
        this.isNewUser = false;
    }

    error(message = 'Save failed. Please check the form and try again.'): void {
        this.notificationsService.error(message);
    }
}
