import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IServiceType } from '../../model/interfaces/service-type';

@Injectable()
export class ServiceTypesService extends BaseService<IServiceType> {

  constructor(public http: HttpClient) {
    super('/service-types', http);
  }

}
