import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { IPhone } from '@model/interfaces/base';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IAddress } from '@model/interfaces/address';

@Injectable()
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        IsArchived: false,
        LastName: null,
        Title: '',
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    getMyUser(): Observable<IUser> {
        return this.http.get<IUser>('/users/me');
    }

    createUser(data: ICreateUserPayload): Observable<IUser> {
        return this.http.post<IUser>(`/users/create`, data);
    }

    updateVersion(user: IUser): Observable<number[]> {
        return this.http.put<number[]>(`/users/${user.Id}/version`, user);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/pic`);
    }

    ArchiveUser(userId: number): Observable<any> {
        return this.http.post('/users/archive', userId);
    }
}
