import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimTypes } from '@model/ClaimTypes';
import { entityListModuleConfig } from '@common/shared.module';
import { ICustomerLocation } from '../../../model/interfaces/customer-location';
import { CustomerLocationsService } from '../../services/customer-locations.service';
import { LocationsEntityListConfig } from './locations.entity-list-config';

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
})
export class LocationsComponent implements OnInit {
    searchControl = new UntypedFormControl();
    locations: ICustomerLocation[];
    currentPage = 1;
    query = '';
    total: number;
    canAddLocation = false;
    customerId: number;

    entityListConfig = new LocationsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(
        private locationService: CustomerLocationsService,
        private claimsService: ClaimsService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.canAddLocation = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.customerId = +this.route.snapshot.parent.paramMap.get('customerId');
        this.getLocations();
    }

    getLocations(): void {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerId',
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                value: '' + this.customerId,
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: this.query && this.query.length > 0 ? this.query : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.locationService.get(searchparams).subscribe((answer) => {
            this.locations = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getLocations();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getLocations();
    }

    locationSelected(event: IItemSelectedEvent): void {
        void this.router.navigate([this.router.url, event.entity.Id]);
    }
}
