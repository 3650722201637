/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { IInvoice } from '../../model/interfaces/invoice';
import { CustomerInvoiceService } from '../services/customer-invoice.service';
import { IDocument } from '../../model/interfaces/base';
import { saveAs } from 'file-saver';
import { FileItem } from 'ng2-file-upload';
import { InvoiceService } from '../../invoices/services/invoice.service';

@Component({
    selector: 'app-customer-admin-document',
    templateUrl: './customer-invoice-admin-document.component.html',
})
export class CustomerInvoiceAdminDocumentComponent implements OnInit {
    @Input() invoice: IInvoice;

    canAddDocuments = false;
    documentArray: IDocument[] = [];
    allowedMimeTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/zip', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    constructor(
        private customerInvoiceService: CustomerInvoiceService,
        private invoiceService: InvoiceService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canAddDocuments = this.claimsService.hasClaim(ClaimTypes.UploadInvoiceDocs, [ClaimValues.FullAccess]);
        if (this.canAddDocuments === undefined) {
            this.canAddDocuments = false;
        }
        if (this.invoice.AdminInvoiceDocuments) {
            this.documentArray = this.invoice.AdminInvoiceDocuments.map((ad) => ad.Document);
        }
    }

    downloadDocument(selectedDocument: IDocument): void {
        this.customerInvoiceService.getAdminDocument(selectedDocument.Id, this.invoice.Id).subscribe((result) => {
            const thefile = new Blob([<ArrayBuffer> result], {
                type: 'application/octet-stream',
            });
            saveAs(result, selectedDocument.Name);
        });
    }

    addInvoiceDocument(file: FileItem): void {
        this.invoiceService.uploadInvoiceDoc(this.invoice.Id, file._file).subscribe((result) => {
            this.documentArray.push(result);
        });
    }

    deleteDocument(selectedDocument: IDocument): void {
        this.invoiceService.deleteAdminDocument(this.invoice.Id, selectedDocument.Id).subscribe((result) => {
            this.documentArray = this.documentArray.filter((d) => d.Id !== selectedDocument.Id);
        });
    }

    validateFailed(message: string): void {
        this.notificationsService.error(message);
    }

}
