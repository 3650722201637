import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { CustomerPaymentSourceService } from '../services/customer-payment-source.service';

@Component({
    templateUrl: './customer-payment-source-add.component.html',
})
export class CustomerPaymentSourceAddComponent implements OnInit {
    customerpaymentsource: ICustomerPaymentSource;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private customerpaymentsourceService: CustomerPaymentSourceService) {}

    ngOnInit(): void {
        this.customerpaymentsource = this.customerpaymentsourceService.getEmptyCustomerPaymentSource();
    }
    navigateToPayaVault(): void {
        this.customerpaymentsourceService.createPaymentSource().subscribe((result) => {
            const form = document.createElement('form');
            const element1 = document.createElement('input');
            form.method = 'POST';
            form.action = 'https://www.sageexchange.com/sevd/frmpayment.aspx';
            element1.value = result.PayaXML;
            element1.name = 'request';
            form.appendChild(element1);

            const element2 = document.createElement('input');
            element2.value = environment.baseApiUrl + `/api/v1/customer-payment-sources/${result.Id}/postPayaVaultResponse`;
            element2.name = 'redirect_url';
            form.appendChild(element2);
            document.body.appendChild(form);
            form.submit();
        });
    }
}
