import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, switchMap } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { ICustomerLocation } from '../../../model/interfaces/customer-location';
import { CustomerLocationsService } from '../../services/customer-locations.service';
import { Location } from '@angular/common';
import { CustomerLocationDynamicConfig } from '../customer-location.dynamic-config';
import { AddressDynamicConfig } from '../../../common/configs/address.dynamic.config';
import { AddressService } from '../../../common/services/address.service';
import { IAddress } from '../../../model/interfaces/address';
import { IAuthUser } from '../../../model/interfaces/auth-user';
import { UserService } from '../../../users/user.service';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-location-basic-info',
    templateUrl: './location-basic-info.component.html',
})
export class LocationBasicInfoComponent implements OnInit {
    @Input() location: ICustomerLocation;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: CustomerLocationDynamicConfig<ICustomerLocation>;
    customerId: number;
    currentAuthUser: IAuthUser;

    get isNewLocation(): boolean {
        return this.location && this.location.Id ? false : true;
    }

    constructor(
        private locationService: CustomerLocationsService,
        private addressService: AddressService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private routeLocation: Location,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        this.customerId = +this.route.snapshot.parent.paramMap.get('customerId') || null;
        this.userService.getMyUser().subscribe((result) => {
            if (result && result.AuthUser) {
                this.currentAuthUser = result.AuthUser;
                this.setConfig();
            }
        });
    }

    setConfig(): void {
        this.formFactory = new CustomerLocationDynamicConfig<ICustomerLocation>(this.location, this.currentAuthUser);

        const addConfig = this.getAdditionalConfigs();
        if (this.isNewLocation) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate(addConfig);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate(addConfig);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new DynamicField(x));
        }
    }

    getAdditionalConfigs(): AddressDynamicConfig<IAddress>[] {
        this.location.Address = this.location.Address || this.addressService.getEmptyAddress();
        const addressConfig = new AddressDynamicConfig<IAddress>(this.location.Address);
        return [addressConfig];
    }

    edit(): void {
        this.isEditing = this.canEdit;
    }

    cancelClick(): void {
        if (this.isNewLocation) {
          void  this.router.navigate(['customers', this.customerId, 'locations']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.location, form.value.CustomerLocation as ICustomerLocation);
            this.assignFormValues(this.location.Address, form.value.Address as IAddress);
            this.saveLocation();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveLocation(): void {
        if (this.isNewLocation) {
            this.addressService
                .create(this.location.Address)
                .pipe(
                    switchMap((addressId) => {
                        this.location.AddressId = addressId;
                        this.location.CustomerId = this.customerId;
                        return this.locationService.create(this.location);
                    }),
                )
                .subscribe((answer) => {
                   void this.router.navigate(['customers', this.customerId, 'locations', answer]);
                    this.success();
                    this.locationService.emitChange(this.location);
                });
        } else {
            this.addressService
                .update(this.location.Address)
                .pipe(switchMap((addressId) => this.locationService.update(this.location)))
                .subscribe(() => {
                    this.isEditing = false;
                    this.success();
                    this.locationService.emitChange(this.location);
                    this.setConfig();
                });
        }
    }

    assignFormValues(object: IAddress, formValue: IAddress): void {
        for (const prop in formValue) {
            if (Object.prototype.hasOwnProperty.call(object, prop)) {
                object[prop] = formValue[prop];
            }
        }
    }

    private success(newLocationSave?: boolean): void {
        if (newLocationSave) {
           void this.router.navigate([this.router.url, this.location.Id]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.locationService.emitChange(this.location);
        this.notificationsService.success('Location saved successfully.');
    }
}
