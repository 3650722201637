import { Component, OnInit } from '@angular/core';

import { ICompany } from '../../model/interfaces/company';
import { CompanyService } from '../services/company.service';

@Component({
    templateUrl: './company-add.component.html',
})
export class CompanyAddComponent implements OnInit {
    company: ICompany;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        this.company = this.companyService.getEmptyCompany();
    }
}
