<br />
<div class="row">
    <div class="col-md-12">
        <div class="miles-form padded" style="max-width: 700px;">
            <h2>Successful Payment</h2>
            <br />
            <div>Thank you for your payment. Please wait 24-48 business hours for your invoice(s) to be updated in the customer portal.</div>
            <br />
            <div>
                <button class="btn btn-primary" style="margin-right: 10px !important;" (click)="navigateToDashboard()">Go to Dashboard</button>
            </div>
        </div>
    </div>
</div>
