import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { RecurringPaymentService } from '../services/recurring-payment.service';
import { IRecurringPayment } from '../../model/interfaces/recurring-payment';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { RecurringPaymentsEntityListConfig } from './recurring-payments.entity-list-config';

@Component({
    selector: 'app-recurring-payments',
    templateUrl: './recurring-payments.component.html',
})
export class RecurringPaymentsComponent implements OnInit {
    recurringPayments: IRecurringPayment[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new RecurringPaymentsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddRecurringPayment = false;

    constructor(private recurringPaymentService: RecurringPaymentService, private claimsService: ClaimsService, private router: Router) {}

    ngOnInit(): void {
        this.canAddRecurringPayment = this.claimsService.hasClaim(ClaimTypes.RecurringPayments, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.getRecurringPayments();
    }

    getRecurringPaymentsCall(): Observable<HttpResponse<IRecurringPayment[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.recurringPaymentService.get(searchparams);
    }

    getRecurringPayments(): void {
        this.getRecurringPaymentsCall().subscribe((answer) => {
            this.recurringPayments = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getRecurringPayments();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getRecurringPayments();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getRecurringPayments();
    }

    recurringPaymentSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/recurring-payments', event.entity.Id]);
    }
}
