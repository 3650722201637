import { ICustomerContactHelp } from './customer-contact-help';
import { IExpandableObject } from '../expandable-object';
import { Validators } from '@angular/forms';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { ICustomerDisputeInvoice } from './customer-dispute-invoice';
import { IMetaItem } from '@mt-ng2/base-service';
import { ICustomerRequestService } from './customer-request-service';

export interface ICustomerRequestServicePartialDynamicControlsParameters {
    formGroup?: string;
}

export class CustomerRequestServiceDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(
        private customerRequestServiceInfo?: ICustomerRequestService,
        private locations?: IMetaItem[],
        private serviceTypes?: IMetaItem[],
        additionalParameters?: ICustomerRequestServicePartialDynamicControlsParameters,
    ) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'requestServiceForm';

        this.Form = {
            CustomerContactName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Contact Name',
                name: 'CustomerContactName',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true },
                value:
                    this.customerRequestServiceInfo &&
                    this.customerRequestServiceInfo.hasOwnProperty('CustomerContactName') &&
                    this.customerRequestServiceInfo.CustomerContactName !== null
                        ? this.customerRequestServiceInfo.CustomerContactName
                        : '',
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true },
                value:
                    this.customerRequestServiceInfo &&
                    this.customerRequestServiceInfo.hasOwnProperty('CustomerName') &&
                    this.customerRequestServiceInfo.CustomerName !== null
                        ? this.customerRequestServiceInfo.CustomerName
                        : '',
            }),
            LocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Location',
                name: 'LocationId',
                options: this.locations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.customerRequestServiceInfo && this.customerRequestServiceInfo.LocationId) || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                }),
                validators: { required: true, maxlength: 1000 },
                value: '',
            }),
            ServiceTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Type',
                name: 'ServiceTypeId',
                options: this.serviceTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: (this.customerRequestServiceInfo && this.customerRequestServiceInfo.ServiceTypeId) || null,
            }),
        };
    }
}
