import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../model/ClaimTypes';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { InvoicePaymentDetailComponent } from './invoice-payment-detail/invoice-payment-detail.component';
import { PaymentSuccessFutureComponent } from './payment-success-future/payment-success-future.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { InvoicePaymentService } from './services/invoice-payment.service';

const invoicePaymentEntityConfig = {
    claimType: ClaimTypes.InvoicePayments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    service: InvoicePaymentService,
    title: 'Invoice Payment',
};

const invoicePaymentListRoleGuard: any = {
    claimType: ClaimTypes.InvoicePayments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Invoice Payments',
};

export const invoicePaths = {
    invoiceConfirmPayment: 'invoice-payment/confirm',
    invoiceFuturePaymentSuccess: 'invoice-payment/success/scheduled',
    invoicePayments: 'invoice-payment',
    invoicePaymentSuccess: 'invoice-payment/success',
};

const invoicePaymentRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: InvoicePaymentDetailComponent,
        data: invoicePaymentListRoleGuard,
        path: invoicePaths.invoicePayments,
    },
    {
        canActivate: [AuthGuard],
        component: ConfirmPaymentComponent,
        data: invoicePaymentListRoleGuard,
        path: invoicePaths.invoiceConfirmPayment,
    },
    {
        canActivate: [AuthGuard],
        component: PaymentSuccessComponent,
        data: invoicePaymentListRoleGuard,
        path: invoicePaths.invoicePaymentSuccess,
    },
    {
        canActivate: [AuthGuard],
        component: PaymentSuccessFutureComponent,
        data: invoicePaymentListRoleGuard,
        path: invoicePaths.invoiceFuturePaymentSuccess,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(invoicePaymentRoutes)],
})
export class InvoicePaymentRoutingModule {}
