import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { InvoicePaymentsComponent } from './invoice-payment-list/invoice-payments.component';
import { InvoicePaymentHeaderComponent } from './invoice-payment-header/invoice-payment-header.component';
import { ClaimTypes } from '../model/ClaimTypes';
import { AdminInvoicePaymentService } from './services/admin-invoice-payment.service';

const invoicePaymentEntityConfig = {
    claimType: ClaimTypes.AdminInvoicePayments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'invoicePaymentId',
    service: AdminInvoicePaymentService,
    title: 'InvoicePayment Detail',
};

const invoicePaymentListRoleGuard: any = {
    claimType: ClaimTypes.AdminInvoicePayments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'InvoicePayments',
};

const invoicePaymentAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.AdminInvoicePayments,
    claimValues: [ClaimValues.FullAccess],
};

export const invoicePaymentPaths = {
    invoicePayments: 'admin-invoice-payments',
};

const invoicePaymentRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: InvoicePaymentsComponent,
        data: invoicePaymentListRoleGuard,
        path: invoicePaymentPaths.invoicePayments,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(invoicePaymentRoutes)],
})
export class AdminInvoicePaymentRoutingModule {}
