<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>My Invoices/Manifests</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="statuses" entity="Status" (selectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>
    <mt-search-filter-daterange [startDate]="startDate" [endDate]="endDate" entity="Invoice Date"
        (selectionChanged)="dateSelectionChanged($event)">
    </mt-search-filter-daterange>
    <ng-container *ngIf="(adminView || associatedCompanyId) && customers && customers.length">
        <mt-search-filter-select [showSelectAllButtons]="false" [items]="customers" entity="Customer"
            (selectionChanged)="companyDropdownChange()">
        </mt-search-filter-select>
    </ng-container>
    <ng-container *ngIf="!groups || !groups.length">
        <button class="btn dropdown-toggle btn-transparent" *ngIf="!associatedCompanyId">No Groups Available</button>
        <button class="btn dropdown-toggle btn-transparent" *ngIf="associatedCompanyId">Select Customer With
            Groups</button>
    </ng-container>
    <ng-container *ngIf="groups && groups.length">
        <mt-search-filter-select [items]="groups" entity="Group"
            (selectionChanged)="getCustomerLocationFilterItems()"> </mt-search-filter-select>
    </ng-container>
    <ng-container *ngIf="locations && locations.length">
        <mt-search-filter-select [items]="locations" entity="Location" (selectionChanged)="filterSelectionChanged()">
        </mt-search-filter-select>
    </ng-container>
</div>
<div class="row">
    <div class="col-md-12">
        <button class="btn btn-default btn-sm" (click)="downloadCSVExport()"><i class="fa fa-fw fa-file"></i> CSV
            Export</button>
        <button *ngIf="selectedInvoices && selectedInvoices.length" (click)="payInvoices()"
            class="btn btn-success btn-sm">
            Pay Selected Invoices
        </button>
    </div>
</div>
<br />
<ng-container *ngIf="adminView">
    <mt-entity-list [entities]="invoices" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage"
        (pageChanged)="getInvoices()" (columnSortedEvent)="columnSorted($event)" (itemSelectedEvent)="invoiceSelected($event)"
        (columnSelectedEvent)="viewPayments($event)" [entityListConfig]="adminEntityListConfig">
    </mt-entity-list>
</ng-container>
<ng-container *ngIf="!adminView">
    <mt-entity-list [entities]="invoices" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage"
        (pageChanged)="getInvoices()" (columnSortedEvent)="columnSorted($event)"
        (columnSelectedEvent)="viewPayments($event)" [entityListConfig]="entityListConfig"
        (selectionChangedEvent)="selectionChanged($event)">
    </mt-entity-list>
</ng-container>
<mt-modal-wrapper [autoShow]="false" [showActions]="false" [options]="{ showCloseButton: true, width: 650 }"
    (ready)="paymentDialog = $event">
    <div *ngIf="selectedInvoice">
        <h2>{{ selectedInvoice.InvoiceNo }}</h2>
        <table class="table table-responsive" style="padding-top: 30px"
            *ngIf="invoicePaymentDetails && invoicePaymentDetails.length">
            <thead>
                <tr>
                    <th>Payment Date</th>
                    <th>Payment Status</th>
                    <th>Payment Source</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of invoicePaymentDetails; let i = index">
                    <td>{{ item.PaymentDate | date: mediumDate:UTC }}</td>
                    <td>{{ item.IsExported ? 'Processed' : 'Pending' }}</td>
                    <td>{{ item.PaymentSourceName }}</td>
                    <td style="text-align: right">{{ item.Amt | currency }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="3" style="text-align: left; font-weight: bold">Payment Total</td>
                    <td style="text-align: right; font-weight: bold">{{ selectedInvoice.PaymentTotal | currency }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</mt-modal-wrapper>