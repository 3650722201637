import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './payment-success.component.html',
})
export class PaymentSuccessComponent {
    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    navigateToDashboard(): void {
        void this.router.navigate(['/customer-dashboard']);
    }
}
