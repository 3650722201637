import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { IInvoice } from '../../model/interfaces/invoice';
import { CustomerInvoiceService } from '../services/customer-invoice.service';

@Component({
    templateUrl: './customer-invoice-detail.component.html',
})
export class CustomerInvoiceDetailComponent implements OnInit {
    invoice: IInvoice;
    canEdit: boolean;
    canAdd: boolean;
    canAddDocuments = false;

    constructor(
        private invoiceService: CustomerInvoiceService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Invoices, [ClaimValues.FullAccess]);
        this.canAddDocuments = this.claimsService.hasClaim(ClaimTypes.UploadInvoiceDocs, [ClaimValues.FullAccess]);
        if (this.canAddDocuments === undefined) {
            this.canAddDocuments = false;
        }
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'invoiceId');
        if (id) {
            this.getInvoiceById(id);
        } else {
            void this.router.navigate(['customer-invoices']); // if no id found, go back to list
        }
    }

    getInvoiceById(id: number): void {
        this.invoiceService.getById(id).subscribe((invoice) => {
            if (invoice === null) {
                this.notificationsService.error('Invoice not found');
                void this.router.navigate(['customer-invoices']);
            }
            this.invoice = invoice;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }

    requestDocuments(): void {
        void this.router.navigate(['/customer-invoices/request-documents', this.invoice.Id]);
    }
}
