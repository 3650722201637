<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && company.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Associated Customers
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <ul class="list-group" *ngFor="let item of associatedCustomers">
        <li class="list-group-item" (click)="customerSelected(item); $event.stopPropagation()">
            <span style="margin-right: 60px !important;">{{item.Name}} ({{item.CustomerNo}})</span>
            <button class="btn btn-xs btn-success" style="position:absolute;right:10px;" (click)="removeCustomer(item); $event.stopPropagation()">Remove</button>
        </li>
    </ul>
    <div *ngIf="!associatedCustomers || !associatedCustomers.length"><i>No associated customers</i></div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Add Customers</h4>
    <angular2-multiselect [data]="itemList" [(ngModel)]="selectedItems" [settings]="settings"
        (onScrollToEnd)="fetchMore($event)">
        <c-search>
            <ng-template>
                <input type="text" (keyup)="onSearch($event)" placeholder="Search customers"
                    style="border: none;width: 100%; height: 100%;outline: none;" />
            </ng-template>
        </c-search>
        <c-badge>
            <ng-template let-item="item">
             <label style="margin: 0px;">{{item.Name}} ({{item.CustomerNo}})</label>
            </ng-template>
        </c-badge>
        <c-item>
            <ng-template let-item="item">
                <label style="color: #333;">{{item.Name}} ({{item.CustomerNo}})</label>
            </ng-template>
        </c-item>
    </angular2-multiselect>
    <br />
    <br />
    <button [disabled]="saveLoading"
        Class="btn btn-flat btn-success" (click)="addCustomers()">
        Add Customers
    </button>
    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
        Cancel
    </button>
</div>
