<div *ngIf="myProfile" class="row">
    <div class="col-md-7">
        <h2>My Profile</h2>
    </div>
</div>
<div *ngIf="user && user.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <company-contact-basic-info [user]="user" [canEdit]="canEdit"></company-contact-basic-info>
            <br />
        </div>
        <div class="col-md-6">
            <mt-common-addresses
                [canEdit]="canEdit"
                [address]="userAddress"
                (saved)="saveAddress($event)"
                (deleted)="deleteAddress($event)"
            ></mt-common-addresses>
            <br />
            <mt-common-phone [PhoneArray]="user.UserPhones" (saved)="savePhones($event)" [canEdit]="canEdit"></mt-common-phone>
            <br />
        </div>
    </div>
    <a class="btn btn-default" (click)="close()">Close</a>
</div>
<div *ngIf="user && user.Id === 0">
    <div class="row">
        <div class="col-md-6">
            <company-contact-basic-info [user]="user"></company-contact-basic-info>
        </div>
    </div>
</div>
