import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { Observable } from 'rxjs';
import { ClaimTypes } from './model/ClaimTypes';

@Injectable()
export class HomeGuard  {
    constructor(private authService: AuthService, private router: Router, private claimService: ClaimsService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this.claimService.hasClaim(ClaimTypes.CustomerDashboard, [ClaimValues.FullAccess])) {
            void this.router.navigate(['customer-dashboard']);
            return false;
        } else {
            return true;
        }
    }
}
