import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { UserService } from '../user.service';
import { IUser } from '@model/interfaces/user';
import { ClaimTypes } from '@model/ClaimTypes';
import { UsersEntityListConfig } from './users.entity-list-config';
import { entityListModuleConfig } from '@common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    showTitle = true;
    searchControl = new UntypedFormControl();
    users: IUser[];
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;
    customerId: number;

    entityListConfig = new UsersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(
        private userService: UserService,
        private claimsService: ClaimsService,
        public router: Router, // Public because used in HTML
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.customerId = +this.route.snapshot.parent.paramMap.get('customerId');
        this.getUsers();
    }

    getUsers(): void {
        const _extraSearchParams: ExtraSearchParams[] = [];
        if (this.customerId) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'CustomerId',
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    value: '' + this.customerId,
                }),
            );
        }
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'HideNoAccess',
                value: '1',
            }),
        );

        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.userService.get(searchparams).subscribe((answer) => {
            this.users = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
       void this.router.navigate([this.router.url, event.entity.Id]);
    }

    selectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.currentPage = 1;
        this.getUsers();
    }

    archiveSelected(event: IItemSelectedEvent): void {
            this.userService.ArchiveUser(event.entity.Id as number).subscribe(
                () => {
                    this.notificationsService.success('User successfully deleted!');
                    this.getUsers();
                },
                () => {
                    this.notificationsService.error('An error occurred while deleting the user.');
                },
            );
    }
}
