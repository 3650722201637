import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { CompanyService } from '../services/company.service';
import { ICompany } from '../../model/interfaces/company';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CompaniesEntityListConfig } from './companies.entity-list-config';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
})
export class CompaniesComponent implements OnInit {
    companies: ICompany[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new CompaniesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddCompany = false;

    constructor(
        private companyService: CompanyService,
        private claimsService: ClaimsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canAddCompany = this.claimsService.hasClaim(ClaimTypes.Companies, [ClaimValues.FullAccess]);
        this.getCompanies();
    }

    getCompaniesCall(): Observable<HttpResponse<ICompany[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.companyService.get(searchparams);
    }

    getCompanies(): void {
        this.getCompaniesCall().subscribe((answer) => {
            this.companies = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getCompanies();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getCompanies();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getCompanies();
    }

    companySelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/companies', event.entity.Id]);
    }
}
