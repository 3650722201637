import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { IRecurringPayment } from '../interfaces/recurring-payment';
import { IUser } from '../interfaces/user';
import { IPaymentFrequency } from '../interfaces/payment-frequency';
import { ICustomerPaymentSource } from '../interfaces/customer-payment-source';
import { IInvoicePayment } from '../interfaces/invoice-payment';

export interface IRecurringPaymentDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
    frequencies?: IPaymentFrequency[];
    sources?: ICustomerPaymentSource[];
    invoicePayments?: IInvoicePayment[];
}

export class RecurringPaymentDynamicControls {
    formGroup: string;
    users?: IUser[];
    frequencies?: IPaymentFrequency[];
    sources?: ICustomerPaymentSource[];
    invoicePayments?: IInvoicePayment[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private recurringpayment?: IRecurringPayment,
        additionalParameters?: IRecurringPaymentDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'RecurringPayment';
        this.users = additionalParameters?.users ?? undefined;
        this.frequencies = additionalParameters?.frequencies ?? undefined;
        this.sources = additionalParameters?.sources ?? undefined;
        this.invoicePayments = additionalParameters?.invoicePayments ?? undefined;
        this.Form = {
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.recurringpayment?.UserId || null,
            }),
            FrequencyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frequency',
                name: 'FrequencyId',
                options: this.frequencies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.recurringpayment?.FrequencyId || null,
            }),
            Amount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Amount',
                name: 'Amount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.recurringpayment?.Amount || 0,
            }),
            StartDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Date',
                name: 'StartDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.recurringpayment?.StartDate || null,
            }),
            EndDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Date',
                name: 'EndDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.recurringpayment?.EndDate || null,
            }),
            SourceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Source',
                name: 'SourceId',
                options: this.sources,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.recurringpayment?.SourceId || null,
            }),
            LastDateProcessedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Date Processed On',
                name: 'LastDateProcessedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.recurringpayment?.LastDateProcessedOn || null,
            }),
            InvoicePaymentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Payment',
                name: 'InvoicePaymentId',
                options: this.invoicePayments,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.recurringpayment?.InvoicePaymentId || null,
            }),
        };
        this.View = {
            UserId: new DynamicLabel({
                label: 'User',
                value: getMetaItemValue(this.users as unknown as IMetaItem[], this.recurringpayment?.UserId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            FrequencyId: new DynamicLabel({
                label: 'Frequency',
                value: getMetaItemValue(this.frequencies as unknown as IMetaItem[], this.recurringpayment?.FrequencyId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Amount: new DynamicLabel({
                label: 'Amount',
                value: this.recurringpayment?.Amount || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            StartDate: new DynamicLabel({
                label: 'Start Date',
                value: this.recurringpayment?.StartDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            EndDate: new DynamicLabel({
                label: 'End Date',
                value: this.recurringpayment?.EndDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            SourceId: new DynamicLabel({
                label: 'Source',
                value: getMetaItemValue(this.sources as unknown as IMetaItem[], this.recurringpayment?.SourceId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastDateProcessedOn: new DynamicLabel({
                label: 'Last Date Processed On',
                value: this.recurringpayment?.LastDateProcessedOn || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            InvoicePaymentId: new DynamicLabel({
                label: 'Invoice Payment',
                value: getMetaItemValue(this.invoicePayments as unknown as IMetaItem[], this.recurringpayment?.InvoicePaymentId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
