<br />
<div class="row">
    <div class="col-md-12">
        <div class="miles-form padded" style="max-width: 700px;">
            <h2>Successfully Scheduled Payment</h2>
            <br />
            <div *ngIf="date">
                Thank you for your payment. You will be charged <b>{{ amount | currency }}</b> on <b>{{ date | date: mediumDate:UTC }}</b
                >. We will notify you when you are charged.
            </div>
            <br />
            <table
                class="table table-responsive"
                style="padding-top: 30px; max-width: 800px;"
                *ngIf="invoicePaymentDetails && invoicePaymentDetails.length"
            >
                <thead>
                    <tr>
                        <th>Invoice</th>
                        <th style="text-align: center;">Payment Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoicePaymentDetails; let i = index">
                        <td style="vertical-align: middle;">{{ item.InvoiceNo }}</td>
                        <td class="right-align" style="padding-right: 35px !important;">
                            {{ item.Amt | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <button class="btn btn-primary" style="margin-right: 10px !important;" (click)="navigateToDashboard()">Go to Dashboard</button>
            </div>
        </div>
    </div>
</div>
