<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <mt-route-back-button></mt-route-back-button>
                {{ header }}
                <mt-audit-logs-for-record *ngIf="customer" [recordId]="customer.Id" entity="Customer"> </mt-audit-logs-for-record>
            </h2>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
