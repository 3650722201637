<div *ngIf="!isEditing" class="miles-editable miles-card padded">
    <h4>Groups</h4>

    <ul class="list-group">
        <li *ngFor="let group of groups" class="list-group-item" (click)="groupSelected(group)">
            {{ group.Name }}
        </li>
    </ul>
    <div *ngIf="groups && !groups.length"><i>No Groups</i></div>

    <div class="fab-wrap">
        <button class="btn btn-primary btn-fab-md btn-fab-center" type="button" (click)="groupSelected(null)">
            <span class="fa fa-plus"></span>
        </button>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Edit Group</h4>
    <mt-dynamic-form [config]="formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <mt-common-phone
            *ngIf="group.Id !== 0"
            [PhoneArray]="group.CustomerGroupPhones"
            (saved)="savePhones($event)"
            [canEdit]="canEdit"
        ></mt-common-phone>
        <br *ngIf="group.Id !== 0" />
        <div class="clearfix">
            <div class="pull-right">
                <button type="submit" Class="btn btn-flat btn-success">Save</button>
                <button type="button" Class="btn btn-flat btn-default" (click)="unedit()">Cancel</button>
            </div>
        </div>
    </mt-dynamic-form>
</div>
