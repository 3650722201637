export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    Invoices = 7,
    CustomerDashboard = 8,
    CustomerInvoices = 9,
    InvoicePayments = 10,
    ContactSupport = 11,
    AdminInvoicePayments = 12,
    CustomerPaymentSources = 13,
    RecurringPayments = 14,
    Companies = 15,
    UploadInvoiceDocs = 16,
}
