import { EntityListConfig, SortDirection, EntityListColumn, EntityListDeleteColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IAddress } from '@mt-ng2/format-functions';
import { ICustomer } from '@model/partials/customer';
import { ICustomerLocation } from '../../../model/interfaces/customer-location';

export class LocationsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: (c: ICustomer) => (c.Archived ? c.Name + ' [ARCHIVED]' : c.Name),
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
                new EntityListColumn({
                    accessors: ['SageAccountNumber'],
                    name: 'Sage Account Number',
                }),
                new EntityListColumn({
                    accessorFunction: function (l: ICustomerLocation) : any {
                        return l;
                    },
                    name: 'Address',
                    pipes: ['address'],
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
