import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../model/ClaimTypes';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { ServiceRequestComponent } from './service-request/service-request.component';

const customerDashboardRoleGuard: any = {
    claimType: ClaimTypes.CustomerDashboard,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Dashboard',
};

export const invoicePaths = {
    customerDashboard: 'customer-dashboard',
    requestService: 'request-service',
};

const requestServiceConfig = {
    idParam: 'customerId',
};

const customerDashboardRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CustomerDashboardComponent,
        data: customerDashboardRoleGuard,
        path: invoicePaths.customerDashboard,
    },
    {
        canActivate: [AuthGuard],
        component: ServiceRequestComponent,
        path: `request-service/:${requestServiceConfig.idParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(customerDashboardRoutes)],
})
export class CustomerDashboardRoutingModule {}
