import { ICustomerContactHelp } from './customer-contact-help';
import { IExpandableObject } from '../expandable-object';
import { Validators } from '@angular/forms';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { ICustomerDisputeInvoice } from './customer-dispute-invoice';
import { IRequestDocumentsInfo } from './request-documents-info.copy';

export interface IRequestDocumentsPartialDynamicControlsParameters {
    formGroup?: string;
}

export class RequestDocumentsDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(
        private requestDocumentsInfo?: IRequestDocumentsInfo,
        additionalParameters?: IRequestDocumentsPartialDynamicControlsParameters,
    ) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'disputeInvoiceForm';

        this.Form = {
            Balance: new DynamicField({
                disabled: true,
                formGroup: this.formGroup,
                label: 'Balance',
                name: 'Balance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.requestDocumentsInfo && this.requestDocumentsInfo.Balance || null,
            }),
            Comments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comments',
                name: 'Comments',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                }),
                validation: [ Validators.required ],
                validators: { required: true, maxlength: 1000 },
                value: null,
            }),
            ContactEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contact Email',
                name: 'ContactEmail',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validation: [ Validators.required ],
                validators: { required: true },
                value:
                    this.requestDocumentsInfo &&
                    this.requestDocumentsInfo.hasOwnProperty('ContactEmail') &&
                    this.requestDocumentsInfo.ContactEmail !== null
                        ? this.requestDocumentsInfo.ContactEmail
                        : null,
            }),
            ContactName: new DynamicField({
                disabled: true,
                formGroup: this.formGroup,
                label: 'Contact Name',
                name: 'ContactName',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validation: [ Validators.required ],
                validators: { required: true },
                value:
                    this.requestDocumentsInfo &&
                    this.requestDocumentsInfo.hasOwnProperty('ContactName') &&
                    this.requestDocumentsInfo.ContactName !== null
                        ? this.requestDocumentsInfo.ContactName
                        : '',
            }),
            CustomerName: new DynamicField({
                disabled: true,
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true },
                value:
                    this.requestDocumentsInfo &&
                    this.requestDocumentsInfo.hasOwnProperty('CustomerName') &&
                    this.requestDocumentsInfo.CustomerName !== null
                        ? this.requestDocumentsInfo.CustomerName
                        : '',
            }),
            InvoiceDate: new DynamicField({
                disabled: true,
                formGroup: this.formGroup,
                label: 'Invoice Date',
                name: 'InvoiceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.requestDocumentsInfo &&
                this.requestDocumentsInfo.hasOwnProperty('InvoiceDate') &&
                this.requestDocumentsInfo.InvoiceDate !== null
                    ? this.requestDocumentsInfo.InvoiceDate
                    : '',
            }),
            InvoiceNumber: new DynamicField({
                disabled: true,
                formGroup: this.formGroup,
                label: 'Invoice Number',
                name: 'InvoiceNumber',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true, maxlength: 1000 },
                value: this.requestDocumentsInfo &&
                this.requestDocumentsInfo.hasOwnProperty('InvoiceNumber') &&
                this.requestDocumentsInfo.InvoiceNumber !== null
                    ? this.requestDocumentsInfo.InvoiceNumber
                    : '',
            }),
            LocationName: new DynamicField({
                disabled: true,
                formGroup: this.formGroup,
                label: 'Location Name',
                name: 'LocationName',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true },
                value:
                    this.requestDocumentsInfo &&
                    this.requestDocumentsInfo.hasOwnProperty('LocationName') &&
                    this.requestDocumentsInfo.LocationName !== null
                        ? this.requestDocumentsInfo.LocationName
                        : '',
            }),
            Total: new DynamicField({
                disabled: true,
                formGroup: this.formGroup,
                label: 'Total',
                name: 'Total',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.requestDocumentsInfo && this.requestDocumentsInfo.Total || null,
            }),
        };
    }
}
