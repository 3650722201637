import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule, LoginModuleConfigToken } from '@mt-ng2/login-module';

import { AppRoutingModule } from './app-routing.module';
import { AppNavModule } from './nav/app-nav.module';
import { SharedModule } from './common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { CustomerModule } from './customers/customer.module';
import { UserModule } from './users/user.module';
import { UserRoleModule } from './user-roles/user-roles.module';
import { AppSettingsComponent } from './app-settings.component';
import { AppSettingsService } from './app-settings.service';
import { LoginConfigOverride } from './common/configs/login.config';

import { ToastrModule } from 'ngx-toastr';

import { NotFoundComponent } from './default-routes/not-found/not-found.component';

import { DragScrollModule } from 'ngx-drag-scroll';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DocparserModule } from './auth-entity/docparser/docparser.module';
import { CustomerDashboardModule } from './customer-dashboard/customer-dashboard.module';
import { HomeGuard } from './home-guard';
import { InvoiceModule } from './invoices/invoice.module';
import { CustomerInvoiceModule } from './customer-invoices/customer-invoice.module';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { InvoicePaymentModule } from './invoice-payment/invoice-payment.module';
import { ContactHelpComponent } from './common/components/contact-help.component';
import { HelpService } from './common/services/help.service';
import { AdminInvoicePaymentModule } from './admin-invoice-payments/admin-invoice-payment.module';
import { CustomerPaymentSourceModule } from './customer-payment-sources/customer-payment-source.module';
import { RecurringPaymentModule } from './recurring-payments/recurring-payment.module';
import { CompanyModule } from './companies/company.module';
import { MtDocumentControlModule, IDocumentControlModuleConfig, DocumentControlModuleConfigToken } from '@mt-ng2/document-control';
import { CookieModule } from '@mt-ng2/cookie';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

export const documentControlModuleConfig: IDocumentControlModuleConfig = {
    maxFileSize: 5 * 1024 * 1024,
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, AppSettingsComponent, NotFoundComponent, ContactHelpComponent],
    imports: [
        BrowserModule,
        MtSearchFilterDaterangeModule,
        SharedModule.forRoot(),
        HttpClientModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.15)',
            primaryColour: '#ffffff',
            secondaryColour: '#bbb',
            tertiaryColour: '#ffffff',
        }),
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        DragScrollModule,
        PdfViewerModule,
        CookieModule.withOptions(),
        EnvironmentModule,
        AuthModule.forRoot(),
        CustomerModule.forRoot(),
        UserModule.forRoot(),
        UserRoleModule.forRoot(),
        InvoiceModule.forRoot(),
        CustomerInvoiceModule.forRoot(),
        InvoicePaymentModule.forRoot(),
        CustomerDashboardModule.forRoot(),
        AdminInvoicePaymentModule,
        CustomerPaymentSourceModule,
        RecurringPaymentModule,
        DocparserModule.forRoot(),
        CompanyModule,
        AppRoutingModule,
        NotificationsModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
        MtDisableDuringHttpCallsModule.forRoot()],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        AppSettingsService,
        HomeGuard,
        HelpService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: DocumentControlModuleConfigToken, useValue: documentControlModuleConfig },
		{ provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
})
export class AppModule {}
