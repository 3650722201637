import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IClaimType } from '@model/interfaces/claim-type';

@Injectable()
export class ClaimTypeService extends StaticMetaItemService<IClaimType> {
    constructor(public http: HttpClient) {
        super('ClaimTypeService', 'Claim Type', 'ClaimTypeIds', '/userRoles/claimTypes', http);
    }
}
