import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { ICustomerLocation } from '../../model/interfaces/customer-location';
import { CustomerLocationDynamicControlsPartial } from '../../model/partials/customer-location.form-controls';
import { ICustomerLocationDynamicControlsParameters } from '../../model/form-controls/customer-location.form-controls';
import { IAuthUser } from '../../model/interfaces/auth-user';
import { UserRoles } from '../../model/UserRoles';

export class CustomerLocationDynamicConfig<T extends ICustomerLocation> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private location: T, private authUser: IAuthUser, private configControls?: string[]) {
        super();
        const additionalParams: ICustomerLocationDynamicControlsParameters = {};
        const dynamicControls = new CustomerLocationDynamicControlsPartial(this.location, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            if (authUser.RoleId === UserRoles.Administrator) {
                this.configControls = ['Name', 'SageAccountNumber'];
            } else {
                this.configControls = ['Name'];
            }
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
