import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { ICreatePayaVault } from '../../model/interfaces/custom/create-paya-vault';
import { Observable } from 'rxjs';

export const emptyCustomerPaymentSource: ICustomerPaymentSource = {
    Id: 0,
    Name: null,
    OnlyUsedForRecurring: null,
    Token: null,
    TypeId: 0,
    UserId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class CustomerPaymentSourceService extends BaseService<ICustomerPaymentSource> {
    constructor(public http: HttpClient) {
        super('/customer-payment-sources', http, null, {
            entityName: 'Payment Source',
        });
    }

    formatTitleText(entity: ICustomerPaymentSource): void {
        this.setTitle(`Payment Source: ${entity.Name}`);
    }

    createPaymentSource(): Observable<ICreatePayaVault> {
        return this.http.post<ICreatePayaVault>('/customer-payment-sources/create', null);
    }

    updatePaymentSource(id: number): Observable<ICreatePayaVault> {
        return this.http.put<ICreatePayaVault>(`/customer-payment-sources/${id}/update`, null);
    }

    getEmptyCustomerPaymentSource(): ICustomerPaymentSource {
        return { ...emptyCustomerPaymentSource };
    }
}
