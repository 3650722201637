import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    IAddressDynamicFields,
    IDynamicFieldType,
    IDynamicField,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { AddressDynamicControls, IAddressDynamicControlsParameters } from '../form-controls/address.form-controls';
import { IAddress } from '../interfaces/address';

export class AddressDynamicControlsPartial extends AddressDynamicControls {
    constructor(address?: IAddress, additionalParameters?: IAddressDynamicControlsParameters) {
        super(address, additionalParameters);

        this.Form.Address1 = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 1',
            name: 'Address1',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.maxLength(50)],
            validators: { required: true, maxlength: 50 },
            value: address ? address.Address1 : null,
        } as IDynamicField);

        this.Form.Address2 = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 2',
            name: 'Address2',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            value: address ? address.Address2 : null,
        } as IDynamicField);

        this.Form.City = new DynamicField({
            formGroup: this.formGroup,
            label: 'City',
            name: 'City',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.maxLength(50)],
            validators: { required: true, maxlength: 50 },
            value: address ? address.City : null,
        } as IDynamicField);

        this.Form.StateCode = new DynamicField({
            formGroup: this.formGroup,
            label: 'State Code',
            name: 'StateCode',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.maxLength(2)],
            validators: { required: true, maxlength: 2 },
            value: address ? address.StateCode : null,
        } as IDynamicField);

        this.Form.Zip = new DynamicField({
            formGroup: this.formGroup,
            label: 'Zip',
            name: 'Zip',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.maxLength(5)],
            validators: { required: true, maxlength: 5 },
            value: address ? address.Zip : null,
        } as IDynamicField);
    }
}
