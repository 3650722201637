import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { CustomerGroupDynamicControls, ICustomerGroupDynamicControlsParameters } from '../form-controls/customer-group.form-controls';
import { ICustomerGroup } from '../interfaces/customer-group';
import { ICustomerLocation } from '../interfaces/customer-location';

export interface ICustomerGroupDynamicControlsParametersPartial extends ICustomerGroupDynamicControlsParameters {
    locations?: ICustomerLocation[];
    selectedLocations?: ICustomerLocation[];
}

export class CustomerGroupDynamicControlsPartial extends CustomerGroupDynamicControls {
    constructor(group?: ICustomerGroup, additionalParameters?: ICustomerGroupDynamicControlsParametersPartial) {
        super(group, additionalParameters);

        // Locations multiselect
        this.Form.Locations = new DynamicField({
            formGroup: this.formGroup,
            label: 'Locations',
            name: 'Locations',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: [],
        });
        this.Form.Locations.options = additionalParameters.locations.filter((l) => {
            return !l.CustomerGroupId || l.CustomerGroupId === group.Id;
        });

        if (additionalParameters.selectedLocations) {
            this.Form.Locations.value = additionalParameters.selectedLocations.map((l) => l.Id);
        }
    }
}
