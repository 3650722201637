import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ICustomer } from '@model/partials/customer';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomerService } from '../services/customer.service';
import { CustomerAddressesService } from '../services/customer-addresses.service';
import { CustomerPhonesService } from '../services/customer-phones.service';

@Component({
    templateUrl: './customer-detail.component.html',
})
export class CustomerDetailComponent implements OnInit {
    customer: ICustomer;
    canEdit: boolean;
    canAdd: boolean;

    addressesService = CustomerAddressesService;

    constructor(
        private customerService: CustomerService,
        private customerPhonesService: CustomerPhonesService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get the id from the route
        const id = this.getIdFromRoute(this.route, 'customerId');
        if (id) {
            this.getCustomerById(id);
        } else {
            void this.router.navigate(['customers']); // if no id found, go back to list
        }
    }

    getCustomerById(id: number): void {
        this.customerService.getById(id).subscribe((customer) => {
            // check for reponse of null, which indicates entity not found with that id
            if (customer === null) {
                this.notificationsService.error('Customer not found');
                void this.router.navigate(['customers']);
            }
            this.customer = customer;
        });
    }

    // TODO CCC: move this to common functions
    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10))  ? null : parseInt(id, 10);
    }

    savePhones(phoneCollection: any): void {
        this.customerPhonesService.saveCustomerPhones(this.customer.Id, phoneCollection).subscribe(() => {
            this.notificationsService.success('Phones Saved Successfully');
            this.customer.CustomerPhones = phoneCollection.Phones;
        });
    }
}
