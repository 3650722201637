<div class="miles-card padded">
    <div *ngIf="!isEditing"
        (mouseenter)="isHovered = true"
        (mouseleave)="isHovered = false"
        (click)="setUploadView(); $event.stopPropagation();"
        [style.cursor]="canAddDocuments ? 'pointer' : 'default'">
        <h4>Invoice
            <span class="pull-right" *ngIf="canAddDocuments" [hidden]="!isHovered">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h4>
        <div *ngIf="invoicePdf" class="invoice-pdf-view">
            <div class="invoice-pdf-wrapper" (click)="viewPdf(invoicePdf); $event.stopPropagation();">
                <pdf-viewer
                    display="block"
                    [src]="invoicePdf"
                    [render-text]="false"
                    [original-size]="false"
                    [fit-to-page]="true"
                    [page]="1"
                    [zoom-scale]="page - fit"
                    [autoresize]="true"
                    [zoom]="1"
                    [show-all]="false"
                    style="display: block; width: 300px; height: 400px"
                ></pdf-viewer>
            </div>
        </div>
        <div *ngIf="!invoicePdf && canAddDocuments">
            <button type="button" Class="btn btn-success" (click)="setUploadView()">
                Upload Invoice
            </button>
        </div>
    </div>
    <div *ngIf="isEditing && canAddDocuments">
        <mt-document headerTitle="Upload Invoice Document" [allowedMimeType]="allowedMimeTypes" (afterAddingFile)="addDocument($event)" (addingFileFailed)="onAddingFileFailed($event)"></mt-document>
        <br />
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelUploadView()">
            Cancel
        </button>
        <br />
        <br />
    </div>
    <div *ngIf="invoice.InvoiceManifests && invoice.InvoiceManifests.length">
        <h4>{{ invoice.InvoiceManifests.length }} Associated Manifests</h4>
        <drag-scroll [scrollbar-hidden]="false">
            <div drag-scroll-item *ngFor="let manifest of invoice.InvoiceManifests; index as i">
                <div class="manifest-cell" (click)="viewPdf(manifestPdfs.get(manifest.Id), i + 1)">
                    <pdf-viewer
                        display="block"
                        [src]="manifestPdfs.get(manifest.Id)"
                        [render-text]="false"
                        [original-size]="false"
                        [fit-to-page]="true"
                        [page]="1"
                        [zoom-scale]="page - fit"
                        [autoresize]="false"
                        [zoom]="1"
                        [show-all]="false"
                        style="display: block; width: 200px; height: 250px"
                    ></pdf-viewer>
                    <div class="text-center">Manifest {{ i + 1 }}</div>
                </div>
            </div>
        </drag-scroll>
    </div>
</div>

<mt-modal-wrapper (ready)="documentPdfDialog = $event" [options]="{ showCloseButton: true, width: 900, height: 1105, showCancelButton: false }" [autoShow]="false">
    <div style="height: 45px; margin: 25px">
        <span class="pull-left" style="font-size: 25px">{{ currentTitle }}</span>
        <button (click)="downloadPdf(zoomedPdfSrc, manifestIndex)" class="pull-right btn btn-default">
            Download PDF <i class="fa fa-w fa-download"></i>
        </button>
    </div>
    <pdf-viewer [src]="zoomedPdfSrc" [render-text]="true" style="display: block; width: 800px; height: 600px"></pdf-viewer>
</mt-modal-wrapper>
