<div>
    <div class="row">
        <div class="col-md-7">
               <h2 [mtSetTitle]="header$">
                <mt-route-back-button></mt-route-back-button>
            </h2>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
