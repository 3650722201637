import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { InvoicePaymentStateService } from '../../invoice-payment/services/invoice-payment-state.service';
import { IInvoice } from '../../model/interfaces/invoice';
import { ICustomerInvoicePaymentDetail } from '../../model/partials/customer-invoice-payment-detail';

export const emptyInvoice: IInvoice = {
    DateCreated: null,
    Id: 0,
    InvoiceNo: null,
    UploadedId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class CustomerInvoiceService extends BaseService<IInvoice> {
    selectedInvoiceIdsForPayment: number[] = [];
    constructor(public http: HttpClient, private invoicePaymentStateService: InvoicePaymentStateService) {
        super('/customer-invoices', http, null, {
            entityName: 'Invoice',
        });
    }

    formatTitleText(entity: IInvoice): void {
        this.setTitle(`Invoice: ${entity.InvoiceNo}`);
    }

    getEmptyInvoice(): IInvoice {
        return { ...emptyInvoice };
    }

    getDocument(docId: number): any {
        return this.http.get(`/customer-invoices/documents/${docId}`, {
            // responseType: 'blob' as 'blob',
            responseType: 'arraybuffer' as 'json',
        });
    }

    getInvoicePaymentDetails(invoiceId: number): Observable<ICustomerInvoicePaymentDetail[]> {
        return this.http.get<ICustomerInvoicePaymentDetail[]>(`/customer-invoices/${invoiceId}/payment-details`);
    }

    getCSV(searchparameters: SearchParams): Observable<string> {
        const params = this.getHttpParams(searchparameters);
        return this.http.get('/customer-invoices/csv', {
            headers: { Accept: 'text/csv', 'Content-Type': 'text/csv' },
            observe: 'body',
            params: params,
            responseType: 'text',
        });
    }

    setSelectedInvoiceIdsForPayment(selectedInvoiceIdsForPayment: number[]): void {
        this.selectedInvoiceIdsForPayment = selectedInvoiceIdsForPayment;
        // clear cached invoice payment everytime we select new invoices
        this.invoicePaymentStateService.clear();
    }

    getSelectedInvoiceIdsForPayment(): number[] {
        return this.selectedInvoiceIdsForPayment;
    }

    getAdminDocument(docId: number, invoiceId: number): any {
        return this.http.get(`/customer-invoices/${invoiceId}/admin-documents/${docId}`, {
            responseType: 'blob' as const,
        });
    }
}
