import { CustomerDynamicControls, ICustomerDynamicControlsParameters } from '../form-controls/customer.form-controls';
import { ICustomer } from '../interfaces/customer';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { ClaimTypes } from '../ClaimTypes';

export class CustomerDynamicControlsPartial extends CustomerDynamicControls {
    constructor(customer?: ICustomer, additionalParamters?: ICustomerDynamicControlsParameters) {
        super(customer, additionalParamters);

        if (customer.Company) {
            this.View.Company = new DynamicLabel({
			    label: 'Company',
			    value: customer && customer.Company && customer.Company.hasOwnProperty('Name') && customer.Company.Name !== null ? customer.Company.Name.toString() : 'N/A',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            );
        }
    }
}
