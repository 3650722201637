<mt-common-documents 
        (documentAdded)="addInvoiceDocument($event)"
        (documentDeleted)="deleteDocument($event)"
        (documentDownloaded)="downloadDocument($event)"
        (validateFailed)="validateFailed($event)"
        [documentArray]="documentArray"
        [total]="10"
        [canEdit]="canAddDocuments"
        [max]="10"
        [allowedMimeType]="allowedMimeTypes">
</mt-common-documents>