<div *ngIf="customerPaymentSource">
    <div class="row">
        <div class="col-md-6">
            <div class="miles-editable miles-card padded">
                <h4>Payment Source</h4>
                <div class="display-padded">
                    <b>Payment Type:</b>
                    <span>{{ customerPaymentSource.PaymentSourceType.Name }}</span>
                </div>
                <div class="display-padded">
                    <b>Last 4 Numbers:</b>
                    <span>{{ customerPaymentSource.LastFourDigits }}</span>
                </div>
                <br />
                <button type="button" (click)="navigateToPayaVault()" class="btn btn-primary">
                    Update {{ customerPaymentSource.PaymentSourceType.Id == paymentTypes.CreditCard ? 'Credit Card' : 'ACH' }}
                </button>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-6">
            <app-customer-payment-source-basic-info
                [customerPaymentSource]="customerPaymentSource"
                [canEdit]="canEdit"
            ></app-customer-payment-source-basic-info>
            <br />
        </div>
    </div>
    <a routerLink="/customer-payment-sources" class="btn btn-default">Close</a>
</div>
