import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMakePaymentPayload } from '../../model/interfaces/custom/make-payment-payload';
import { IInvoicePayment } from '../../model/interfaces/invoice-payment';
import { IInvoicePaymentDetailPartial } from '../../model/partials/invoice-payment-detail';
import { ISavedPaymentPartial } from '../../model/partials/saved-payment-partial';

@Injectable({
    providedIn: 'root',
})
export class InvoicePaymentStateService {
    savedPayment: ISavedPaymentPartial = null;

    constructor(public http: HttpClient) {}

    getSavedPayment(): ISavedPaymentPartial {
        return this.savedPayment;
    }

    setSavedPayment(paymentDetails: IInvoicePaymentDetailPartial[], paymentDate: Date, savedPaymentId: number, savedPaymentName: string): void {
        this.savedPayment = {
            paymentDate: paymentDate,
            paymentDetails: paymentDetails,
            savedPaymentId: savedPaymentId,
            savedPaymentName: savedPaymentName,
        };
    }

    clear(): void {
        this.savedPayment = null;
    }
}
