import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IEntityListComponentMembers, IEntityListDynamicCellComponent } from '@mt-ng2/entity-list-module';
import { IInvoice } from '../../model/interfaces/invoice';
import { CustomerInvoiceService } from '../services/customer-invoice.service';

@Component({
    template: `
        <button (click)="invoiceSelected()" class="btn btn-primary btn-xs">View Invoices & Manifests</button>
        <button (click)="payInvoice()" class="btn btn-success btn-xs">Pay Invoice</button>
    `,
})
export class CustomerInvoicesListActionComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    item: IInvoice;

    constructor(private router: Router, private customerInvoiceService: CustomerInvoiceService) {}

    set entity(value: any) {
        this.item = value as IInvoice;
    }

    invoiceSelected(): void {
        void this.router.navigate(['/customer-invoices', this.item.Id]);
    }

    payInvoice(): void {
        this.customerInvoiceService.setSelectedInvoiceIdsForPayment([this.item.Id]);
        void this.router.navigate(['/invoice-payment']);
    }
}
