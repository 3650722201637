import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { ICustomerGroup } from '../../model/interfaces/customer-group';
import {
    CustomerGroupDynamicControlsPartial,
    ICustomerGroupDynamicControlsParametersPartial,
} from '../../model/partials/customer-group.form-controls';

export class CustomerGroupDynamicConfig<T extends ICustomerGroup> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private group: T, private configControls?: string[], private additionalParams?: ICustomerGroupDynamicControlsParametersPartial) {
        super();
        const dynamicControls = new CustomerGroupDynamicControlsPartial(this.group, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'Locations'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
