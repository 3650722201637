import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IInvoice } from '@model/interfaces/invoice';
import { InvoiceService } from '../services/invoice.service';

@Component({
    templateUrl: './invoice-header.component.html',
})
export class InvoiceHeaderComponent implements OnInit, OnDestroy {
    invoice: IInvoice;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private invoiceService: InvoiceService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('invoiceId');
        // set the header based on the id
        if (id > 0) {
            this.invoiceService.getById(id).subscribe((invoice) => {
                this.setHeader(invoice);
            });
        } else {
            this.setHeader(this.invoiceService.getEmptyInvoice());
        }
        // subscribe to any changes in the invoice service
        // which should update the header accordingly
        this.subscriptions.add(
            this.invoiceService.changeEmitted$.subscribe((invoice) => {
                this.setHeader(invoice);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(invoice: IInvoice): void {
        this.invoice = invoice;
        this.header = invoice && invoice.Id ? `Invoice: ${invoice.InvoiceNo}` : 'Add Invoice';
    }
}
