import { Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../../model/interfaces/customer';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAddress } from '@model/interfaces/address';
import { ICustomerGroup } from '../../../model/interfaces/customer-group';
import { CustomerGroupService } from '../../services/customer-group.service';
import { CustomerGroupDynamicConfig } from '../customer-groups.dynamic-config';
import { forkJoin, Subscription } from 'rxjs';
import { ICustomerGroupDynamicControlsParametersPartial } from '../../../model/partials/customer-group.form-controls';
import { ICustomerLocation } from '../../../model/interfaces/customer-location';
import { CustomerGroupPhonesService } from '../../services/customer-group-phones.service';
import { CustomerLocationsService } from '../../services/customer-locations.service';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-customer-groups-info',
    templateUrl: './customer-groups-info.component.html',
})
export class CustomerGroupsInfoComponent implements OnInit {
    @Input('customer') customer: ICustomer;
    @Input('canEdit') canEdit: boolean;

    isEditing = false;
    group: ICustomerGroup;
    searchControl = new UntypedFormControl();
    groups: ICustomerGroup[];
    currentPage = 1;
    query = '';
    total: number;
    canAddGroup = false;
    parentId: number;

    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    groupForm: any;
    formFactory: CustomerGroupDynamicConfig<ICustomerGroup>;
    address: IAddress;
    additionalParams: ICustomerGroupDynamicControlsParametersPartial = {};
    selectedLocations: number[];
    subscriptions = new Subscription();

    constructor(
        private groupService: CustomerGroupService,
        private customerService: CustomerService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private customerGroupPhonesService: CustomerGroupPhonesService,
        private locationService: CustomerLocationsService,
    ) {}

    get isNewGroup(): boolean {
        return this.group && this.group.Id && this.group.Id > 0 ? false : true;
    }

    ngOnInit(): void {
        this.canAddGroup = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);

        forkJoin([this.customerService.getGroups(this.customer.Id), this.customerService.getLocations(this.customer.Id)]).subscribe(
            ([groups, locations]) => {
                this.groups = groups;
                this.additionalParams.locations = locations;
            },
        );
        this.subscriptions.add(
            this.locationService.changeEmitted$.subscribe((location) => {
                this.updateList(location, this.additionalParams.locations);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setConfig(): void {
        this.formFactory = new CustomerGroupDynamicConfig<ICustomerGroup>(this.group, undefined, this.additionalParams);
        if (this.isNewGroup) {
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new  DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) =>new  DynamicField(x));

        }
    }

    groupSelected(group: ICustomerGroup): void {
        if (group) {
            this.group = group;
            this.groupService.getGroupLocations(this.group.Id).subscribe((selectedLocations) => {
                this.additionalParams.selectedLocations = selectedLocations;
                this.setConfig();
                this.isEditing = true;
            });
        } else {
            this.additionalParams.selectedLocations = [];
            this.group = this.groupService.getEmptyCustomerGroup();
            this.setConfig();
            this.isEditing = true;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.group, form.value.CustomerGroup as ICustomerGroup);
            this.selectedLocations = (form.value.CustomerGroup.Locations as number[]) || [];
            this.saveGroup();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
    }

    private saveGroup(): void {
        if (this.isNewGroup) {
            this.group.CustomerId = this.customer.Id;
            this.groupService.createWithLocations(this.group, this.selectedLocations).subscribe(
                (answer: any) => {
                    this.group.Id = answer.groupId as number;
                    this.groups.push(this.group);
                    this.success(answer);
                },
                (e) => {
                    this.error(e);
                },
            );
        } else {
            // this.group.CustomerGroupPhones = null;
            this.groupService.updateWithLocations(this.group, this.selectedLocations).subscribe(
                (answer: any) => {
                    this.success(answer);
                },
                (e) => {
                    this.error(e);
                },
            );
        }
    }

    savePhones(phoneCollection: any): void {
        this.customerGroupPhonesService.saveCustomerGroupPhones(this.group, phoneCollection).subscribe(() => {
            this.notificationsService.success('Phones Saved Successfully');
            this.group.CustomerGroupPhones = phoneCollection.Phones;
        });
    }

    unedit(): void {
        this.isEditing = false;
        this.selectedLocations = [];
    }

    private updateList(updatedItem: any, list: any[]): void {
        for (let item of list) {
            if (updatedItem.Id === item.Id) {
                item = updatedItem;
                return;
            }
        }
        list.push(updatedItem);
    }

    private success(answer: any): void {
        this.additionalParams.locations = answer.locations as ICustomerLocation[];

        this.unedit();
        this.notificationsService.success('Saved Successfully');
        this.groupService.emitChange(this.group);
    }

    private error(e: any): void {
        this.unedit();
        const message = e.error.ExceptionMessage || e.error.ModelState.BreckServiceBase; // Shows error for custom validator and breck base validator
        this.notificationsService.error(message as string);
    }
}
