import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { DocparserUploadComponent } from './docparser-upload/docparser-upload.component';
import { ClaimTypes } from '@model/ClaimTypes';

const uploadRoleGuard = {
    claimType: ClaimTypes.Invoices,
    claimValues: [ClaimValues.FullAccess],
    title: 'Upload Invoices',
};

const docparserRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: DocparserUploadComponent,
        data: uploadRoleGuard,
        path: 'docparser/upload-invoices',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(docparserRoutes)],
})
export class DocParserRoutingModule {}
