import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { RecurringPaymentService } from '../services/recurring-payment.service';

@Component({
    templateUrl: './recurring-payment-header.component.html',
})
export class RecurringPaymentHeaderComponent implements OnInit {
    header$: Observable<string>;
    readonly recurringPaymentService = inject(RecurringPaymentService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
         // get the id from the route
         const id = +this.route.snapshot.paramMap.get('recurringPaymentId');
         this.recurringPaymentService.setInitialTitle(id);
         this.header$ = this.recurringPaymentService.title$;
    }
}
