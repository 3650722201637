import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IRecurringPayment } from '@model/interfaces/recurring-payment';
import { Observable } from 'rxjs';

export const emptyRecurringPayment: IRecurringPayment = {
    Amount: null,
    EndDate: null,
    FrequencyId: 0,
    Id: 0,
    SourceId: 0,
    StartDate: null,
    UserId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class RecurringPaymentService extends BaseService<IRecurringPayment> {
    constructor(public http: HttpClient) {
        super('/recurring-payments', http, null, {
            entityName: 'Recurring Payment',
        });
    }

    formatTitleText(entity: IRecurringPayment): void {
        this.setTitle(`Recurring Payment`);
    }

    cancelPayment(id: number): Observable<any> {
        return this.http.delete(`/recurring-payments/${id}/cancel`);
    }

    getEmptyRecurringPayment(): IRecurringPayment {
        return { ...emptyRecurringPayment };
    }
}
