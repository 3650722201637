import { Component, OnInit } from '@angular/core';

import { IRecurringPayment } from '../../model/interfaces/recurring-payment';
import { RecurringPaymentService } from '../services/recurring-payment.service';

@Component({
    templateUrl: './recurring-payment-add.component.html',
})
export class RecurringPaymentAddComponent implements OnInit {
    recurringPayment: IRecurringPayment;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private recurringpaymentService: RecurringPaymentService) {}

    ngOnInit(): void {
        this.recurringPayment = this.recurringpaymentService.getEmptyRecurringPayment();
    }
}
