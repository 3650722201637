import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { IInvoicePayment } from '../../model/interfaces/invoice-payment';
import { IInvoicePaymentDetailDTO } from '../../model/partials/invoice-payment-detail-dto';

export const emptyInvoicePayment: IInvoicePayment = {
    DateCreated: null,
    Id: 0,
    PaidBy: null,
    PaymentAmt: null,
    PaymentDate: null,
    PayaConfirmed: false,
    TransactionId: ''
};

@Injectable({
    providedIn: 'root',
})
export class AdminInvoicePaymentService extends BaseService<IInvoicePayment> {
    constructor(public http: HttpClient) {
        super('/admin/invoice-payments', http, null, {
            entityName: 'Invoice Payment',
        });
    }

    formatTitleText(entity: IInvoicePayment): void {
        this.setTitle(`Invoice Payment: ${entity.PaymentDate.toDateString()}`);
    }

    getInvoicePaymentDTO(searchparameters: SearchParams): Observable<HttpResponse<IInvoicePaymentDetailDTO[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http.get<IInvoicePaymentDetailDTO[]>('/admin/invoice-payments/dto', {
            observe: 'response',
            params: params,
        });
    }

    getCSV(searchparameters: SearchParams): Observable<string> {
        const params = this.getHttpParams(searchparameters);
        return this.http.get('/admin/invoice-payments/dto/csv', {
            headers: { Accept: 'text/csv', 'Content-Type': 'text/csv' },
            observe: 'body',
            params: params,
            responseType: 'text',
        });
    }

    updateAsNotExported(id: number): any {
        return this.http.put(`/admin/invoice-payments/${id}/unexport`, null);
    }
    getEmptyInvoicePayment(): IInvoicePayment {
        return { ...emptyInvoicePayment };
    }
}
