<div class="row">
    <div class="pull-left" style="margin-left: 15px;">
        <h2>
            Account Dashboard
        </h2>
    </div>
    <div class="col-md-8 filters">
        <ng-container *ngIf="associatedCompanyId && customers && customers.length">
            <mt-search-filter-select [showSelectAllButtons]="false" [items]="customers" entity="Customer" (selectionChanged)="companyDropdownChange()">
            </mt-search-filter-select>
        </ng-container>
        <ng-container *ngIf="!groups || !groups.length">
            <button class="btn dropdown-toggle btn-transparent" *ngIf="!associatedCompanyId">No Groups Available</button>
            <button class="btn dropdown-toggle btn-transparent" *ngIf="associatedCompanyId">Select Customer With Groups</button>
        </ng-container>
        <ng-container *ngIf="groups && groups.length">
            <mt-search-filter-select [items]="groups" entity="Group" (selectionChanged)="getCustomerLocationFilterItems()">
            </mt-search-filter-select>
        </ng-container>
        <ng-container *ngIf="locations && locations.length">
            <mt-search-filter-select [items]="locations" entity="Location" (selectionChanged)="getDashboard()"> </mt-search-filter-select>
        </ng-container>
    </div>
</div>
<br />
<div class="row" *ngIf="dashboardInfo">
    <div class="col-md-6 summary-detail">
        <div class="panel panel-default">
            <div class="panel-heading"><div class="panel-header-text">Financial Summary</div></div>
            <div class="panel-body">
                <div class="col-md-6">
                    <div class="detail-header">Total Amount Due</div>
                    <div class="large-detail-text">{{ dashboardInfo.TotalBalance | currency }}</div>
                    <button type="button" (click)="navigateToPayInvoices()" class="btn btn-secondary">
                        Make Payment <i class="fa fa-w fa-dollar"></i>
                    </button>
                </div>
                <div class="col-md-6">
                    <div class="detail-header"># of Invoices</div>
                    <div class="large-detail-text">{{ dashboardInfo.TotalOpenInvoices }}</div>
                    <button type="button" (click)="navigateToInvoices()" class="btn btn-primary">
                        View Invoices & Manifests<i class="fa fa-w fa-files-o"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 summary-detail">
        <div class="panel panel-default">
            <div class="panel-heading"><div class="panel-header-text">Service Level Summary</div></div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="detail-header">Your Locations</div>
                        <div class="detail-text"><span class="large-detail-text">{{dashboardInfo.TotalGroups}}</span>&nbsp;&nbsp;Groups Serviced</div>
                        <div class="detail-text"><span class="large-detail-text">{{dashboardInfo.TotalLocations}}</span>&nbsp;&nbsp;Locations Serviced</div>
                        <button type="button" (click)="navigateToRequestService()" class="btn btn-primary" style="margin-top: 35px;">Request Service<i class="fa fa-w fa-briefcase"></i></button>
                    </div>
                    <div class="col-md-6">
                        <div class="detail-header">Service Interval</div>
                        <div class="detail-text"><span class="large-detail-text">90</span>&nbsp;&nbsp;Days</div>
                        <div class="detail-text" style="margin-top: 7px;"><b>Last Service Date:</b></div>
                        <div class="detail-text"><span>{{!dashboardInfo.LastServiceDate ? 'N/A' : dashboardInfo.LastServiceDate | date:mediumDate:UTC}}</span></div>
                        <div class="detail-text" style="margin-top: 7px;"><b>Next Service Date:</b></div>
                        <div class="detail-text"><span>{{!dashboardInfo.NextServiceDate ? 'N/A' : dashboardInfo.NextServiceDate | date:mediumDate:UTC}}</span></div>
                    </div>
                </div>     
            </div>
        </div>
    </div>
</div>
