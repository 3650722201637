import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICompany } from '../../../model/interfaces/company';
import { IUser } from '../../../model/interfaces/user';
import { CompanyContactsService } from '../../services/company-contacts.service';
import { CompanyService } from '../../services/company.service';

@Component({
    selector: 'company-contacts-info',
    templateUrl: './company-contacts-info.component.html',
})
export class CompanyContactsInfoComponent implements OnInit {
    @Input('company') company: ICompany;
    @Input('canEdit') canEdit: boolean;

    isEditing = false;
    editContact: IUser;
    contacts: IUser[];
    isHovered: boolean;
    isNewUser: boolean;

    constructor(
        private companyContactsService: CompanyContactsService,
        private companyService: CompanyService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.companyService.getContacts(this.company.Id).subscribe((contacts) => {
            this.contacts = contacts;
        });
    }

    userSelected(user: IUser): void {
        this.isNewUser = !user;
        this.editContact = user || this.companyContactsService.getEmptyUser();
        this.isEditing = true;
    }

    openContactsList(): void {
        void this.router.navigate([this.router.url, 'contacts']);
    }

    success(user: IUser): void {
        this.editContact = user;
        if (this.isNewUser) {
            this.contacts.push(this.editContact);
        }
        this.isEditing = false;
    }

    cancel(): void {
        this.isEditing = false;
    }
}
