<div class="row">
    <br />
    <div class="col-md-12">
        <div class="text-center">
            <h2>Contact Support</h2>
        </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <ngx-loading [show]="isLoading"></ngx-loading>
        <div class="miles-editable miles-card padded" *ngIf="abstractCustomerContactHelpControls && customerContactHelpForm">
            <h4>Question Info</h4>
            <form [formGroup]="customerContactHelpForm" (ngSubmit)="formSubmitted()">
                <mt-dynamic-field [field]="abstractCustomerContactHelpControls.CustomerName" [form]="customerContactHelpForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerContactHelpControls.FirstName" [form]="customerContactHelpForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerContactHelpControls.LastName" [form]="customerContactHelpForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerContactHelpControls.Email" [form]="customerContactHelpForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerContactHelpControls.Phone" [form]="customerContactHelpForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerContactHelpControls.Question" [form]="customerContactHelpForm"></mt-dynamic-field>
                <br />
                <div>
                    <button type="submit" class="btn btn-primary" [disabled]="isLoading">Submit Support Request</button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-3"></div>
</div>
