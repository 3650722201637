<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Invoice Payments</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-daterange [startDate]="startDate" [endDate]="endDate" entity="Payment Date" (selectionChanged)="dateSelectionChanged($event)">
    </mt-search-filter-daterange>
    <mt-search-filter-checkbox (searchCheckboxChanged)="selectionChanged($event)" text="Show Exported"></mt-search-filter-checkbox>
    <br />
</div>
<div class="row">
    <div class="col-md-12">
        <button class="btn btn-default btn-sm" *ngIf="!showExported" (click)="downloadCSVExport()">
            <i class="fa fa-fw fa-file"></i> CSV Export
        </button>
    </div>
</div>
<br />
<mt-entity-list
    [entities]="invoicePayments"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getInvoicePayments()"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>
