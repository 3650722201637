
import { ClaimTypes } from '../model/ClaimTypes';
import { ClaimValues } from '@mt-ng2/auth-module';
import { KeyboardKeys } from '@mt-ng2/keyboard-shortcuts-module';
import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarRowItem({
        claimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.CustomerDashboard,
        content: 'Dashboard',
        icon: 'fa fa-fw fa-dashboard',
        link: '/customer-dashboard',
    }),
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                addLink: '/users/add',
                addClaimType: ClaimTypes.Users,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.Users,
                content: 'Users',
                icon: 'fa fa-fw fa-user',
                link: '/users',
                shortcut: {
                    keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'U'],
                    description: 'go to Users',
                },
            }),
            new NavSidebarRowItem({
                addLink: '/roles/add',
                addClaimType: ClaimTypes.UserRoles,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.UserRoles,
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
                shortcut: {
                    keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'R'],
                    description: 'go to User Roles',
                },
            }),
        ],
        claimType: ClaimTypes.AppSettings,
        content: 'Administration',
        icon: 'fa fa-fw fa-university',
        settingsLink: '/settings',
        settingsClaimType: ClaimTypes.AppSettings,
    }),
    new NavSidebarRowItem({
        claimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.CustomerInvoices,
        content: 'Invoices/Manifests',
        icon: 'fa fa-fw fa-files-o',
        link: '/customer-invoices',
    }),
    new NavSidebarRowItem({
        claimValues: [ClaimValues.ReadOnly],
        claimType: ClaimTypes.CustomerPaymentSources,
        content: 'My Payment Sources',
        icon: 'fa fa-fw fa-credit-card',
        link: '/customer-payment-sources',
    }),
    new NavSidebarRowItem({
        claimValues: [ClaimValues.ReadOnly],
        claimType: ClaimTypes.RecurringPayments,
        content: 'My Recurring Payments',
        icon: 'fa fa-fw fa-calendar',
        link: '/recurring-payments',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.AdminInvoicePayments,
        claimValues: [ClaimValues.FullAccess],
        content: 'Invoice Payments',
        icon: 'fa fa-fw fa-briefcase',
        link: '/admin-invoice-payments',
        shortcut: {
            keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'P'],
            description: 'go to Payments',
        },
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Customers,
        content: 'Customers',
        icon: 'fa fa-fw fa-briefcase',
        link: '/customers',
    }),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.Invoices,
        addClaimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.Invoices,
        content: 'Docparser Invoices',
        icon: 'fa fa-fw fa-briefcase',
        link: '/invoices',
        shortcut: {
            keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'I'],
            description: 'go to Invoices',
        },
    }),
    new NavSidebarRowItem({
        addClaimType: ClaimTypes.Invoices,
        addClaimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.Invoices,
        content: 'Upload Invoices',
        icon: 'fa fa-fw fa-briefcase',
        link: '/docparser/upload-invoices',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Companies,
        content: 'Companies',
        icon: 'fa fa-fw fa-briefcase',
        link: '/companies',
        shortcut: {
            keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'C'],
            description: 'go to Companies',
        },
    }),
    // new NavSidebarRowItem({
    //     claimType: ClaimTypes.Audit,
    //     content: 'Audit Logs',
    //     icon: 'fa fa-fw fa-list-alt',
    //     link: '/audit-logs',
    //     shortcut: {
    //         keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'A'],
    //         description: 'go to audit-logs',
    //     },
    // }),
];
// tslint:enable:object-literal-sort-keys
