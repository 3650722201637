<div class="row">
    <div class="col-md-7">
        <h2>Invoices</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-checkbox (searchCheckboxChanged)="selectionChanged($event)" text="Show Matched"></mt-search-filter-checkbox>
    <br />
</div>
<div class="table-responsive">
    <ngx-loading [show]="isLoading"></ngx-loading>
    <table class="table table-hover table-scroll">
        <thead style="width: calc(100% - 1em)">
            <tr class="no-pointer">
                <th width="90%">
                    <b>Invoice Number</b>
                </th>
                <th width="10%"></th>
            </tr>
        </thead>
        <tbody class="max-table-height">
            <ng-container *ngFor="let invoice of invoices">
                <tr (click)="viewPdf(invoice, 0)" class="sub-item-header">
                    <td class="ellipsis sub-item-header">
                        {{ invoice.InvoiceNo }}
                        <span *ngIf="!invoice.LocationId && (invoice.AssociateToLocation || invoice.OverwriteDocuments)">
                            <i (click)="handleMatching(invoice); $event.stopPropagation()" class="fa fa-lg fa-exclamation-triangle text-warning"></i>
                        </span>
                    </td>
                    <td class="ellipsis sub-item-header" style="padding-left: 50px !important">
                        <button
                            class="btn btn-primary btn-xs"
                            style="min-width: 110px"
                            (click)="showDocumentsDialog(invoice); $event.stopPropagation()"
                        >
                            Add Documents
                        </button>
                    </td>
                </tr>
                <tr class="no-border" *ngFor="let manifest of invoice.InvoiceManifests; let i = index" (click)="viewPdf(invoice, i + 1)">
                    <td class="ellipsis sub-item">Manifest {{ i + 1 }}</td>
                    <td class="ellipsis sub-item">
                        <button
                            class="btn btn-success btn-xs"
                            style="min-width: 110px"
                            (click)="convertToInvoice($event, manifest.Id, invoice.InvoiceManifests.length - i - 1)"
                        >
                            Convert to Invoice
                        </button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<ngb-pagination [collectionSize]="total" [(page)]="currentPage" [pageSize]="itemsPerPage" [maxSize]="5" (pageChange)="getInvoices()"></ngb-pagination>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="{ showCloseButton: true, width: 900, height: 1105, showCancelButton: false, showConfirmButton: false }"
    (ready)="documentPdfDialog = $event"
>
    <div>
        <div
            class="row"
            [ngClass]="{ 'miles-editable miles-card with-cursor': isInvoiceOpen }"
            style="width: 60%; padding: 15px; margin: auto"
            (click)="edit()"
        >
            <div *ngIf="!isEditing">
                <span *ngIf="isInvoiceOpen" class="pull-right" style="margin-top: 1%">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
                <h3 style="margin: 10px">Invoice {{ displayedInvoiceNum }}</h3>
            </div>
            <div *ngIf="isEditing">
                <div class="col-md-8">
                    <input
                        #invoiceInput
                        class="form-control"
                        [(ngModel)]="displayedInvoiceNum"
                        (keydown)="saveInvoiceNum($event)"
                        style="text-align: center"
                    />
                </div>
                <div class="col-md-auto">
                    <button class="btn btn-flat btn-success" (click)="saveInvoiceNum($event)">Save</button>
                    <button class="btn btn-flat" (click)="cancelInvoiceNum($event)">Cancel</button>
                </div>
            </div>
        </div>
        <br />
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" style="display: block; width: 800px; height: 600px"></pdf-viewer>
    </div>
</mt-modal-wrapper>
<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="{ width: 700, height: 700, showCloseButton: true, showCancelButton: false, showConfirmButton: false }"
    (ready)="documentsDialog = $event"
>
    <br />
    <mt-common-documents
        (documentAdded)="addInvoiceDocument($event)"
        (documentDeleted)="deleteDocument($event)"
        (documentDownloaded)="downloadDocument($event)"
        (validateFailed)="validateFailed($event)"
        [documentArray]="documentArray"
        [total]="10"
        [canEdit]="canAddDocuments"
        [max]="10"
        [allowedMimeType]="allowedMimeTypes"
    ></mt-common-documents>
    <br />
</mt-modal-wrapper>
