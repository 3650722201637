import { NgModule } from '@angular/core';

import { NgxLoadingModule } from 'ngx-loading';
import { CustomerContactGroupsService } from '../common/services/customer-contact-groups.service';
import { CustomerContactLocationsService } from '../common/services/customer-contact-locations.service';
import { SharedModule } from '../common/shared.module';
import { CustomerDashboardRoutingModule } from './customer-dashboard-routing.module';
import { CustomerDashboardService } from './customer-dashboard.service';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { ServiceRequestComponent } from './service-request/service-request.component';

@NgModule({
    declarations: [CustomerDashboardComponent, ServiceRequestComponent],
    imports: [SharedModule, CustomerDashboardRoutingModule, NgxLoadingModule],
    providers: [CustomerDashboardService, CustomerContactGroupsService, CustomerContactLocationsService],
})
export class CustomerDashboardModule {
    static forRoot(): any {
        return {
            ngModule: CustomerDashboardModule,
        };
    }
}
