import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomerGroup } from '@model/interfaces/customer-group';
import { ICustomerLocation } from '../../model/interfaces/customer-location';
import { Observable } from 'rxjs';
import { IUser } from '../../model/interfaces/user';

export const emptyCustomerGroup: ICustomerGroup = {
    Customer: null,
    CustomerGroupPhones: [],
    CustomerId: 0,
    CustomerLocations: [],
    Id: 0,
    Name: null,
    Users: [],
};

@Injectable({
    providedIn: 'root',
})
export class CustomerGroupService extends BaseService<ICustomerGroup> {
    constructor(public http: HttpClient) {
        super('/customergroups', http);
    }

    getEmptyCustomerGroup(): ICustomerGroup {
        return { ...emptyCustomerGroup };
    }

    getGroupLocations(groupId: number): Observable<ICustomerLocation[]> {
        return this.http.get<ICustomerLocation[]>(`/customergroups/${groupId}/locations`);
    }

    getGroupContacts(groupId: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/customergroups/${groupId}/contacts`);
    }

    createWithLocations(group: ICustomerGroup, locationIds: number[]): Observable<number> {
        return this.http.post<number>(`/customergroups/with-locations`, { group, locationIds });
    }

    updateWithLocations(group: ICustomerGroup, locationIds: number[]): Observable<number> {
        return this.http.put<number>(`/customergroups/with-locations`, { group, locationIds });
    }
}
