import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { RecurringPaymentService } from '../services/recurring-payment.service';
import { RecurringPaymentDynamicConfig } from '../recurring-payment.dynamic-config';
import { PaymentFrequencyService } from '../services/payment-frequency.service';
import { IRecurringPayment } from '@model/interfaces/recurring-payment';
import { IPaymentFrequency } from '@model/interfaces/payment-frequency';
import { ICustomerPaymentSource } from '@model/interfaces/customer-payment-source';
import { IUser } from '@model/interfaces/user';
import { CustomerPaymentSourceService } from '../../customer-payment-sources/services/customer-payment-source.service';
import { PaymentFrequencies } from '../../model/PaymentFrequencies';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-recurring-payment-basic-info',
    styles: [
        `
            .hover-container {
                position: relative;
            }
            .hover-content {
                position: absolute;
                top: 25px;
                right: 0px;
                display: none;
                z-index: 999;
                background-color: white;
                padding: 5px;
                border: 1px solid black;
                font-size: 14px !important;
                font-weight: normal !important;
                color: #777 !important;
                min-width: 300px !important;
            }
            .hover-container:hover .hover-content {
                display: block;
            }
        `,
    ],
    templateUrl: './recurring-payment-basic-info.component.html',
})
export class RecurringPaymentBasicInfoComponent implements OnInit {
    @Input() recurringPayment: IRecurringPayment;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: RecurringPaymentDynamicConfig<IRecurringPayment>;
    doubleClickIsDisabled = false;

    frequencies: IPaymentFrequency[];
    sources: ICustomerPaymentSource[];
    users: IUser[];
    subscription: Subscription = new Subscription();

    get isNewRecurringPayment(): boolean {
        return this.recurringPayment && this.recurringPayment.Id ? false : true;
    }

    constructor(
        private recurringPaymentService: RecurringPaymentService,
        private customerPaymentSourceService: CustomerPaymentSourceService,
        private paymentFrequencyService: PaymentFrequencyService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        forkJoin(this.paymentFrequencyService.getItems(), this.customerPaymentSourceService.getAll()).subscribe((answers) => {
            const [frequencies, sources] = answers;
            this.frequencies = frequencies.sort((a, b) => a.Id - b.Id);
            this.sources = sources;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new RecurringPaymentDynamicConfig<IRecurringPayment>(this.recurringPayment, this.frequencies, this.sources, this.users);

        if (this.isNewRecurringPayment) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewRecurringPayment) {
            void this.router.navigate(['/recurring-payments']);
        } else {
            this.isEditing = false;
        }
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('RecurringPayment.NeverEnding').valueChanges.subscribe((answer) => {
                if (answer) {
                    event.get('RecurringPayment.EndDate').disable();
                    event.get('RecurringPayment.EndDate').setValue(null);
                } else if (event.get('RecurringPayment.FrequencyId').value !== PaymentFrequencies.OneTime) {
                    event.get('RecurringPayment.EndDate').enable();
                }
            }),
        );
        this.subscription.add(
            event.get('RecurringPayment.FrequencyId').valueChanges.subscribe((answer) => {
                if (answer === PaymentFrequencies.OneTime) {
                    event.get('RecurringPayment.EndDate').disable();
                    event.get('RecurringPayment.NeverEnding').disable();
                    event.get('RecurringPayment.NeverEnding').setValue(false);
                    event.get('RecurringPayment.EndDate').setValue(event.get('RecurringPayment.StartDate').value);
                } else {
                    event.get('RecurringPayment.EndDate').enable();
                    event.get('RecurringPayment.NeverEnding').enable();
                }
            }),
        );
        this.subscription.add(
            event.get('RecurringPayment.StartDate').valueChanges.subscribe((answer) => {
                if (answer) {
                    if (event.get('RecurringPayment.FrequencyId').value === PaymentFrequencies.OneTime) {
                        event.get('RecurringPayment.EndDate').setValue(event.get('RecurringPayment.StartDate').value);
                    }
                }
            }),
        );
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.recurringPayment, form.value.RecurringPayment as IRecurringPayment);
            if (this.recurringPayment.FrequencyId === PaymentFrequencies.OneTime) {
                this.recurringPayment.EndDate = this.recurringPayment.StartDate;
            }
            if (form.value.RecurringPayment.NeverEnding) {
                this.recurringPayment.EndDate = null;
            }
            this.saveRecurringPayment();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private saveRecurringPayment(): void {
        if (this.isNewRecurringPayment) {
            this.recurringPaymentService.create(this.recurringPayment).subscribe((answer) => {
                this.recurringPayment.Id = answer;
                this.success(true);
            });
        } else {
            this.recurringPaymentService.update(this.recurringPayment).subscribe((success) => {
                this.success();
            });
        }
    }

    private success(newRecurringPaymentSave?: boolean): void {
        if (newRecurringPaymentSave) {
            void this.router.navigate([`/recurring-payments/${this.recurringPayment.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.recurringPaymentService.emitChange(this.recurringPayment);
        this.notificationsService.success('Recurring Payment saved successfully.');
    }
}
