import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { ICustomer } from '@model/partials/customer';
import { CustomerService } from '../services/customer.service';
import { finalize } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
declare let CollectJS: any;

@Component({
    templateUrl: './customer-add.component.html',
})
export class CustomerAddComponent implements OnInit {
    customer: ICustomer;
    @ViewChild('response')
    response: ElementRef;

    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private customerService: CustomerService) {}

    ngOnInit(): void {
        this.customer = this.customerService.getEmptyCustomer();
        document.addEventListener('DOMContentLoaded', function (): void {
            CollectJS.configure({
                callback: function (response): void {
                    document.getElementById('paymentTokenInfo').innerHTML =
                        `<b>Payment Token:</b> ${response.token as string } <br><b>Card:</b> ${response.card.number as number}`;
                    (document.getElementById('response') as HTMLInputElement).value = response.token;
                    document.getElementById('sendPayment').click();
                },
                variant: 'inline',
                // tslint:disable-next-line:object-literal-sort-keys
                validCss: {
                    color: '#13AA73',
                },
                customCss: {
                    'border-color': '#ffffff',
                    'border-style': 'solid',
                },
                focusCss: {
                    'border-color': '#1AD18E',
                    'border-style': 'solid',
                    'border-width': '3px',
                },
                fields: {
                    cvv: {
                        placeholder: 'CVV',
                    },
                    // tslint:disable-next-line:object-literal-sort-keys
                    ccnumber: {
                        placeholder: 'Credit Card',
                    },
                    ccexp: {
                        placeholder: 'MM / YY',
                    },
                },
            });
        });
    }

    sendPayment(): void {
        // TODO
    }
}
