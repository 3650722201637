import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../../../users/user.service';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../../model/interfaces/customer';
import { CustomerContactsService } from '../../services/customer-contacts.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-customer-contacts-info',
    templateUrl: 'customer-contacts-info.component.html',
})
export class CustomerContactsInfoComponent implements OnInit {
    @Input('customer') customer: ICustomer;
    @Input('canEdit') canEdit: boolean;

    isEditing = false;
    editContact: IUser;
    contacts: IUser[];
    isHovered: boolean;
    isNewUser: boolean;

    constructor(
        private customerContactsService: CustomerContactsService,
        private customerService: CustomerService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.customerService.getContacts(this.customer.Id).subscribe((contacts) => {
            this.contacts = contacts;
        });
    }

    userSelected(user: IUser): void {
        this.isNewUser = !user;
        this.editContact = user || this.customerContactsService.getEmptyUser();
        this.isEditing = true;
    }

    openContactsList(): void {
        void this.router.navigate([this.router.url, 'contacts']);
    }

    success(user: IUser): void {
        this.editContact = user;
        if (this.isNewUser) {
            this.contacts.push(this.editContact);
        }
        this.isEditing = false;
    }

    cancel(): void {
        this.isEditing = false;
    }

    delete(user: IUser): void{
        this.editContact = user;
        this.contacts.forEach( (item, index) => {
            if(item === this.editContact) this.contacts.splice(index,1);
          });
        this.isEditing = false;
    }
}
