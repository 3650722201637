import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { AddressDynamicControlsPartial } from '@model/partials/address.form-controls';
import { IAddress } from '@model/interfaces/address';

export class AddressDynamicConfig<T extends IAddress> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private address: T, private configControls?: string[]) {
        super();
        const dynamicControls = new AddressDynamicControlsPartial(this.address);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Address1', 'Address2', 'City', 'StateCode', 'Zip'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
