import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IPaymentFrequency } from '../../model/interfaces/payment-frequency';

@Injectable({
    providedIn: 'root',
})
export class PaymentFrequencyService extends StaticMetaItemService<IPaymentFrequency> {
    constructor(public http: HttpClient) {
        super('PaymentFrequencyService', 'Frequency', 'FrequencyIds', '/payment-frequencies', http);
    }
}
