<div *ngIf="!isEditing" class="miles-editable miles-card padded">
    <h4>
        Locations
        <span class="pull-right show-on-hover" *ngIf="canEdit">
            <button *ngIf="canEdit" [hidden]="!isHovered" class="btn btn-primary btn-flat" (click)="openLocationsList()" style="margin-top: -12px;">
                Manage
            </button>
        </span>
    </h4>

    <ul class="list-group">
        <li *ngFor="let location of locations" class="list-group-item" (click)="locationSelected(location)">
            {{ location.Name }}
        </li>
    </ul>
    <div *ngIf="locations && !locations.length"><i>No Locations</i></div>

    <div class="fab-wrap">
        <button class="btn btn-primary btn-fab-md btn-fab-center" type="button" (click)="locationSelected(null)">
            <span class="fa fa-plus"></span>
        </button>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Edit Location</h4>
    <mt-dynamic-form [config]="formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <button type="submit" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="isEditing = false">Cancel</button>
    </mt-dynamic-form>
</div>
