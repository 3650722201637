import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { IAddress } from '../interfaces/address';
import { IState } from '../interfaces/state';
import { ICountry } from '../interfaces/country';

export interface IAddressDynamicControlsParameters {
    formGroup?: string;
}

export class AddressDynamicControls {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private address?: IAddress,
        additionalParameters?: IAddressDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'Address';

        this.Form = {
            Address1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address1',
                name: 'Address1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.address?.Address1?.toString() || '',
            }),
            Address2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address2',
                name: 'Address2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.address?.Address2?.toString() || '',
            }),
            City: new DynamicField({
                formGroup: this.formGroup,
                label: 'City',
                name: 'City',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.address?.City?.toString() || '',
            }),
            StateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'State Code',
                name: 'StateCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 2 },
                validation: [Validators.maxLength(2)],
                value: this.address?.StateCode?.toString() || '',
            }),
            Zip: new DynamicField({
                formGroup: this.formGroup,
                label: 'Zip',
                name: 'Zip',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 20 },
                validation: [Validators.maxLength(20)],
                value: this.address?.Zip?.toString() || '',
            }),
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country Code',
                name: 'CountryCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 2 },
                validation: [Validators.maxLength(2)],
                value: this.address?.CountryCode?.toString() || '',
            }),
            Province: new DynamicField({
                formGroup: this.formGroup,
                label: 'Province',
                name: 'Province',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.address?.Province?.toString() || '',
            }),
        };
        this.View = {
            Address1: new DynamicLabel({
                label: 'Address1',
                value: this.address?.Address1?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Address2: new DynamicLabel({
                label: 'Address2',
                value: this.address?.Address2?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            City: new DynamicLabel({
                label: 'City',
                value: this.address?.City?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            StateCode: new DynamicLabel({
                label: 'State Code',
                value: this.address?.StateCode?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Zip: new DynamicLabel({
                label: 'Zip',
                value: this.address?.Zip?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CountryCode: new DynamicLabel({
                label: 'Country Code',
                value: this.address?.CountryCode?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Province: new DynamicLabel({
                label: 'Province',
                value: this.address?.Province?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
