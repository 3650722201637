import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthUserDynamicConfig } from '../../../auth-entity/auth-user/auth-user.dynamic-config';
import { UserRoles } from '../../../model/UserRoles';
import { UntypedFormGroup } from '@angular/forms';
import { CompanyContactDynamicConfig } from '../company-contact.dynamic-config';
import { CompanyContactsService } from '../../services/company-contacts.service';
import { IAuthUser } from '../../../model/interfaces/auth-user';
import { IUserRole } from '../../../model/interfaces/user-role';
import { IUser } from '../../../model/interfaces/user';
import { ICreateUserPayload } from '../../../model/interfaces/custom/create-user-payload';
import { IUserDynamicControlsParameters } from '../../../model/form-controls/user.form-controls';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { Subscription } from 'rxjs';
@Component({
    selector: 'company-contact-info-form',
    templateUrl: './company-contact-info-form.component.html',
})
export class CompanyContactInfoFormComponent implements OnInit {
    @Input() companyId: number;
    @Input() user: IUser;
    @Input() canEdit: boolean;

    @Output() successEmitter = new EventEmitter();
    @Output() cancelEmitter = new EventEmitter();

    authUser: IAuthUser;
    roles: IUserRole[];
    additionalConfigs: any[] = [];
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    userForm: any;
    formFactory: CompanyContactDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    formReady = false;
    subscription: Subscription = new Subscription();
    additionalParams: IUserDynamicControlsParameters = {};

    constructor(private companyContactsService: CompanyContactsService, private notificationsService: NotificationsService) {}

    get isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new CompanyContactDynamicConfig<IUser>(this.user, undefined, this.additionalParams);
        this.additionalConfigs = this.getAdditionalConfigs();
        if (this.isNewUser) {
            const config = this.formFactory.getForCreate(this.additionalConfigs);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate(this.additionalConfigs);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
        this.formReady = true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const authConfigControls: string[] = ['GrantAccessEmail'];
        const authUser = this.isNewUser ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, this.roles, authConfigControls);
        return [pwConfig];
    }

    cancelClick(): void {
        this.cancelEmitter.emit();
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            this.user.CompanyId = this.companyId;
            this.saveContact(form);
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    private saveContact(form): void {
        if (this.isNewUser) {
            const data: ICreateUserPayload = {
                GrantAccess: form.value.AuthUser.GrantAccessEmail || false,
                SendEmail: form.value.AuthUser.GrantAccessEmail || false,
                User: this.user,
                UserRoleId: UserRoles.AdminContacts,
            };
            this.companyContactsService.createUser(data).subscribe((user: IUser) => {
                this.user = user;
                this.companyContactsService.emitChange(this.user);
                this.success();
            });
        } else {
            this.user.AuthUser.RoleId = UserRoles.AdminContacts;
            this.user.AuthUser.HasAccess = form.value.AuthUser.GrantAccessEmail;
            this.companyContactsService.updateVersion(this.user).subscribe((newVersion) => {
                this.user.Version = newVersion;
                this.success();
                this.setConfig();
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
        this.successEmitter.emit(this.user);
    }

    error(message = 'Save failed. Please check the form and try again.'): void {
        this.notificationsService.error(message);
    }
}
