import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import {
    CustomerPaymentSourceDynamicControls,
    ICustomerPaymentSourceDynamicControlsParameters,
} from '@model/form-controls/customer-payment-source.form-controls';
import { ICustomerPaymentSource } from '@model/interfaces/customer-payment-source';
import { IPaymentSourceType } from '@model/interfaces/payment-source-type';
import { IUser } from '@model/interfaces/user';

export class CustomerPaymentSourceDynamicConfig<T extends ICustomerPaymentSource> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private customerPaymentSource: T, private types: IPaymentSourceType[], private users: IUser[], private configControls?: string[]) {
        super();
        const additionalParams: ICustomerPaymentSourceDynamicControlsParameters = {
            types: this.types,
            users: this.users,
        };
        const dynamicControls = new CustomerPaymentSourceDynamicControls(this.customerPaymentSource, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'OnlyUsedForRecurring'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
