import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { InvoicePaymentDetailComponent } from './invoice-payment-detail/invoice-payment-detail.component';
import { InvoicePaymentManageInvoicesComponent } from './invoice-payment-manage-invoices/invoice-payment-manage-invoices.component';
import { InvoicePaymentRoutingModule } from './invoice-payment-routing.module';
import { InvoicePaymentService } from './services/invoice-payment.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { InvoicePdfPreviewComponent } from './invoice-pdf-preview/invoice-pdf-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PaymentSuccessFutureComponent } from './payment-success-future/payment-success-future.component';

@NgModule({
    declarations: [
        InvoicePaymentDetailComponent,
        InvoicePaymentManageInvoicesComponent,
        ConfirmPaymentComponent,
        PaymentSuccessComponent,
        InvoicePdfPreviewComponent,
        PaymentSuccessFutureComponent,
    ],
    imports: [SharedModule, InvoicePaymentRoutingModule, CurrencyMaskModule, PdfViewerModule],
})
export class InvoicePaymentModule {
    static forRoot(): any {
        return {
            ngModule: InvoicePaymentModule,
            providers: [InvoicePaymentService],
        };
    }
}
