import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICustomerContactHelp } from '../../model/partials/customer-contact-help';
import { CustomerContactHelpDynamicControlsPartial } from '../../model/partials/customer-contact-help.form-controls';
import { UserService } from '../../users/user.service';
import { HelpService, ICustomerContactInfo } from '../services/help.service';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-contact-help',
    styles: [],
    templateUrl: './contact-help.component.html',
})
export class ContactHelpComponent implements OnInit {
    abstractCustomerContactHelpControls: any;
    customerContactHelpForm: UntypedFormGroup;
    isLoading = false;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,

        private helpService: HelpService,
        private notificationsService: NotificationsService,
        private location: Location,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.helpService
            .getHelpUserInfo()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((result) => {
                this.createForm(result);
            });
    }

    createForm(userInfo: ICustomerContactInfo): void {
        this.getControls(userInfo);
        this.customerContactHelpForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            customerContactHelpForm: this.fb.group({}),
        });
    }

    getControls(userInfo: ICustomerContactInfo): void {
        this.abstractCustomerContactHelpControls = new CustomerContactHelpDynamicControlsPartial(
            {
                CustomerName: userInfo ? userInfo.CustomerName : null,
                Email: userInfo ? userInfo.Email : null,
                FirstName: userInfo ? userInfo.FirstName : null,
                LastName: userInfo ? userInfo.LastName : null,
                Phone: userInfo ? userInfo.Phone : null,
                Question: null,
            },
            {
                formGroup: 'customerContactHelpForm',
            },
        ).Form;
    }

    formSubmitted(): void {
        if (this.customerContactHelpForm.valid) {
            const formValues = this.customerContactHelpForm.value.customerContactHelpForm as ICustomerContactHelp;
            this.isLoading = true;
            this.helpService
                .submitHelpRequest({
                    CustomerName: formValues.CustomerName,
                    Email: formValues.Email,
                    FirstName: formValues.FirstName,
                    LastName: formValues.LastName,
                    Phone: formValues.Phone,
                    Question: formValues.Question,
                })
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    this.notificationsService.success('Thank you for your help request we will get back to you as quickly as possible.');
                    this.location.back();
                });
        } else {
            common.markAllFormFieldsAsTouched(this.customerContactHelpForm);
        }
    }
}
