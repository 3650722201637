<div class="row">
    <div class="col-md-6">
        <div class="miles-form padded">
            <div *ngIf="!invoicePaymentDetails || !invoicePaymentDetails.length" class="no-invoices-text">No invoices selected</div>
            <table class="table table-responsive" style="padding-top: 30px" *ngIf="invoicePaymentDetails && invoicePaymentDetails.length">
                <thead>
                    <tr>
                        <th>Invoices to be Paid</th>
                        <th colspan="2" style="text-align: center">Invoice Payment Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of invoicePaymentDetails; let i = index">
                        <td (click)="showPdfPreview(item)" style="vertical-align: middle">{{ item.InvoiceNo }}</td>
                        <td colspan="2" class="right-align">
                            <input type="text" currencyMask [(ngModel)]="item.Amt" class="form-control right-align" />
                        </td>
                        <td><i class="fa fa-2x fa-trash" (click)="deletePaymentInvoiceDetail(i)"></i></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total Payment Amount</td>
                        <td class="right-align" colspan="2" style="padding-right: 35px !important">
                            {{ getTotalPayment() | currency }}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Payment Date</td>
                        <td class="right-align" colspan="2">
                            <input type="date" [ngModel]="paymentDate | date: 'yyyy-MM-dd'" (ngModelChange)="paymentDate = $event" />
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="col-md-6" *ngIf="invoicePaymentDetails && invoicePaymentDetails.length">
        <div class="miles-card padded">
            <h4>Saved Payment Sources</h4>
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let item of paymentSources" (click)="selectedPaymentId = item.Id">
                    {{ item.Name }} {{ item.PaymentSourceType ? '(' + item.PaymentSourceType.Name + ')' : '' }}
                    <input class="pull-right" type="radio" name="radiogroup" [checked]="item.Id === selectedPaymentId" />
                </li>
            </ul>
        </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-6">
        <button class="btn btn-default" (click)="goBack()">Cancel</button>
        <button class="btn btn-success" (click)="save(invoicePaymentDetails)" [disabled]="getTotalPayment() == 0 || isLoading">Continue</button>
    </div>
</div>

<mt-modal-wrapper
    [autoShow]="false"
    [showActions]="false"
    [options]="{ showCloseButton: true, width: 900, height: 1105, showCancelButton: false, showconfirmButton: false }"
    (ready)="documentPdfDialog = $event"
>
    <app-invoice-pdf-preview #pdfPreview></app-invoice-pdf-preview>
</mt-modal-wrapper>
