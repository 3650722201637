import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICustomerContactHelp } from '../../model/partials/customer-contact-help';
import { ICustomerRequestService } from '../../model/partials/customer-request-service';
import { IRequestDocumentsInfo } from '../../model/partials/request-documents-info.copy';

export interface ICustomerContactInfo {
    CustomerName: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
}

export interface IDisputeInvoiceInfo {
  CustomerName?: string,
  LocationName?: string,
  Email: string,
  CustomerPhone: string,
  Reason?: string,
}

export interface IRequestDocumentSubmitInfo {
  ContactEmail: string,
  ContactName: string,
  Comments: string,
}

@Injectable()
export class HelpService {
    constructor(public http: HttpClient) {}

    getHelpUserInfo(): Observable<ICustomerContactInfo> {
        return this.http.get<ICustomerContactInfo>('/help/user-info');
    }

  submitHelpRequest(params: ICustomerContactHelp): Observable<any> {
      return this.http.post<any>('/help', params);
  }

  getInvoiceDisputeInfo(invoiceId: number): Observable<IDisputeInvoiceInfo> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('invoiceId', invoiceId.toString());
    return this.http.get<IDisputeInvoiceInfo>('/help/dispute-invoice-info', { params: httpParams });
  }

  submitInvoiceDispute(params: IDisputeInvoiceInfo, invoiceId: number): Observable<any> {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('invoiceId', invoiceId.toString());
      return this.http.post<any>('/help/dispute-invoice', params, { params: httpParams });
  }

  getRequestDocumentInfo(invoiceId: number): Observable<IRequestDocumentsInfo> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('invoiceId', invoiceId.toString());
    return this.http.get<IRequestDocumentsInfo>('/help/request-document-info', { params: httpParams });
  }

  submitRequestDocuments(params: IRequestDocumentSubmitInfo, invoiceId: number): Observable<any> {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('invoiceId', invoiceId.toString());
      return this.http.post<any>('/help/request-documents', params, { params: httpParams });
  }

  submitServiceRequest(locationId: number, serviceTypeId: number, notes: string): Observable<any> {
    return this.http.post<any>('/help/service-request', {
      LocationId: locationId,
      Notes: notes,
      ServiceTypeId: serviceTypeId,
    });
}

}
