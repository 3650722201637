<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && recurringPayment && recurringPayment.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Recurring Payment Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Recurring Payment Info
        <span class="hover-container">
                <i class="fa fa-lg fa-info-circle" ></i>
                <div class="hover-content">
                    <ul>
                        <li>Recurring Payment Frequencies:
                            <ul>
                                <li>Bi-Weekly processes every 14 days</li>
                                <li>Monthly processes every 30 days</li>
                                <li>Quarterly processes every 90 days</li>
                            </ul>
                        </li>
                        <li>Recurring payments are processed against the oldest available invoices up to the recurring payment amount.</li>
                    </ul>
                </div>
        </span>
    </h4>
    <mt-dynamic-form [config]="formObject" ([isEditing])="isEditing" (submitted)="formSubmitted($event)" (formCreated)="formCreated($event)">
        <button type="submit" mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
            Cancel
        </button>
    </mt-dynamic-form>
</div>
