import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { IUser } from '@model/interfaces/user';
import { ClaimTypes } from '@model/ClaimTypes';
import { entityListModuleConfig } from '@common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { UserService } from '../../../users/user.service';
import { ContactEntityListConfig } from './contact.entity-list-config';
import { forkJoin } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { IMetaItem } from '../../../model/interfaces/base';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CustomerContactsService } from '../../services/customer-contacts.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-contacts',
    templateUrl: './contact.component.html',
})
export class ContactsComponent implements OnInit {
    showTitle = true;
    searchControl = new UntypedFormControl();
    users: IUser[];
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;
    customerId: number;
    locations: MtSearchFilterItem[] = [];
    groups: MtSearchFilterItem[] = [];

    entityListConfig = new ContactEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(
        private customerContacts: CustomerContactsService,
        private customerService: CustomerService,
        private claimsService: ClaimsService,
        private userService: UserService,
        private notificationsService : NotificationsService,
        public router: Router, // Public because used in HTML
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.customerId = +this.route.snapshot.parent.paramMap.get('customerId');
        forkJoin([this.customerService.getLocations(this.customerId), this.customerService.getGroups(this.customerId)]).subscribe(
            ([locations, groups]) => {
                this.locations = locations.map((l) => new MtSearchFilterItem(l, false));
                this.groups = groups.map((g) => new MtSearchFilterItem(g, false));
                this.getUsers();
            },
        );
    }

    getUsers(): void {
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.customerContacts.get(searchparams).subscribe(
            (answer) => {
                this.users = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedLocationIds: number[] = this.getSelectedFilters(this.locations);
        const selectedGroupIds: number[] = this.getSelectedFilters(this.groups);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'LocationIds',
                valueArray: selectedLocationIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'GroupIds',
                valueArray: selectedGroupIds,
            }),
        );
        if (this.customerId) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'CustomerId',
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    value: '' + this.customerId,
                }),
            );
        }

        return _extraSearchParams;
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    search(query: string): void {
        this.query = query;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
       void this.router.navigate([this.router.url, event.entity.Id]);
    }

    selectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.currentPage = 1;
        this.getUsers();
    }

    archiveSelected(event: IItemSelectedEvent): void {
        this.userService.ArchiveUser(event.entity.Id as number).subscribe(
            () => {
                this.notificationsService.success('User successfully deleted!');
                this.getUsers();
            },
            () => {
                this.notificationsService.error('An error occurred while deleting the user.');
            },
        );
}
}
