<div class="row">
    <br>
    <div class="col-md-12">
        <div class="text-center">
            <h2>Request Service</h2>
        </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <ngx-loading [show]="isLoading"></ngx-loading>
        <div class="miles-editable miles-card padded" *ngIf="abstractCustomerRequestServiceControls && requestServiceForm">
            <h4>Request Service</h4>
            <form [formGroup]="requestServiceForm" (ngSubmit)="formSubmitted();">
                <mt-dynamic-field [field]="abstractCustomerRequestServiceControls.CustomerContactName" [form]="requestServiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerRequestServiceControls.CustomerName" [form]="requestServiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerRequestServiceControls.LocationId" [form]="requestServiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerRequestServiceControls.ServiceTypeId" [form]="requestServiceForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractCustomerRequestServiceControls.Notes" [form]="requestServiceForm"></mt-dynamic-field>
                <br />
                <div>
                    <button type="submit" class="btn btn-primary" [disabled]="isLoading">Submit</button>
                    <button class="btn btn-default" (click)="cancel()" [disabled]="isLoading">Cancel</button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-3"></div>
</div>