import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedEntitiesListComponent, SharedEntitiesInfoComponent } from '@mt-ng2/shared-entities-module';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { CommonNotesListComponent } from '@mt-ng2/entity-components-notes';
import { CommonAddressesListComponent } from '@mt-ng2/entity-components-addresses';
import { CommonDocumentsListComponent } from '@mt-ng2/entity-components-documents';

import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomerService } from './services/customer.service';
import { CustomerAddressesService } from './services/customer-addresses.service';
import { CustomerAddComponent } from './customer-add/customer-add.component';
import { UserService } from '../users/user.service';
import { UserDetailComponent } from '../users/user-detail/user-detail.component';
import { UsersComponent } from '../users/user-list/users.component';
import { LocationsComponent } from './customer-locations/locations-list/locations.component';
import { LocationDetailComponent } from './customer-locations/location-detail/location-detail.component';
import { ContactsComponent } from './customer-contacts/contact-list/contact.component';
import { ContactDetailComponent } from './customer-contacts/contact-detail/contact-detail.component';

const customerEntityConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    contactsPath: 'contacts',
    documentsPath: 'documents',
    entityIdParam: 'customerId',
    notesPath: 'notes',
    service: CustomerService,
    title: 'Customer Detail',
};

const customerListRoleGuard: any = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Customers',
};

const customerAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.FullAccess],
};

export const customerPaths = {
    customers: 'customers',
    customersAdd: 'customers/add',
};

const customerRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CustomersComponent,
        data: customerListRoleGuard,
        path: customerPaths.customers,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: CustomerAddComponent, pathMatch: 'full' }],
        component: CustomerHeaderComponent,
        data: customerAddRoleGuard,
        path: customerPaths.customersAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: CustomerDetailComponent,
                path: '',
                pathMatch: 'full',
            },
            {
                component: ContactsComponent,
                path: 'contacts',
                pathMatch: 'full',
            },
            {
                component: ContactDetailComponent,
                path: 'contacts/add',
                pathMatch: 'full',
            },
            {
                component: ContactDetailComponent,
                path: 'contacts/:userId',
                pathMatch: 'full',
            },
            {
                component: LocationsComponent,
                path: 'locations',
                pathMatch: 'full',
            },
            {
                component: LocationDetailComponent,
                path: 'locations/add',
                pathMatch: 'full',
            },
            {
                component: LocationDetailComponent,
                path: 'locations/:locationId',
                pathMatch: 'full',
            },
            {
                component: CommonAddressesListComponent,
                data: {
                    service: CustomerAddressesService,
                    title: 'Customer Addresses',
                },
                path: customerEntityConfig.addressesPath,
                pathMatch: 'full',
            },
        ],
        component: CustomerHeaderComponent,
        data: customerEntityConfig,
        path: 'customers/:customerId',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(customerRoutes)],
})
export class CustomerRoutingModule {}
