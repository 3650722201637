<div *ngIf="company">
    <div class="row">
        <div class="col-md-6">
            <app-company-basic-info [company]="company" [canEdit]="canEdit"></app-company-basic-info>
            <br />
            <company-add-customers [company]="company" [canEdit]="canEdit"></company-add-customers>
            <br />
        </div>
        <div class="col-md-6">
            <company-contacts-info [company]="company" [canEdit]="canEdit"> </company-contacts-info>
            <br />
        </div>
    </div>
    <a routerLink="/companies" class="btn btn-default">Close</a>
</div>