import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { InvoicePaymentsEntityListConfig } from './invoice-payments.entity-list-config';
import { IInvoicePaymentDetailDTO } from '../../model/partials/invoice-payment-detail-dto';
import { AdminInvoicePaymentService } from '../services/admin-invoice-payment.service';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';

@Component({
    selector: 'app-invoice-payments',
    templateUrl: './invoice-payments.component.html',
})
export class InvoicePaymentsComponent implements OnInit {
    invoicePayments: IInvoicePaymentDetailDTO[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new InvoicePaymentsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    startDate: Date = null;
    endDate: Date = null;
    showExported = false;

    canAddInvoicePayment = false;

    constructor(private invoicePaymentService: AdminInvoicePaymentService, private claimsService: ClaimsService, private router: Router) {}

    ngOnInit(): void {
        this.canAddInvoicePayment = this.claimsService.hasClaim(ClaimTypes.InvoicePayments, [ClaimValues.FullAccess]);
        this.getInvoicePayments();
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.currentPage = 1;
        this.getInvoicePayments();
    }

    getSearchParams(): SearchParams {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (this.startDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StartDate',
                    value: this.startDate.toDateString(),
                }),
            );
        }

        if (this.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'EndDate',
                    value: this.endDate.toDateString(),
                }),
            );
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowExported',
                value: this.showExported.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return searchparams;
    }

    getInvoicePayments(): void {
        const searchParams = this.getSearchParams();
        this.invoicePaymentService.getInvoicePaymentDTO(searchParams).subscribe((answer) => {
            this.invoicePayments = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    downloadCSVExport(): void {
        const searchParams = this.getSearchParams();
        searchParams.skip = null;
        searchParams.take = null;
        this.invoicePaymentService.getCSV(searchParams).subscribe((response) => {
            const mimeType = 'text/csv;charset=utf-8';
            const blob = new Blob([response], { type: mimeType });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'invoice-payments.csv';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            this.getInvoicePayments();
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getInvoicePayments();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getInvoicePayments();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getInvoicePayments();
    }

    selectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.showExported = event.value;
        this.currentPage = 1;
        this.getInvoicePayments();
    }
}
