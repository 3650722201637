import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomerGroup } from '../../model/interfaces/customer-group';

@Injectable()
export class CustomerGroupPhonesService {
    constructor(private http: HttpClient) {}

    saveCustomerGroupPhones(group: ICustomerGroup, phoneCollection: any): any {
        return this.http.put(`/customers/  ${group.CustomerId } /group-phones/ ${group.Id}`, phoneCollection, { responseType: 'text' as 'json' });
    }
}
