import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { IUser } from '@model/interfaces/user';
import { ClaimTypes } from '@model/ClaimTypes';
import { entityListModuleConfig } from '@common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { UserService } from '../../../users/user.service';
import { ContactEntityListConfig } from './contact.entity-list-config';
import { forkJoin } from 'rxjs';
import { IMetaItem } from '../../../model/interfaces/base';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CompanyContactsService } from '../../services/company-contacts.service';
import { CompanyService } from '../../services/company.service';

@Component({
    selector: 'company-contacts',
    templateUrl: './company-contact.component.html',
})
export class CompanyContactsComponent implements OnInit {
    showTitle = true;
    searchControl = new UntypedFormControl();
    users: IUser[];
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;
    companyId: number;
    locations: MtSearchFilterItem[] = [];
    groups: MtSearchFilterItem[] = [];

    entityListConfig = new ContactEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(
        private companyContactsService: CompanyContactsService,
        private companyService: CompanyService,
        private claimsService: ClaimsService,
        public router: Router, // Public because used in HTML
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.companyId = +this.route.snapshot.parent.paramMap.get('companyId');
        this.getUsers();
    }

    getUsers(): void {
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.companyContactsService.get(searchparams).subscribe(
            (answer) => {
                this.users = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (this.companyId) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'CompanyId',
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    value: '' + this.companyId,
                }),
            );
        }

        return _extraSearchParams;
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    search(query: string): void {
        this.query = query;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
        void this.router.navigate([this.router.url, event.entity.Id]);
    }

    selectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.currentPage = 1;
        this.getUsers();
    }
}
