import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';

@Injectable()
export class DocparserService {
    constructor(public http: HttpClient) {}

    uploadDocparserImport(invoiceImport: File): any {
        const formData: FormData = new FormData();
        formData.append('file', invoiceImport, invoiceImport.name);

        return this.http.post(`/doc-parser/upload`, formData);
    }
}
