import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { ICustomerPaymentSource } from '../interfaces/customer-payment-source';
import { IUser } from '../interfaces/user';
import { IPaymentSourceType } from '../interfaces/payment-source-type';

export interface ICustomerPaymentSourceDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
    types?: IPaymentSourceType[];
}

export class CustomerPaymentSourceDynamicControls {
    formGroup: string;
    users?: IUser[];
    types?: IPaymentSourceType[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private customerpaymentsource?: ICustomerPaymentSource,
        additionalParameters?: ICustomerPaymentSourceDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'CustomerPaymentSource';
        this.users = additionalParameters?.users ?? undefined;
        this.types = additionalParameters?.types ?? undefined;
        this.Form = {
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.customerpaymentsource?.UserId || null,
            }),
            TypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                name: 'TypeId',
                options: this.types,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.customerpaymentsource?.TypeId || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.customerpaymentsource?.Name?.toString() || '',
            }),
            Token: new DynamicField({
                formGroup: this.formGroup,
                label: 'Token',
                name: 'Token',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 36 },
                validation: [Validators.maxLength(36)],
                value: this.customerpaymentsource?.Token?.toString() || '',
            }),
            LastFourDigits: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Four Digits',
                name: 'LastFourDigits',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 4 },
                validation: [Validators.maxLength(4)],
                value: this.customerpaymentsource?.LastFourDigits?.toString() || '',
            }),
            OnlyUsedForRecurring: new DynamicField({
                formGroup: this.formGroup,
                label: 'Only Used For Recurring',
                name: 'OnlyUsedForRecurring',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.customerpaymentsource?.OnlyUsedForRecurring || false,
            }),
        };
        this.View = {
            UserId: new DynamicLabel({
                label: 'User',
                value: getMetaItemValue(this.users as unknown as IMetaItem[], this.customerpaymentsource?.UserId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TypeId: new DynamicLabel({
                label: 'Type',
                value: getMetaItemValue(this.types as unknown as IMetaItem[], this.customerpaymentsource?.TypeId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.customerpaymentsource?.Name?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Token: new DynamicLabel({
                label: 'Token',
                value: this.customerpaymentsource?.Token?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastFourDigits: new DynamicLabel({
                label: 'Last Four Digits',
                value: this.customerpaymentsource?.LastFourDigits?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            OnlyUsedForRecurring: new DynamicLabel({
                label: 'Only Used For Recurring',
                value: this.customerpaymentsource?.OnlyUsedForRecurring || false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
