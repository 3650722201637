import { ICustomerContactHelp } from './customer-contact-help';
import { IExpandableObject } from '../expandable-object';
import { Validators } from '@angular/forms';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { ICustomerDisputeInvoice } from './customer-dispute-invoice';

export interface ICustomerDisputeInvoicePartialDynamicControlsParameters {
    formGroup?: string;
}

export class CustomerDisputeInvoiceDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(
        private customerDisputeInvoiceInfo?: ICustomerDisputeInvoice,
        additionalParameters?: ICustomerDisputeInvoicePartialDynamicControlsParameters,
    ) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'disputeInvoiceForm';

        this.Form = {
            CustomerEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Email',
                name: 'CustomerEmail',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true },
                value:
                    this.customerDisputeInvoiceInfo &&
                    this.customerDisputeInvoiceInfo.hasOwnProperty('CustomerEmail') &&
                    this.customerDisputeInvoiceInfo.CustomerEmail !== null
                        ? this.customerDisputeInvoiceInfo.CustomerEmail
                        : '',
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true },
                value:
                    this.customerDisputeInvoiceInfo &&
                    this.customerDisputeInvoiceInfo.hasOwnProperty('CustomerName') &&
                    this.customerDisputeInvoiceInfo.CustomerName !== null
                        ? this.customerDisputeInvoiceInfo.CustomerName
                        : '',
            }),
            CustomerPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Phone',
                name: 'CustomerPhone',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Phone,
                }),
                validators: { required: true },
                value:
                    this.customerDisputeInvoiceInfo &&
                    this.customerDisputeInvoiceInfo.hasOwnProperty('CustomerPhone') &&
                    this.customerDisputeInvoiceInfo.CustomerPhone !== null
                        ? this.customerDisputeInvoiceInfo.CustomerPhone
                        : '',
            }),
            LocationName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Location Name',
                name: 'LocationName',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                }),
                validators: { required: true },
                value:
                    this.customerDisputeInvoiceInfo &&
                    this.customerDisputeInvoiceInfo.hasOwnProperty('LocationName') &&
                    this.customerDisputeInvoiceInfo.LocationName !== null
                        ? this.customerDisputeInvoiceInfo.LocationName
                        : '',
            }),
            Reason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reason',
                name: 'Reason',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                }),
                validators: { required: true, maxlength: 1000 },
                value: '',
            }),
        };
    }
}
