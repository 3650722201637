import { NgModule } from '@angular/core';

import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtDocumentControlModule } from '@mt-ng2/document-control';

import { EntityComponentsDocumentsModule, IEntityComponentsDocumentsModuleConfig, EntityComponentsDocumentsModuleConfigToken } from '@mt-ng2/entity-components-documents';
import { NgxLoadingModule } from 'ngx-loading';
import { DocparserUploadComponent } from './docparser-upload/docparser-upload.component';
import { DocParserRoutingModule } from './docparser-routing.module';
import { DocparserService } from './docparser.service';
import { SharedModule } from '../../common/shared.module';

const entityComponentsDocumentsModuleConfig: IEntityComponentsDocumentsModuleConfig = {
    max: 4,
};

@NgModule({
    declarations: [DocparserUploadComponent],
    imports: [SharedModule, DocParserRoutingModule, EntityComponentsDocumentsModule, MtDocumentControlModule, NgxLoadingModule],
})
export class DocparserModule {
    static forRoot(): any {
        return {
            ngModule: DocparserModule,
            providers: [DocparserService, { provide: EntityComponentsDocumentsModuleConfigToken, useValue: entityComponentsDocumentsModuleConfig }],
        };
    }
}
