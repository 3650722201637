import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';
import { RecurringPaymentRoutingModule } from './recurring-payment-routing.module';
import { RecurringPaymentAddComponent } from './recurring-payment-add/recurring-payment-add.component';
import { RecurringPaymentBasicInfoComponent } from './recurring-payment-basic-info/recurring-payment-basic-info.component';
import { RecurringPaymentDetailComponent } from './recurring-payment-detail/recurring-payment-detail.component';
import { RecurringPaymentsComponent } from './recurring-payment-list/recurring-payments.component';
import { RecurringPaymentHeaderComponent } from './recurring-payment-header/recurring-payment-header.component';

@NgModule({
    declarations: [
        RecurringPaymentsComponent,
        RecurringPaymentHeaderComponent,
        RecurringPaymentAddComponent,
        RecurringPaymentDetailComponent,
        RecurringPaymentBasicInfoComponent,
    ],
    imports: [SharedModule, RecurringPaymentRoutingModule],
})
export class RecurringPaymentModule {}
