import { Component, OnInit } from '@angular/core';
import { FileUploader, FileLikeObject, FileItem } from 'ng2-file-upload';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, of, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DocparserService } from '../docparser.service';

@Component({
    selector: 'app-docparser-upload',
    templateUrl: 'docparser-upload.component.html',
})
export class DocparserUploadComponent implements OnInit {
    allowedMimeTypes: any = ['application/pdf'];
    maxFileSize = 50 * 1024 * 1024;
    isLoading = false;

    constructor(private notificationService: NotificationsService, private docparserService: DocparserService) {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {}

    addDocument(file: FileItem): void {
        if (file && file.file) {
            this.isLoading = true;
            this.docparserService
                .uploadDocparserImport(file._file)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((response) => {
                    if (response) {
                        this.notificationService.success('Successfully uploaded document.');
                    } else {
                        this.notificationService.error('Failure uploading document.');
                    }
                });
        }
    }

    documentFailed(error: string): void {
        this.notificationService.error(error);
    }
}
