import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { CustomerPaymentSourceRoutingModule } from './customer-payment-source-routing.module';
import { CustomerPaymentSourceAddComponent } from './customer-payment-source-add/customer-payment-source-add.component';
import { CustomerPaymentSourceBasicInfoComponent } from './customer-payment-source-basic-info/customer-payment-source-basic-info.component';
import { CustomerPaymentSourceDetailComponent } from './customer-payment-source-detail/customer-payment-source-detail.component';
import { CustomerPaymentSourcesComponent } from './customer-payment-source-list/customer-payment-sources.component';
import { CustomerPaymentSourceHeaderComponent } from './customer-payment-source-header/customer-payment-source-header.component';

@NgModule({
    declarations: [
        CustomerPaymentSourcesComponent,
        CustomerPaymentSourceHeaderComponent,
        CustomerPaymentSourceAddComponent,
        CustomerPaymentSourceDetailComponent,
        CustomerPaymentSourceBasicInfoComponent,
    ],
    imports: [SharedModule, CustomerPaymentSourceRoutingModule],
})
export class CustomerPaymentSourceModule {}
