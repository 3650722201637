import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IMakePaymentPayload } from '../../model/interfaces/custom/make-payment-payload';
import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { IInvoicePayment } from '../../model/interfaces/invoice-payment';
import { IInvoicePaymentDetail } from '../../model/interfaces/invoice-payment-detail';
import { IInvoicePaymentDetailPartial } from '../../model/partials/invoice-payment-detail';

export const emptyInvoicePayment: IInvoicePayment = {
    DateCreated: null,
    Id: 0,
    PaidBy: null,
    PaymentAmt: 0,
    PaymentDate: null,
    PayaConfirmed: false,
    TransactionId: ''
};

export const emptyInvoicePaymentDetail: IInvoicePaymentDetailPartial = {
    Amt: 0,
    Id: 0,
    InvoiceId: 0,
    InvoiceNo: '',
    InvoicePaymentId: 0,
    IsExported: false
};

@Injectable({
    providedIn: 'root',
})
export class InvoicePaymentService extends BaseService<IInvoicePayment> {
    constructor(public http: HttpClient) {
        super('/invoice-payments', http);
    }

    getEmptyInvoicePayment(): IInvoicePayment {
        return { ...emptyInvoicePayment };
    }

    getEmptyInvoicePaymentDetail(): IInvoicePaymentDetailPartial {
        return { ...emptyInvoicePaymentDetail };
    }

    makePayment(params: IMakePaymentPayload): Observable<number> {
        return this.http.post<number>('/invoice-payments/make-payment', params);
    }

    getPaymentSources(): Observable<ICustomerPaymentSource[]> {
        return this.http.get<ICustomerPaymentSource[]>('/invoice-payments/payment-sources');
    }

    getInvoicePaymentDetails(invoiceIds: number[]): Observable<IInvoicePaymentDetailPartial[]> {
        return this.http.put<IInvoicePaymentDetailPartial[]>('/invoice-payments/invoice-payment-details', { InvoiceIds: invoiceIds });
    }

    postPayment(invoicePaymentDetails: IInvoicePaymentDetail[], paymentDate: Date, savedPaymentId: number): any {
        const params = { InvoicePaymentDetails: invoicePaymentDetails, PaymentDate: paymentDate, PaymentSourceId: savedPaymentId };
        return this.http.post('/invoice-payments/pay', params);
    }

    postFuturePayment(invoicePaymentDetails: IInvoicePaymentDetail[], paymentDate: Date, savedPaymentId: number): any {
        const params = { InvoicePaymentDetails: invoicePaymentDetails, PaymentDate: paymentDate, PaymentSourceId: savedPaymentId };
        return this.http.post('/invoice-payments/pay/future', params);
    }
}
