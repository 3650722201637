import { CurrencyPipe, formatDate, formatCurrency } from '@angular/common';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { IRecurringPayment } from '../../model/interfaces/recurring-payment';

export class RecurringPaymentsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['PaymentFrequency', 'Name'],
                    name: 'Frequency',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (x: IRecurringPayment) => (!isNaN(Number(x.Amount)) ? formatCurrency(x.Amount, 'en', '$') : ''),
                    name: 'Amount',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['StartDate'],
                    name: 'Start Date',
                    pipes: ['date:mediumDate:UTC'],
                    sort: {
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (i) => (i.EndDate ? formatDate(i.EndDate as string, 'mediumDate', 'en_US', 'UTC') : 'Never Ending'),
                    name: 'End Date',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerPaymentSource', 'Name'],
                    name: 'Source',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    accessorFunction: (i) => (i.LastDateProcessedOn ? formatDate(i.LastDateProcessedOn, 'mediumDate', 'en_US', 'UTC') : ''),
                    name: 'Last Date Processed On',
                }),
            ],
        };
        super(listConfig);
    }
}
