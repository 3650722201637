<h2>Confirm Payment</h2>
<div>
    <div class="row">
        <div class="col-md-12">
            <div class="miles-form padded" *ngIf="savedPayment" style="max-width: 800px;">
                <table class="table table-responsive" style="padding-top: 30px;" *ngIf="invoicePaymentDetails && invoicePaymentDetails.length">
                    <thead>
                        <tr>
                            <th>Invoices to be Paid</th>
                            <th style="text-align: center;">Invoice Payment Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of invoicePaymentDetails; let i = index">
                            <td (click)="showPdfPreview(item)" style="vertical-align: middle;">{{ item.InvoiceNo }}</td>
                            <td class="right-align" style="padding-right: 35px !important;">
                                {{ item.Amt | currency }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                Total Payment Amount
                            </td>
                            <td class="right-align" style="padding-right: 35px !important;">
                                {{ paymentAmount | currency }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Payment Date
                            </td>
                            <td class="right-align" style="padding-right: 35px !important;">
                                {{ paymentDate | date: mediumDate:UTC }}
                            </td>
                        </tr>
                        <tr *ngIf="paymentSource">
                            <td>
                                Payment Source
                            </td>
                            <td class="right-align" style="padding-right: 35px !important;">
                                {{ paymentSource }}
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <br />
                <div class="row">
                    <button class="btn btn-success pull-right" style="margin-right: 10px !important;" (click)="confirm()" [disabled]="isLoading">
                        Confirm
                    </button>
                    <button class="btn btn-default pull-right" style="margin-right: 3px !important;" (click)="cancel()">Cancel</button>
                </div>
            </div>
            <div *ngIf="!savedPayment">
                No payment to confirm.
            </div>
        </div>
    </div>
</div>
