import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDashboardSearchParams } from '../model/interfaces/custom/dashboard-search-params';
import { ICustomerDashboardReturn } from '../model/partials/customer-dashboard-return';

@Injectable()
export class CustomerDashboardService {
    constructor(public http: HttpClient) {}

    getDashboard(searchParams: IDashboardSearchParams): any {
        return this.http.put<ICustomerDashboardReturn>('/customer-dashboard', searchParams);
    }
}
