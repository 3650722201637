import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IUserDynamicControlsParameters, UserDynamicControls } from '../form-controls/user.form-controls';
import { ICustomerGroup } from '../interfaces/customer-group';
import { ICustomerLocation } from '../interfaces/customer-location';
import { IUser } from '../interfaces/user';
import { UserRoles } from '../UserRoles';

export interface ICustomerContactDynamicControlsParametersPartial extends IUserDynamicControlsParameters {
    locations?: ICustomerLocation[];
    groups?: ICustomerGroup[];
    selectedLocation?: ICustomerLocation;
    selectedGroup?: ICustomerGroup;
}

export class CustomerContactDynamicControlsPartial extends UserDynamicControls {
    constructor(user?: IUser, additionalParameters?: ICustomerContactDynamicControlsParametersPartial) {
        super(user, additionalParameters);

        (additionalParameters.locations as any).unshift({ Id: null, Name: 'N/A' });
        (additionalParameters.groups as any).unshift({ Id: null, Name: 'N/A' });

        this.Form.CustomerLocationId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Location',
            name: 'CustomerLocationId',
            options: additionalParameters.locations,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: user.CustomerLocationId,
        });

        this.Form.CustomerGroupId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Group',
            name: 'CustomerGroupId',
            options: additionalParameters.groups,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: user.CustomerGroupId,
        });

        this.Form.IsAdmin = new DynamicField({
            formGroup: this.formGroup,
            label: 'Admin',
            name: 'IsAdmin',
            options: additionalParameters.groups,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [ ],
            validators: { },
            value: user.AuthUser && user.AuthUser.RoleId === UserRoles.AdminContacts,
        });
    }
}
