import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, NumericInputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IRecurringPaymentDynamicControlsParameters, RecurringPaymentDynamicControls } from '../form-controls/recurring-payment.form-controls';
import { IUserDynamicControlsParameters, UserDynamicControls } from '../form-controls/user.form-controls';
import { ICustomerGroup } from '../interfaces/customer-group';
import { ICustomerLocation } from '../interfaces/customer-location';
import { IRecurringPayment } from '../interfaces/recurring-payment';
import { IUser } from '../interfaces/user';
import { PaymentFrequencies } from '../PaymentFrequencies';

export interface IRecurringPaymentDynamicControlsParametersPartial extends IRecurringPaymentDynamicControlsParameters {}

export class RecurringPaymentDynamicControlsPartial extends RecurringPaymentDynamicControls {
    constructor(private recurringpaymentPartial?: IRecurringPayment, additionalParameters?: IRecurringPaymentDynamicControlsParametersPartial) {
        super(recurringpaymentPartial, additionalParameters);

        this.Form.NeverEnding = new DynamicField({
            formGroup: this.formGroup,
            label: 'Never Ending',
            name: 'NeverEnding',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value:
                this.recurringpaymentPartial.FrequencyId === PaymentFrequencies.OneTime ||
                (this.recurringpaymentPartial && this.recurringpaymentPartial.EndDate)
                    ? false
                    : true,
        });

        (<DynamicField>this.Form.Amount).type.inputType = NumericInputTypes.Currency;
        (<DynamicLabel>this.View.Amount).type.inputType = NumericInputTypes.Currency;
        (<DynamicField>this.Form.StartDate).setRequired(true);
        let today = new Date();
        (<DynamicField>this.Form.StartDate).type.datepickerOptions = {
            minDate: {
                day: today.getDate(),
                month: today.getMonth() + 1,
                year: today.getFullYear(),
            },
        };
        (<DynamicField>this.Form.EndDate).type.datepickerOptions = {
            minDate: {
                day: today.getDate(),
                month: today.getMonth() + 1,
                year: today.getFullYear(),
            },
        };

        if (!this.recurringpaymentPartial.EndDate) {
            ((<DynamicLabel>this.View.EndDate).type = new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            })),
                ((<DynamicLabel>this.View.EndDate).value = 'Never Ending');
        }
    }
}
