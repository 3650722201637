<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && user.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        User Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>User Info</h4>
    <company-contact-info-form
        [companyId]="companyId"
        [user]="user"
        [canEdit]="canEdit"
        (successEmitter)="success($event)"
        (cancelEmitter)="cancel()"
    ></company-contact-info-form>
</div>
