import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FileService } from '../../common/services/file.service';
import { IInvoice } from '../../model/interfaces/invoice';
import { CustomerInvoiceService } from '../services/customer-invoice.service';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { FileItem } from 'ng2-file-upload';
import { InvoiceService } from '../../invoices/services/invoice.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    selector: 'app-customer-invoice-documents-preview',
    styles: [
        `
            .manifest-cell {
                padding: 10px;
                margin: 10px;
                background: #dbdbdb;
                border-radius: 8px;
                cursor: pointer;
            }
            .invoice-pdf-view {
                margin: auto;
                width: 302px;
            }
            .invoice-pdf-wrapper {
                width: 302px;
                border: 1px solid lightgray;
                cursor: pointer;
            }
            drag-scroll {
                user-select: none;
                -moz-user-select: none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                -o-user-select: none;
            }
        `,
    ],
    templateUrl: './customer-invoice-documents-preview.component.html',
})
export class CustomerInvoicesDocumentsPreviewComponent implements OnInit {
    @Input() invoice: IInvoice;
    documentPdfDialog: IModalWrapperApi;
    manifestPdfs: Map<number, any> = new Map();
    invoicePdf: any;
    zoomedPdfSrc: any;
    currentTitle: string;
    manifestIndex: number;
    allowedMimeTypes: string[] = ['application/pdf'];
    isEditing = false;
    canAddDocuments = false;

    constructor(private customerInvoiceService: CustomerInvoiceService,
        private invoiceService: InvoiceService,
        private notifcationService: NotificationsService,
        private claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.canAddDocuments = this.claimsService.hasClaim(ClaimTypes.UploadInvoiceDocs, [ClaimValues.FullAccess]);
        if (this.canAddDocuments === undefined) {
            this.canAddDocuments = false;
        }
        this.loadPdfs();
    }

    loadPdfs(): void {
        if (this.invoice.InvoiceDocuments && this.invoice.InvoiceDocuments.length) {
            this.getPdfForInvoice(this.invoice.InvoiceDocuments[0].DocumentId);
        }
        if (this.invoice.InvoiceManifests && this.invoice.InvoiceManifests.length) {
            this.invoice.InvoiceManifests.forEach((invoiceManifest) => {
                if (invoiceManifest.InvoiceManifestDocuments && invoiceManifest.InvoiceManifestDocuments.length) {
                    this.getPdfDocumentForManifest(invoiceManifest.InvoiceManifestDocuments[0].DocumentId, invoiceManifest.Id);
                }
            });
        }
    }

    setUploadView(): void {
        if (!this.canAddDocuments) {
            return;
        }
        this.isEditing = true;
    }

    cancelUploadView(): void {
        this.isEditing = false;
    }

    onAddingFileFailed(error: string): void {
        this.notifcationService.error(error);
    }

    addDocument(file: FileItem): void {
        this.invoiceService.uploadAssociatedInvoiceDoc(this.invoice.Id, file._file).subscribe((result) => {
            this.getPdfForInvoice(result);
            this.cancelUploadView();
        });
    }

    getPdfForInvoice(documentId: number): void {
        this.customerInvoiceService.getDocument(documentId).subscribe((result) => {
            this.invoicePdf = result;
        });
    }

    getPdfDocumentForManifest(documentId: number, manifsetId: number): void {
        this.customerInvoiceService.getDocument(documentId).subscribe((result) => {
            this.manifestPdfs.set(manifsetId, result);
        });
    }

    downloadPdf(pdf: any, index: number): void {
        const fileName = !index ? `invoice-${this.invoice.InvoiceNo}.pdf` : `invoice-${this.invoice.InvoiceNo}-manifest-${index}.pdf`;
        FileService.save(pdf as string, fileName, 'application/pdf', false);
    }

    viewPdf(pdf: any, index = 0): void {
        this.manifestIndex = index;
        this.currentTitle = this.invoice.InvoiceNo;
        this.zoomedPdfSrc = pdf;
        this.documentPdfDialog.show();
    }

    getPdfForManifest(manifestId: number): any {
        return this.manifestPdfs.get(manifestId);
    }
}
