import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { CompanyService } from '../services/company.service';
import { CompanyDynamicConfig } from '../company.dynamic-config';
import { ICompany } from '../../model/interfaces/company';

@Component({
    selector: 'app-company-basic-info',
    templateUrl: './company-basic-info.component.html',
})
export class CompanyBasicInfoComponent implements OnInit {
    @Input() company: ICompany;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: CompanyDynamicConfig<ICompany>;
    doubleClickIsDisabled = false;

    get isNewCompany(): boolean {
        return this.company && this.company.Id ? false : true;
    }

    constructor(private companyService: CompanyService, private notificationsService: NotificationsService, private router: Router) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new CompanyDynamicConfig<ICompany>(this.company);

        if (this.isNewCompany) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewCompany) {
            void this.router.navigate(['/companies']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.company, form.value.Company as ICompany);
            this.saveCompany();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveCompany(): void {
        if (this.isNewCompany) {
            this.companyService.create(this.company).subscribe((answer) => {
                this.company.Id = answer;
                this.success(true);
            });
        } else {
            this.companyService.update(this.company).subscribe((success) => {
                this.success();
            });
        }
    }

    private success(newCompanySave?: boolean): void {
        if (newCompanySave) {
            void this.router.navigate([`/companies/${this.company.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.companyService.emitChange(this.company);
        this.notificationsService.success('Company saved successfully.');
    }
}
