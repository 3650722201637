import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { CustomerPaymentSourceDynamicConfig } from '../customer-payment-source.dynamic-config';
import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { IPaymentSourceType } from '../../model/interfaces/payment-source-type';
import { IUser } from '../../model/interfaces/user';
import { CustomerPaymentSourceService } from '../services/customer-payment-source.service';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-customer-payment-source-basic-info',
    templateUrl: './customer-payment-source-basic-info.component.html',
})
export class CustomerPaymentSourceBasicInfoComponent implements OnInit {
    @Input() customerPaymentSource: ICustomerPaymentSource;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: CustomerPaymentSourceDynamicConfig<ICustomerPaymentSource>;
    doubleClickIsDisabled = false;

    types: IPaymentSourceType[];
    users: IUser[];

    get isNewCustomerPaymentSource(): boolean {
        return this.customerPaymentSource && this.customerPaymentSource.Id ? false : true;
    }

    constructor(
        private customerPaymentSourceService: CustomerPaymentSourceService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new CustomerPaymentSourceDynamicConfig<ICustomerPaymentSource>(this.customerPaymentSource, this.types, this.users);

        if (this.isNewCustomerPaymentSource) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewCustomerPaymentSource) {
            void this.router.navigate(['/customer-payment-sources']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.customerPaymentSource, form.value.CustomerPaymentSource as ICustomerPaymentSource);
            this.saveCustomerPaymentSource();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveCustomerPaymentSource(): void {
        if (this.isNewCustomerPaymentSource) {
            this.customerPaymentSourceService.create(this.customerPaymentSource).subscribe((answer) => {
                this.customerPaymentSource.Id = answer;
                this.success(true);
            });
        } else {
            this.customerPaymentSourceService.update(this.customerPaymentSource).subscribe((success) => {
                this.success();
            });
        }
    }

    private success(newCustomerPaymentSourceSave?: boolean): void {
        if (newCustomerPaymentSourceSave) {
            void this.router.navigate([`/customer-payment-sources/${this.customerPaymentSource.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.customerPaymentSourceService.emitChange(this.customerPaymentSource);
        this.notificationsService.success('CustomerPaymentSource saved successfully.');
    }
}
