import { NgModule } from '@angular/core';

import { NavModule, NavSidebarServiceToken, INavModuleConfig, NavModuleConfigToken } from '@mt-ng2/nav-module';

import { NavConfigOverride } from '../common/configs/nav.config';
import { NavSidebarService } from './sidebar.service';

@NgModule({
    exports: [NavModule],
    imports: [NavModule],
})
export class AppNavModule {
    static forRoot(): any {
        return {
            ngModule: AppNavModule,
            providers: [
                { provide: NavSidebarServiceToken, useClass: NavSidebarService },
                { provide: NavModuleConfigToken, useValue: NavConfigOverride},
            ],
        };
    }
}
