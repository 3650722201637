import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { ICustomerDynamicControlsParameters } from '@model/form-controls/customer.form-controls';
import { CustomerDynamicControlsPartial } from '@model/partials/customer.form-controls';
import { ICustomer } from '@model/interfaces/customer';
import { IAuthUser } from '../model/interfaces/auth-user';
import { UserRoles } from '../model/UserRoles';

export class CustomerDynamicConfig<T extends ICustomer> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private customer: T, private authUser: IAuthUser, private configControls?: string[]) {
        super();
        const additionalParams: ICustomerDynamicControlsParameters = {};
        const dynamicControls = new CustomerDynamicControlsPartial(this.customer, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            if (authUser.RoleId === UserRoles.Administrator) {
                this.configControls = ['Name', 'CustomerNo', 'Archived', 'Company'];
            } else {
                this.configControls = ['Name', 'Archived'];
            }
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
