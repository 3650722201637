import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IEntityListComponentMembers, IEntityListDynamicCellComponent } from '@mt-ng2/entity-list-module';
import { finalize } from 'rxjs/operators';
import { IInvoice } from '../../model/interfaces/invoice';
import { IInvoicePaymentDetailDTO } from '../../model/partials/invoice-payment-detail-dto';
import { AdminInvoicePaymentService } from '../services/admin-invoice-payment.service';

@Component({
    template: `
        <button *ngIf="item.IsExported" [disabled]="isLoading" (click)="markAsNotExported()" class="btn btn-primary btn-xs">
            Mark As Not Exported
        </button>
    `,
})
export class AdminInvoicesListActionComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;
    item: IInvoicePaymentDetailDTO;
    isLoading = false;

    constructor(private router: Router, private adminInvoicePaymentService: AdminInvoicePaymentService) {}

    set entity(value: any) {
        this.item = value as IInvoicePaymentDetailDTO;
    }

    markAsNotExported(): void {
        this.isLoading = true;
        this.adminInvoicePaymentService
            .updateAsNotExported(this.item.Id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((result) => {
                this.entityListComponentMembers.getEntities();
            });
    }
}
