import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';

import { DynamicFormModule, DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { MtNoteControlModule } from '@mt-ng2/note-control';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { MtDisableDuringHttpCallsModule  } from '@mt-ng2/disable-during-http-calls';
import { EntityListModule, IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MtDateModule } from '@mt-ng2/date-module';
import { MtFabSpeedDialControlModule } from '@mt-ng2/fab-speed-dial-control';


import { CommonService } from './services/common.service';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { AuthEntityService } from '../auth-entity/auth-entity.service';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';

import { MtPhotoControlModule } from '@mt-ng2/photo-control';

import { AuditLogModule, AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module';
import { AuditLogUserService } from './services/audit-log-user.service';
import { auditLogModuleConfig } from './configs/audit-log.config';
import { ModalModule } from '@mt-ng2/modal-module';
import { ServiceTypesService } from './services/service-types.service';
import { NavModule } from '@mt-ng2/nav-module';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

@NgModule({
    exports: [
    AuthEntityModule,
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DynamicFormModule,
    NgxMaskModule,
    MtNoteControlModule,
    EntityComponentsNotesModule,
    EntityComponentsAddressesModule,
    MtManagedListControlModule,
    MtDocumentControlModule,
    EntityComponentsDocumentsModule,
    EntityComponentsPhonesModule,
    MtSearchFilterSelectModule,
    MtSearchFilterDaterangeModule,
    MtDisableDuringHttpCallsModule,
    EntityListModule,
    ModalModule,
    MtSearchBarControlModule,
    SharedEntitiesModule,
    MtSearchFilterCheckboxModule,
    BackButtonModule,
    MtDateModule,
    MtFabSpeedDialControlModule,
    TypeAheadModule,
    KeyboardShortcutModule,
    MtPhotoControlModule,
    AuditLogModule,
    ModalModule,
    NavModule,
],
    imports: [
    AuthEntityModule,
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    BrowserAnimationsModule,
    DynamicFormModule,
    NgxMaskModule,
    FileUploadModule,
    MtNoteControlModule,
    EntityComponentsNotesModule,
    MtManagedListControlModule,
    EntityComponentsAddressesModule,
    ModalModule,
    AuditLogModule,
    MtDocumentControlModule,
    EntityComponentsDocumentsModule,
    EntityComponentsPhonesModule,
    MtSearchFilterSelectModule,
    MtSearchFilterDaterangeModule,
    MtDisableDuringHttpCallsModule,
    EntityListModule,
    MtSearchBarControlModule,
    SharedEntitiesModule,
    MtSearchFilterCheckboxModule,
    BackButtonModule,
    MtDateModule,
    MtFabSpeedDialControlModule,
    TypeAheadModule,
    KeyboardShortcutModule,
    MtPhotoControlModule,
    ModalModule,
    NavModule,
],
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                CommonService,
                AuthEntityService,
                AuditLogUserService,
                KeyboardShortcutService,
                ServiceTypesService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useExisting: AuditLogUserService },
            ],
        };
    }
}
