import { CurrencyPipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { CustomerInvoiceService } from '../../customer-invoices/services/customer-invoice.service';
import { CustomerPaymentSourceService } from '../../customer-payment-sources/services/customer-payment-source.service';
import { InvoiceService } from '../../invoices/services/invoice.service';
import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { IInvoice } from '../../model/interfaces/invoice';
import { IInvoicePaymentDetail } from '../../model/interfaces/invoice-payment-detail';
import { IInvoicePaymentDetailPartial } from '../../model/partials/invoice-payment-detail';
import { InvoicePdfPreviewComponent } from '../invoice-pdf-preview/invoice-pdf-preview.component';
import { InvoicePaymentStateService } from '../services/invoice-payment-state.service';
import { InvoicePaymentService } from '../services/invoice-payment.service';

@Component({
    selector: 'manage-invoices-component',
    styles: [
        `
            tr > th {
                background-color: white !important;
                font-size: 15px;
                padding-bottom: 15px;
                padding-top: 20px;
            }
            tfoot tr td {
                padding-top: 25px !important;
                padding-bottom: 25px !important;
            }
            tbody tr td {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
                border: none !important;
            }
            tbody {
                display: block;
                height: 320px;
                overflow-y: auto;
                overflow-x: hidden;
            }
            thead,
            tbody tr,
            tfoot {
                display: table;
                width: 100%;
                table-layout: fixed; /* even columns width , fix width of table too*/
            }
            .right-align {
                text-align: end !important;
            }
            .no-invoices-text {
                padding: 25px;
                font-size: 22px;
            }
        `,
    ],
    templateUrl: './invoice-payment-manage-invoices.component.html',
})
export class InvoicePaymentManageInvoicesComponent implements OnInit {
    documentPdfDialog: IModalWrapperApi;
    @ViewChild('pdfPreview') invoicePdfComponent: InvoicePdfPreviewComponent;

    invoicePaymentDetails: IInvoicePaymentDetailPartial[];
    paymentDate = new Date();
    isLoading = false;
    pdfInvoiceId: number;
    selectedPaymentId = 0;
    paymentSources: ICustomerPaymentSource[];

    constructor(
        private invoicePaymentService: InvoicePaymentService,
        private customerInvoiceService: CustomerInvoiceService,
        private location: Location,
        private router: Router,
        private notificationsService: NotificationsService,
        private invoicePaymentStateService: InvoicePaymentStateService,
        private activatedRoute: ActivatedRoute,
        private customerPaymentSourceService: CustomerPaymentSourceService,
    ) {}

    ngOnInit(): void {
        this.paymentDate.setHours(0, 0, 0, 0);
        this.getSavedPayments();
        if (this.invoicePaymentStateService.getSavedPayment()) {
            this.invoicePaymentDetails = this.invoicePaymentStateService.getSavedPayment().paymentDetails;
            this.paymentDate = this.invoicePaymentStateService.getSavedPayment().paymentDate;
            this.selectedPaymentId = this.invoicePaymentStateService.getSavedPayment().savedPaymentId;
        } else {
            this.getInvoicePaymentDetails(this.customerInvoiceService.getSelectedInvoiceIdsForPayment());
        }
    }

    getSavedPayments(): void {
        this.invoicePaymentService.getPaymentSources().subscribe((result) => {
            this.paymentSources = result;
            const empty = this.customerPaymentSourceService.getEmptyCustomerPaymentSource();
            empty.Name = 'No Payment Source Selected';
            this.paymentSources.push(empty);
        });
    }

    getInvoicePaymentDetails(selectedInvoices: number[]): void {
        this.invoicePaymentService.getInvoicePaymentDetails(selectedInvoices).subscribe((result) => {
            this.invoicePaymentDetails = result;
        });
    }

    getTotalPayment(): number {
        if (this.invoicePaymentDetails && this.invoicePaymentDetails.length) {
            return this.invoicePaymentDetails.map((d) => d.Amt).reduce((a, b) => a + b, 0);
        } else {
            return 0;
        }
    }

    save(saveInvoicePaymentDetails: IInvoicePaymentDetail[]): void {
        if (saveInvoicePaymentDetails.some((d) => !d.Amt)) {
            return this.notificationsService.error('No invoice can have payment of 0');
        }
        if (!saveInvoicePaymentDetails.map((d) => d.Amt).reduce((a, b) => a + b)) {
            return this.notificationsService.error('Total payment must be greater than 0');
        }
        if (this.paymentDate == null) {
            return this.notificationsService.error('Please select a payment date');
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        let paymentDateObject = this.paymentDate;
        if (!(paymentDateObject instanceof Date)) {
            paymentDateObject = new Date(paymentDateObject);
        }
        if (paymentDateObject > today && !this.selectedPaymentId) {
            return this.notificationsService.error('Selecting a payment source is required if payment date is in future');
        }
        const selectedPaymentName = this.selectedPaymentId ? this.paymentSources.find((ps) => ps.Id === this.selectedPaymentId).Name : null;
        this.invoicePaymentStateService.setSavedPayment(saveInvoicePaymentDetails, this.paymentDate, this.selectedPaymentId, selectedPaymentName);
        void this.router.navigate(['confirm'], { relativeTo: this.activatedRoute });
    }

    deletePaymentInvoiceDetail(index: number): void {
        this.invoicePaymentDetails.splice(index, 1);
    }

    goBack(): void {
        void this.router.navigate(['/customer-invoices']);
    }

    showPdfPreview(event: IInvoicePaymentDetailPartial): void {
        this.pdfInvoiceId = event.InvoiceId;
        if (this.invoicePdfComponent) {
            this.invoicePdfComponent.invoiceId = this.pdfInvoiceId;
            this.invoicePdfComponent.ngOnInit();
        }
        this.documentPdfDialog.show();
    }
}
