import { NgModule } from '@angular/core';

import { InvoicePaymentsComponent } from './invoice-payment-list/invoice-payments.component';
import { InvoicePaymentHeaderComponent } from './invoice-payment-header/invoice-payment-header.component';
import { AdminInvoicePaymentRoutingModule } from './admin-invoice-payment-routing.module';
import { SharedModule } from '../common/shared.module';
import { AdminInvoicesListActionComponent } from './invoice-payment-list/admin-invoices-list-action.component';

@NgModule({
    declarations: [InvoicePaymentsComponent, InvoicePaymentHeaderComponent, AdminInvoicesListActionComponent],
    imports: [SharedModule, AdminInvoicePaymentRoutingModule]
})
export class AdminInvoicePaymentModule {}
