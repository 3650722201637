import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IInvoicePaymentDetailPartial } from '../../model/partials/invoice-payment-detail';
import { ISavedPaymentPartial } from '../../model/partials/saved-payment-partial';
import { InvoicePaymentStateService } from '../services/invoice-payment-state.service';
import { InvoicePaymentService } from '../services/invoice-payment.service';

@Component({
    styles: [
        `
            tr > th {
                background-color: white !important;
                font-size: 15px;
                padding-bottom: 15px;
                padding-top: 20px;
            }
            tfoot tr td {
                padding-top: 25px !important;
                padding-bottom: 25px !important;
            }
            tbody tr td {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
                border: none !important;
            }
            tbody {
                display: block;
                overflow-y: auto;
                overflow-x: hidden;
                margin-bottom: 30px;
            }
            thead,
            tbody tr,
            tfoot {
                display: table;
                width: 100%;
                table-layout: fixed; /* even columns width , fix width of table too*/
            }
            .right-align {
                text-align: end !important;
            }
            .no-invoices-text {
                padding: 25px;
                font-size: 22px;
            }
        `,
    ],
    templateUrl: './confirm-payment.component.html',
})
export class ConfirmPaymentComponent {
    savedPayment: ISavedPaymentPartial;
    paymentAmount: number;
    paymentDate: Date;
    paymentSource: string;
    invoices: string[];
    invoicePaymentDetails: IInvoicePaymentDetailPartial[];
    isLoading = false;

    constructor(
        private invoicePaymentStateService: InvoicePaymentStateService,
        private invoicePaymentService: InvoicePaymentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.savedPayment = this.invoicePaymentStateService.getSavedPayment();
        if (this.savedPayment) {
            this.invoicePaymentDetails = this.savedPayment.paymentDetails;
            this.paymentDate = this.savedPayment.paymentDate;
            this.paymentAmount = this.savedPayment.paymentDetails.map((d) => d.Amt).reduce((a, b) => a + b);
            this.paymentSource = this.savedPayment.savedPaymentName;
        }
    }

    cancel(): void {
        void this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }

    confirm(): void {
        const savedPayment = this.invoicePaymentStateService.getSavedPayment();
        let paymentDateObject = this.savedPayment.paymentDate;
        if (!(paymentDateObject instanceof Date)) {
            paymentDateObject = new Date(paymentDateObject);
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.isLoading = true;
        if (paymentDateObject > today) {
            this.invoicePaymentService
                .postFuturePayment(savedPayment.paymentDetails, savedPayment.paymentDate, savedPayment.savedPaymentId)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    void this.router.navigate(['/invoice-payment/success/scheduled']);
                });
        } else {
            this.invoicePaymentService
                .postPayment(savedPayment.paymentDetails, savedPayment.paymentDate, savedPayment.savedPaymentId)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    const form = document.createElement('form');
                    const element1 = document.createElement('input');
                    form.method = 'POST';
                    form.action = 'https://www.sageexchange.com/sevd/frmpayment.aspx';
                    element1.value = result;
                    element1.name = 'request';
                    form.appendChild(element1);

                    const element2 = document.createElement('input');
                    element2.value = environment.baseApiUrl + '/api/v1/invoice-payments/postPayaResponse';

                    // eslint-disable-next-line no-console
                    console.log(element2.value);
                    element2.name = 'redirect_url';
                    form.appendChild(element2);
                    document.body.appendChild(form);
                    form.submit();
                });
        }
    }
}
