import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { CompanyService } from '../services/company.service';
import { ICompany } from '../../model/interfaces/company';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    templateUrl: './company-detail.component.html',
})
export class CompanyDetailComponent implements OnInit {
    company: ICompany;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private companyService: CompanyService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Companies, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'companyId');
        if (id) {
            this.getCompanyById(id);
        } else {
            void this.router.navigate(['companies']); // if no id found, go back to list
        }
    }

    getCompanyById(id: number): void {
        this.companyService.getById(id).subscribe((company) => {
            if (company === null) {
                this.notificationsService.error('Company not found');
                void this.router.navigate(['companies']);
            }
            this.company = company;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return  isNaN(parseInt(id, 10))  ? null : parseInt(id, 10);
    }

}
