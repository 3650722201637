import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { IPhone } from '@model/interfaces/base';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IAddress } from '@model/interfaces/address';

@Injectable()
export class CustomerContactsService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        IsArchived: false,
        LastName: null,
        Title: '',
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/customercontacts', http);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<IUser> {
        return this.http.post<IUser>(`/customercontacts/create`, data);
    }

    updateVersion(user: IUser): Observable<number[]> {
        return this.http.put<number[]>(`/customercontacts/${user.Id}/version-contact`, user);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/customercontacts/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/customercontacts/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/customercontacts/ ${userId} /address`, {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/customercontacts/${userId}/phones`, phones);
    }
}
