import { IEntityListConfig, EntityListConfig, IEntityListColumn, EntityListColumn, SortDirection, EntityListDeleteColumn } from '@mt-ng2/entity-list-module';
import { IUser } from '@model/interfaces/user';

export class ContactEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: (user: IUser) => `${user.FirstName} ${user.LastName}`,
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['UserPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    linkFunction: function (user: IUser): void {
                        window.open(`mailto:${user.Email}`);
                    },
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessorFunction: (c) => (c.CustomerLocation ? c.CustomerLocation.Name : ''),
                    name: 'Location',
                    sort: {
                        sortProperty: 'CustomerLocation.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (c) => (c.CustomerGroup ? c.CustomerGroup.Name : ''),
                    name: 'Group',
                    sort: {
                        sortProperty: 'CustomerGroup.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (c) => (c.AuthUser.HasAccess ? 'Has Access' : ''),
                    name: 'Access',
                    sort: {
                        sortProperty: 'AuthUser.HasAccess',
                    },
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: '<i class="fa fa-2x fa-fw fa-trash icon-main"></i>',
                confirm: {
                    confirmButtonText: 'Delete',
                    text: 'Are you sure you want to delete this contact?',
                    title: 'Delete Contact?',
                },
                headerText: 'Delete',
            }),
        };
        super(listConfig);
    }
}
