import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { AuthService } from '@mt-ng2/auth-module';
import { AddressService } from '../../../common/services/address.service';
import { IUser } from '../../../model/interfaces/user';
import { IAddress } from '@model/interfaces/address';
import { CompanyContactsService } from '../../services/company-contacts.service';
import { ClaimTypes } from '../../../model/ClaimTypes';
import { IPhone } from '@mt-ng2/phone-control';

@Component({
    selector: 'company-contact-detail',
    templateUrl: './company-contact-detail.component.html',
})
export class CompanyContactDetailComponent implements OnInit {
    user: IUser;
    currentUser;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    userAddress: IAddress;
    canAdd: boolean;
    myProfile: boolean;

    constructor(
        private companyContactsService: CompanyContactsService,
        private authService: AuthService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,

        private addressService: AddressService,
        private location: Location,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        // get current id from route
        let id = +this.route.snapshot.paramMap.get('userId');
        // check if this is the my-profile path
        if (this.route.snapshot.routeConfig.path === 'my-profile') {
            this.myProfile = true;
            id = this.currentUser = this.authService.currentUser.getValue().Id;
        }
        // try load if id > 0
        if (id > 0) {
            this.getUserById(id);
        } else {
            // set user to emptyUser
            this.user = this.companyContactsService.getEmptyUser();
        }

        this.editingComponent.next('');
    }

    getUserById(id: number): void {
        this.companyContactsService.getById(id).subscribe((user) => {
            if (user == null) {
                this.notificationsService.error('Contact not found');
                this.location.back();
                return;
            }
            this.user = user;
            this.canEdit = this.user.AuthUser && this.user.AuthUser.IsEditable;
            this.currentUser = this.authService.currentUser.getValue();
            this.userAddress = user.Address;
        });
    }

    saveAddress(address: IAddress): void {
        this.companyContactsService.saveAddress(this.user.Id, address).subscribe((answer) => {
            address.Id = answer;
            this.notificationsService.success('Address Saved Successfully');
            this.user.Address = address;
            this.userAddress = address;
        });
    }

    deleteAddress(address): void {
        this.companyContactsService.deleteAddress(this.user.Id).subscribe((answer) => {
            this.notificationsService.success('Address Deleted Successfully');
            this.userAddress = null;
            this.user.Address = null;
        });
    }

    savePhones(phoneCollection: any): void {
        this.companyContactsService.savePhones(this.user.Id, phoneCollection as IPhone).subscribe((answer) => {
            this.notificationsService.success('Phones Saved Successfully');
            this.user.UserPhones = phoneCollection.Phones;
            this.editingComponent.next('');
        });
    }

    close(): void {
        this.location.back();
    }
}
