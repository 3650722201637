import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { IAuthUser } from '../interfaces/auth-user';
import { IUserRole } from '../interfaces/user-role';

export interface IAuthUserDynamicControlsParameters {
    formGroup?: string;
    roles?: IUserRole[];
}

export class AuthUserDynamicControls {
    formGroup: string;
    roles?: IUserRole[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private authuser?: IAuthUser,
        additionalParameters?: IAuthUserDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'AuthUser';
        this.roles = additionalParameters?.roles ?? undefined;
        this.Form = {
            Username: new DynamicField({
                formGroup: this.formGroup,
                label: 'Username',
                name: 'Username',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.authuser?.Username?.toString() || '',
            }),
            Password: new DynamicField({
                formGroup: this.formGroup,
                label: 'Password',
                name: 'Password',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [Validators.required],
                value: this.authuser?.Password?.toString() || '',
            }),
            Salt: new DynamicField({
                formGroup: this.formGroup,
                label: 'Salt',
                name: 'Salt',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [Validators.required],
                value: this.authuser?.Salt?.toString() || '',
            }),
            ResetKey: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reset Key',
                name: 'ResetKey',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.authuser?.ResetKey?.toString() || '',
            }),
            ResetKeyExpirationUtc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reset Key Expiration Utc',
                name: 'ResetKeyExpirationUtc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.authuser?.ResetKeyExpirationUtc || null,
            }),
            RoleId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Role',
                name: 'RoleId',
                options: this.roles,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.authuser?.RoleId || null,
            }),
            HasAccess: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Access',
                name: 'HasAccess',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.authuser?.HasAccess || true,
            }),
            IsEditable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Editable',
                name: 'IsEditable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.authuser?.IsEditable || true,
            }),
        };
        this.View = {
            Username: new DynamicLabel({
                label: 'Username',
                value: this.authuser?.Username?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Password: new DynamicLabel({
                label: 'Password',
                value: this.authuser?.Password?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                    inputType: null,
                    scale: null,
                }),
            }),
            Salt: new DynamicLabel({
                label: 'Salt',
                value: this.authuser?.Salt?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ResetKey: new DynamicLabel({
                label: 'Reset Key',
                value: this.authuser?.ResetKey?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ResetKeyExpirationUtc: new DynamicLabel({
                label: 'Reset Key Expiration Utc',
                value: this.authuser?.ResetKeyExpirationUtc || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            RoleId: new DynamicLabel({
                label: 'Role',
                value: getMetaItemValue(this.roles as unknown as IMetaItem[], this.authuser?.RoleId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            HasAccess: new DynamicLabel({
                label: 'Has Access',
                value: this.authuser?.HasAccess || true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsEditable: new DynamicLabel({
                label: 'Is Editable',
                value: this.authuser?.IsEditable || true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
