<div *ngIf="invoice">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <app-customer-invoice-basic-info [invoice]="invoice"></app-customer-invoice-basic-info>
                <br />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ng-container *ngIf="invoice">
                    <app-customer-admin-document [invoice]="invoice"></app-customer-admin-document>
                </ng-container> 
            </div>
        </div>
        <br>
        <a routerLink="/customer-invoices" class="btn btn-default">Close</a>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <ng-container *ngIf="canAddDocuments || (invoice && invoice.InvoiceDocuments && invoice.InvoiceDocuments.length)">
                    <app-customer-invoice-documents-preview [invoice]="invoice"></app-customer-invoice-documents-preview>
                </ng-container>
                <ng-container *ngIf="!canAddDocuments && invoice && invoice && (!invoice.InvoiceDocuments || !invoice.InvoiceDocuments.length)">
                    <div class="miles-card padded">
                        <br>
                        <i>No documents have been uploaded to this invoice.</i>
                        <button (click)="requestDocuments()" style="margin: 5px;" class="btn btn-primary btn-xs">Request Documents</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
