import { AuthUserDynamicControls } from '../form-controls/auth-user.form-controls';
import { IAuthUser } from '../interfaces/auth-user';
import { IUserRole } from '../interfaces/user-role';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType } from '@mt-ng2/dynamic-form';
import { LoginConfig } from '@mt-ng2/login-module';
import { LoginConfigOverride } from '../../common/configs/login.config';
export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {
    constructor(private extAuthUser: IAuthUser, userRoles?: IUserRole[]) {
        super(extAuthUser, { roles: userRoles });

        const loginConfig = new LoginConfig(LoginConfigOverride);
        let passwordRegexPattern = loginConfig.passwordPattern;
        let failedPatternMessage = loginConfig.messageOverrides.failedPattern;
        // Add the send email checkbox when creating a new user
        this.Form.SendResetEmail = new DynamicField({
            formGroup: 'AuthUser',
            label: 'Send Reset Password Email',
            name: 'SendResetEmail',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: '',
        });

        this.Form.GrantAccessEmail = new DynamicField({
            formGroup: 'AuthUser',
            label: 'Grant Portal Access Via Email',
            name: 'GrantAccessEmail',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),

            value: extAuthUser && extAuthUser.HasAccess,
        });

        // Add the controls to validate passwords.  These are not in the model.
        this.Form.ConfirmPassword = new DynamicField({
            failedPatternMessage: failedPatternMessage,
            formGroup: 'AuthUser',
            label: 'Confirm Password',
            labelHtml: `<label>Confirm Password</label>`,
            name: 'ConfirmPassword',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Password,
            } as IDynamicFieldType),
            validation: [Validators.required, Validators.pattern(passwordRegexPattern)],
            validators: {
                pattern: passwordRegexPattern,
                required: true,
            },
            value: '',
        });

        this.Form.CurrentPassword = new DynamicField({
            formGroup: 'AuthUser',
            label: 'Current Password',
            name: 'CurrentPassword',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Password,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: '',
        });

        (<DynamicField>this.Form.Password).validation.push(Validators.pattern(passwordRegexPattern));
        (<DynamicField>this.Form.Password).validators.pattern = passwordRegexPattern;
        (<DynamicField>this.Form.Password).failedPatternMessage = failedPatternMessage;
    }
}
