<div *ngIf="!isEditing" class="miles-editable miles-card padded">
    <h4>
        Contacts
        <span class="pull-right show-on-hover" *ngIf="canEdit">
            <button *ngIf="canEdit" [hidden]="!isHovered" class="btn btn-primary btn-flat" (click)="openContactsList()" style="margin-top: -12px;">
                Manage
            </button>
        </span>
    </h4>

    <ul class="list-group">
        <li *ngFor="let contact of contacts" class="list-group-item" (click)="userSelected(contact)">
            {{ contact.FirstName + ' ' + contact.LastName }}
        </li>
    </ul>
    <div *ngIf="contacts && !contacts.length"><i>No Contacts</i></div>

    <div class="fab-wrap">
        <button class="btn btn-primary btn-fab-md btn-fab-center" type="button" (click)="userSelected(null)">
            <span class="fa fa-plus"></span>
        </button>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Edit Contact</h4>
    <company-contact-info-form
        [companyId]="company.Id"
        [user]="editContact"
        [canEdit]="canEdit"
        (successEmitter)="success($event)"
        (cancelEmitter)="cancel()"
    ></company-contact-info-form>
</div>
