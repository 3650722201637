import { IEntityListConfig, EntityListConfig, IEntityListColumn, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { IUser } from '../../../model/interfaces/user';

export class ContactEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: (user: IUser) => `${user.FirstName} ${user.LastName}`,
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['UserPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    linkFunction: function (user: IUser): void {
                        window.open(`mailto:${user.Email}`);
                    },
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessorFunction: (c) => (c.AuthUser.HasAccess ? 'Has Access' : ''),
                    name: 'Access',
                    sort: {
                        sortProperty: 'AuthUser.HasAccess',
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
