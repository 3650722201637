import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { CompanyAddComponent } from './company-add/company-add.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CompaniesComponent } from './company-list/companies.component';
import { CompanyService } from './services/company.service';
import { CompanyHeaderComponent } from './company-header/company-header.component';

import { ClaimTypes } from '../model/ClaimTypes';
import { CompanyContactsComponent } from './company-contacts/contact-list/company-contact.component';
import { CompanyContactDetailComponent } from './company-contacts/contact-detail/company-contact-detail.component';
import { ContactDetailComponent } from '../customers/customer-contacts/contact-detail/contact-detail.component';

const companyEntityConfig = {
    claimType: ClaimTypes.Companies,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'companyId',
    service: CompanyService,
    title: 'Company Detail',
};

const companyListRoleGuard: any = {
    claimType: ClaimTypes.Companies,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Companies',
};

const companyAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Companies,
    claimValues: [ClaimValues.FullAccess],
};

export const companyPaths = {
    companies: 'companies',
    companiesAdd: 'companies/add',
};

const companyRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CompaniesComponent,
        data: companyListRoleGuard,
        path: companyPaths.companies,
    },
    {
        canActivate: [AuthGuard],
        children: [ { component: CompanyAddComponent, path: '', pathMatch: 'full' } ],
        component: CompanyHeaderComponent,
        data: companyAddRoleGuard,
        path: companyPaths.companiesAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {   component: CompanyDetailComponent,
                path: '',
                pathMatch: 'full',
            },
            {
                component: CompanyContactsComponent,
                path: 'contacts',
                pathMatch: 'full',
            },
            {
                component: CompanyContactDetailComponent,
                path: 'contacts/add',
                pathMatch: 'full',
            },
            {
                component: CompanyContactDetailComponent,
                path: 'contacts/:userId',
                pathMatch: 'full',
            },

        ],
        component: CompanyHeaderComponent,
        data: companyEntityConfig,
        path: `companies/:${companyEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(companyRoutes)],
})
export class CompanyRoutingModule {}
