import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { ICustomer } from '../model/interfaces/customer';
import { IInvoice } from '../model/interfaces/invoice';
import { IInvoiceStatus } from '../model/interfaces/invoice-status';
import { IInvoiceDynamicControlsParameters, InvoiceDynamicControls } from '../model/form-controls/invoice.form-controls';
import { ICustomerLocation } from '../model/interfaces/customer-location';

export class InvoiceDynamicConfig<T extends IInvoice> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private invoice: T, private statuses: IInvoiceStatus[], private configControls?: string[]) {
        super();
        const additionalParams: IInvoiceDynamicControlsParameters = {
            statuses: this.statuses,
        };
        const dynamicControls = new InvoiceDynamicControls(this.invoice, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['InvoiceNo', 'InvoiceDate', 'Total', 'Balance'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
