import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';
import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoicesComponent } from './invoice-list/invoices.component';
import { InvoiceHeaderComponent } from './invoice-header/invoice-header.component';

import { InvoiceService } from './services/invoice.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
    declarations: [InvoicesComponent, InvoiceHeaderComponent],
    imports: [SharedModule, InvoiceRoutingModule, PdfViewerModule, NgxLoadingModule],
})
export class InvoiceModule {
    static forRoot(): any {
        return {
            ngModule: InvoiceModule,
            providers: [InvoiceService],
        };
    }
}
