import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { CustomersComponent } from './customers/customer-list/customers.component';
import { AppSettingsComponent } from './app-settings.component';
import { ClaimTypes } from './model/ClaimTypes';
import { UserService } from './users/user.service';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { AdminAccessComponent, ForgotPasswordOldStyleComponent, LoginOldStyleComponent, ResetPasswordOldStyleComponent } from '@mt-ng2/login-module';
import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { PathNotFoundResolveService } from './default-routes/path-not-found-resolve.service';
import { appPaths } from './default-routes/app-paths.library';
import { HomeGuard } from './home-guard';
import { ContactHelpComponent } from './common/components/contact-help.component';

const homeRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    isHomePage: true,
};

const userEntityConfig: IEntityRouteConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Users,
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: 'notes',
    service: UserService,
};

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginOldStyleComponent },
    { path: appPaths.forgotPassword, component: ForgotPasswordOldStyleComponent },
    { path: appPaths.resetPassword, component: ResetPasswordOldStyleComponent },
    { path: appPaths.help, component: ContactHelpComponent },
    
    { path: appPaths.adminAccess, component: AdminAccessComponent , title: 'Admin Access' },
    {
        canActivate: [HomeGuard, AuthGuard],
        component: CustomersComponent,
        data: homeRoleGuard,
        path: appPaths.home,
    },
    {
        canActivate: [AuthGuard],
        component: AppSettingsComponent,
        path: appPaths.settings,
    },
    {
        canActivate: [AuthGuard],
        component: UserDetailComponent,
        data: userEntityConfig,
        path: appPaths.myProfile,
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: CustomersComponent,
        data: homeRoleGuard,
        path: '',
    },
    {
        canActivate: [AuthGuard],
        component: NotFoundComponent,
        data: homeRoleGuard,
        path: '**',
        resolve: {
            path: PathNotFoundResolveService,
        },
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
