import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICustomerGroup } from '../../model/interfaces/customer-group';

@Injectable()
export class CustomerContactGroupsService {
    constructor(public http: HttpClient) {}

    GetAll(customerIds: number[]): Observable<ICustomerGroup[]> {
        return this.http.put<ICustomerGroup[]>('/customer-contact-groups', customerIds);
    }

    getSearchFilterItems(customerIds: number[]): Observable<MtSearchFilterItem[]> {
        return this.GetAll(customerIds).pipe(
            map((items) => {
                return items.map((item) => new MtSearchFilterItem(<any>item, false));
            }),
        );
    }
}
