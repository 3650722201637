<h2>Upload Invoices</h2>
<div class="row">
    <div class="col-md-6">
        <div class="miles-form padded">
            <ngx-loading [show]="isLoading"></ngx-loading>
            <mt-document
                #docComponent
                [allowedMimeType]="allowedMimeTypes"
                [maxFileSize]="maxFileSize"
                (afterAddingFile)="addDocument($event)"
                (addingFileFailed)="documentFailed($event)"
            ></mt-document>
        </div>
    </div>
</div>
