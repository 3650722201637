import { EntityListConfig, SortDirection, EntityListColumn, EntityListDeleteColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IAddress } from '@mt-ng2/format-functions';
import { ICustomer } from '@model/partials/customer';

export class CustomersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: (c: ICustomer) => (c.Archived ? c.Name + ' [ARCHIVED]' : c.Name),
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (c: ICustomer) => (c.Company ? c.Company.Name : ''),
                    name: 'Company',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerAddresses'],
                    name: 'Address',
                    pipes: ['primary', 'address'],
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
