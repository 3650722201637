import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { ICustomerPaymentSource } from '../../model/interfaces/customer-payment-source';
import { CustomerPaymentSourceService } from '../services/customer-payment-source.service';

@Component({
    templateUrl: './customer-payment-source-header.component.html',
})
export class CustomerPaymentSourceHeaderComponent implements OnInit {
    header$: Observable<string>;
    readonly customerPaymentSourceService = inject(CustomerPaymentSourceService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('customerPaymentSourceId');
        this.customerPaymentSourceService.setInitialTitle(id);
        this.header$ = this.customerPaymentSourceService.title$;
    }
}
