import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { Router } from '@angular/router';
import { ICustomerDashboardReturn } from '../../model/partials/customer-dashboard-return';
import { CustomerDashboardService } from '../customer-dashboard.service';
import { CustomerContactGroupsService } from '../../common/services/customer-contact-groups.service';
import { CustomerContactLocationsService } from '../../common/services/customer-contact-locations.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { CustomerInvoiceService } from '../../customer-invoices/services/customer-invoice.service';
import { UserService } from '../../users/user.service';
import { CustomerService } from '../../customers/services/customer.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    styles: [
        `
            .panel-body h3 {
                margin-top: 5px;
            }
            .panel-body h2 {
                margin-top: 20px;
            }
            .panel-body button {
                margin-top: 25px;
            }
            button i {
                margin-left: 7px;
            }
            .panel {
                border-radius: 0px;
                min-height: 250px !important;
            }
            .summary-detail {
                max-width: 650px !important;
            }
            .panel-header-text {
                font-size: 25px;
                font-weight: bold;
                margin: 7px;
            }
            .detail-header {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .detail-text {
                font-size: 19px;
            }
            .large-detail-text {
                font-size: 33px;
                font-weight: bold;
            }
            .filters {
                font-size: 20px important!;
                margin-top: 15px;
                margin-bottom: 15px;
            }
        `,
    ],
    templateUrl: './customer-dashboard.component.html',
})
export class CustomerDashboardComponent implements OnInit {
    dashboardInfo: ICustomerDashboardReturn;
    customers: MtSearchFilterItem[] = [];
    locations: MtSearchFilterItem[] = [];
    groups: MtSearchFilterItem[] = [];
    associatedCompanyId = 0;

    constructor(
        private customerDashboardService: CustomerDashboardService,
        private router: Router,
        private customerService: CustomerService,
        private customerContactGroupsService: CustomerContactGroupsService,
        private customerContactLocationsService: CustomerContactLocationsService,
        private customerInvoicesService: CustomerInvoiceService,
        private userService: UserService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.getDashboard();
        this.userService.getMyUser().subscribe((result) => {
            if (result.CompanyId) {
                this.associatedCompanyId = result.CompanyId;
                this.customerService.getMyCustomersFilterItems().subscribe((result) => {
                    this.customers = result;
                });
            } else {
                this.getCustomerGroupsFilterItems();
                this.getCustomerLocationFilterItems();
            }
        });
    }

    public companyDropdownChange(): void {
        this.groups = [];
        this.locations = [];
        this.getCustomerGroupsFilterItems();
        this.getCustomerLocationFilterItems();
        this.getDashboard();
    }

    public getCustomerGroupsFilterItems(): void {
        const selectedCustomerIds: number[] = this.getSelectedFilters(this.customers);
        this.customerContactGroupsService.getSearchFilterItems(selectedCustomerIds).subscribe((answer) => {
            this.groups = answer;
        });
    }

    public getCustomerLocationFilterItems(): void {
        const selectedCustomerIds: number[] = this.getSelectedFilters(this.customers);
        const selectedGroupIds: number[] = this.getSelectedFilters(this.groups);

        this.customerContactLocationsService.getSearchFilterItems({ CustomerIds: selectedCustomerIds, GroupIds: selectedGroupIds }).subscribe((answer) => {
            this.locations = answer;
            this.getDashboard();
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    getDashboard(): void {
        const selectedCustomerIds: number[] = this.getSelectedFilters(this.customers);
        const selectedGroupIds: number[] = this.getSelectedFilters(this.groups);
        const selectedLocationIds: number[] = this.getSelectedFilters(this.locations);

        this.customerDashboardService.getDashboard({ CustomerIds: selectedCustomerIds, GroupIds: selectedGroupIds, LocationIds: selectedLocationIds }).subscribe((result) => {
            this.dashboardInfo = result;
        });
    }

    navigateToInvoices(): void {
        void this.router.navigate(['/customer-invoices']);
    }

    navigateToPayInvoices(): void {
        this.customerInvoicesService.setSelectedInvoiceIdsForPayment(this.dashboardInfo.InvoiceIds);
        void this.router.navigate(['/invoice-payment']);
    }

    navigateToRequestService(): void {
        const selectedCustomerIds: number[] = this.getSelectedFilters(this.customers);
        if(this.associatedCompanyId > 0){
            if(selectedCustomerIds.length > 0){
                void this.router.navigate(['/request-service', selectedCustomerIds[0]]);
            }
            else{
                this.notificationsService.error('Selecting a customer is required if requesting a service');
            }
        }
        else{
            void this.router.navigate(['/request-service', 0]);
        }

    }
}
