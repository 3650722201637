/* eslint-disable no-prototype-builtins */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { SortDirection, IEntity } from '@mt-ng2/entity-list-module';

import { InvoiceService } from '../services/invoice.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { FileItem } from 'ng2-file-upload';
import { IInvoice } from '../../model/interfaces/invoice';
import { IDocument } from '../../model/interfaces/base';
import { FileService } from '../../common/services/file.service';
import { saveAs } from 'file-saver';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { UpdateInvoiceReturnStatus } from '../../model/UpdateInvoiceReturnStatus';

@Component({
    selector: 'app-invoices',
    styles: [
        `
            tr.no-border > th {
                border: none !important;
            }
            tr.no-border > td {
                border: none !important;
            }
            th.no-border {
                border: none !important;
            }
            .no-border {
                border-top: none !important;
            }
            .sub-item {
                background-color: #fff !important;
                padding: 5px !important;
                padding-left: 50px !important;
            }
            .sub-item-header {
                background-color: #ececec !important;
                padding: 10px !important;
                font-size: 13px;
                font-weight: 400;
            }
        `,
    ],
    templateUrl: './invoices.component.html',
})
export class InvoicesComponent implements OnInit {
    documentPdfDialog: IModalWrapperApi;
    documentsDialog: IModalWrapperApi;
    documentArray = [];
    selectedInvoice: IInvoice;

    invoices: IInvoice[];
    currentPage = 1;
    itemsPerPage = 12;
    query = '';
    total: number;
    order = 'Id';
    orderDirection: string = SortDirection.Asc;
    isLoading = false;

    allowedMimeTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/zip', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    currentDocumentPaths: string[] = [];
    currentDocumentIndex = 0;
    displayedInvoiceNum = '';
    isInvoiceOpen = false; // If manifest is open this will remain false
    currentOpenInvoice: any;

    pdfSrc: any;

    canAddInvoice = false;
    canAddDocuments = false;
    isEditing = false;
    currentTitle: string;

    showMatched = false;

    constructor(
        private invoiceService: InvoiceService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.canAddInvoice = this.claimsService.hasClaim(ClaimTypes.Invoices, [ClaimValues.FullAccess]);
        this.canAddDocuments = this.claimsService.hasClaim(ClaimTypes.UploadInvoiceDocs, [ClaimValues.FullAccess]);
        this.getInvoices();
    }

    getInvoices(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShowMatched',
                value: this.showMatched ? '1' : '0',
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.invoiceService.getList(searchparams).subscribe((answer) => {
            this.invoices = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    selectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.showMatched = event.value;
        this.currentPage = 1;
        this.getInvoices();
    }

    convertToInvoice(event: Event, manifestId: number, manifestsToBeConverted: number): void {
        event.stopPropagation();
        this.showPinDialog(manifestId, manifestsToBeConverted);
    }

    showPinDialog(manifestId: number, manifestsToBeConverted: number): void {
        this.modalService
            .showModal({
                confirmButtonText: 'Save',
                input: 'text',
                showCancelButton: true,
                text: 'Please enter a new Invoice Number',
                title: 'Convert Manifest to Invoice',
            })
            .subscribe((result) => {
                if (result.value && result.value.length > 1) {
                    this.showConfirmDialog(result.value as string, manifestId, manifestsToBeConverted);
                } else if (!result.hasOwnProperty('dismiss') && result.value.length === 0) {
                    this.notificationsService.error('Must enter an invoice number for a new Invoice');
                }
            });
    }

    showConfirmDialog(invoiceNumber: string, manifestId: number, manifestsToBeConverted: number): void {
        this.modalService
            .showModal({
                confirmButtonText: 'Save',
                showCancelButton: true,
                text: `Are you sure this document is an invoice? If yes, there will be ${manifestsToBeConverted} associated manifests.`,
            })
            .subscribe((result) => {
                this.isLoading = true;
                this.invoiceService
                    .convertManifestToInvoice(manifestId, invoiceNumber)
                    .pipe(finalize(() => (this.isLoading = false)))
                    .subscribe((result) => {
                        this.notificationsService.success('Successfully converted manifest to invoice');
                        this.getInvoices();
                    });
            });
    }

    viewPdf(invoice: IInvoice, index: number): void {
        this.currentOpenInvoice = invoice;
        this.displayedInvoiceNum = this.currentOpenInvoice.InvoiceNo;
        let currentOpenDocument: IEntity;
        if (index === 0) {
            this.isInvoiceOpen = true;
            currentOpenDocument = invoice.InvoiceDocuments[0];
        } else {
            this.displayedInvoiceNum += ` — Manifest ${index}`;
            index--;
            this.isInvoiceOpen = false;
            currentOpenDocument = invoice.InvoiceManifests[index].InvoiceManifestDocuments[0];
        }

        this.invoiceService.getDocument((currentOpenDocument as any).DocumentId as number).subscribe((result) => {
            this.pdfSrc = result;
            this.documentPdfDialog.show();
            this.isEditing = false;
        });
    }

    saveInvoiceNum(e: any): void {
        if (e instanceof MouseEvent || e.key === 'Enter') {
            if (this.displayedInvoiceNum) {
                const newInvoice = { ...this.currentOpenInvoice };
                newInvoice.InvoiceNo = this.displayedInvoiceNum;
                this.invoiceService.updateInvoiceNo(newInvoice.Id as number, this.displayedInvoiceNum).subscribe((result) => {
                    this.isEditing = false;
                    if (result.ResultCode === UpdateInvoiceReturnStatus.Success) {
                        this.currentOpenInvoice.InvoiceNo = this.displayedInvoiceNum;
                        this.notificationsService.success('Successfully updated invoice number.');
                    } else if (result.ResultCode === UpdateInvoiceReturnStatus.AssociateToLocation) {
                        this.showOverwriteInvoiceModal(this.currentOpenInvoice.Id as number, this.displayedInvoiceNum, result.CustomerName, result.LocationName, true);
                    } else if (result.ResultCode === UpdateInvoiceReturnStatus.OverwriteDocuments) {
                        this.showOverwriteInvoiceModal(this.currentOpenInvoice.Id as number, this.displayedInvoiceNum, result.CustomerName, result.LocationName, false);
                    }
                });
            } else {
                this.notificationsService.error('Invoice number must not be empty.');
            }
        }
        e.stopPropagation();
    }

    cancelInvoiceNum(e: Event): void {
        this.displayedInvoiceNum = this.currentOpenInvoice.InvoiceNo;
        this.isEditing = false;
        e.stopPropagation();
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getInvoices();
    }

    edit(): void {
        this.isEditing = this.isInvoiceOpen ? true : false;
    }

    showDocumentsDialog(invoice: IInvoice): void {
        this.selectedInvoice = invoice;
        this.documentArray = [];
        this.invoiceService.getAdminDocuments(this.selectedInvoice.Id).subscribe((result) => {
            this.documentArray = result;
        });
        this.documentsDialog.show();
    }

    addInvoiceDocument(file: FileItem): void {
        this.invoiceService.uploadInvoiceDoc(this.selectedInvoice.Id, file._file).subscribe((result) => {
            this.documentArray.push(result);
        });
    }

    downloadDocument(selectedDocument: IDocument): void {
        this.invoiceService.getAdminDocument(selectedDocument.Id).subscribe((result) => {
            const thefile = new Blob([<ArrayBuffer>result], {
                type: 'application/octet-stream',
            });
            saveAs(result, selectedDocument.Name);
        });
    }

    deleteDocument(selectedDocument: IDocument): void {
        this.invoiceService.deleteAdminDocument(this.selectedInvoice.Id, selectedDocument.Id).subscribe((result) => {
            this.documentArray = this.documentArray.filter((d) => d.Id !== selectedDocument.Id);
            this.documentsDialog.show();
        });
    }

    validateFailed(message: string): void {
        this.notificationsService.error(message);
    }

    handleMatching(invoice: any): void {
        this.showOverwriteInvoiceModal(invoice.Id as number, invoice.InvoiceNo as string, invoice.MatchingCustomerName as string, invoice.MatchingLocationName as string, invoice.AssociateToLocation as boolean);
    }

    showOverwriteInvoiceModal(invoiceId: number, invoiceNo: string, customerName: string, locationName: string, associateToLocation: boolean): void {
        // either associates to locatoin or overwrites documents
        const text = associateToLocation ? `The Invoice Number ${invoiceNo} is associated with Customer ${customerName} - Location ${locationName}. Would you like to make this Invoice available in their portal?` : `An invoice document for Invoice Number ${invoiceNo} for Customer ${customerName} - Location ${locationName} already exists on this invoice. Would you like to overwrite the existing documents?`;
        const confirmButtonText = 'Yes';
        this.modalService.showModal({
            confirmButtonText: confirmButtonText,
            showCancelButton: true,
            showCloseButton: true,
            text: text,
        }).subscribe((result) => {
            if (result.value) {
                this.invoiceService.moveinvoiceDocumentsToLocation(invoiceId, invoiceNo).subscribe((result) => {
                    this.notificationsService.success('Invoice and documents are now available in customer portal');
                    this.getInvoices();
                }, (error) => {
                    this.notificationsService.error('Failure associating documents');
                });
            }
        });
    }
}
