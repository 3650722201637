<div class="row">
    <br>
    <div class="col-md-12">
        <div class="text-center">
            <h2>Request Documents</h2>
        </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <ngx-loading [show]="isLoading"></ngx-loading>
        <div class="miles-editable miles-card padded" *ngIf="abstractRequestDocumentControls && requestDocumentsForm">
            <h4>Request Documents</h4>
            <form [formGroup]="requestDocumentsForm" (ngSubmit)="formSubmitted();">
                <mt-dynamic-field [field]="abstractRequestDocumentControls.CustomerName" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.LocationName" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.ContactName" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.ContactEmail" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.InvoiceNumber" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.InvoiceDate" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.Total" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.Balance" [form]="requestDocumentsForm"></mt-dynamic-field>
                <mt-dynamic-field [field]="abstractRequestDocumentControls.Comments" [form]="requestDocumentsForm"></mt-dynamic-field>
                <br />
                <div>
                    <button type="submit" class="btn btn-primary" [disabled]="isLoading">Submit Request</button>
                    <button class="btn btn-default" (click)="cancel()" [disabled]="isLoading">Cancel</button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-3"></div>
</div>