import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { RecurringPaymentAddComponent } from './recurring-payment-add/recurring-payment-add.component';
import { RecurringPaymentDetailComponent } from './recurring-payment-detail/recurring-payment-detail.component';
import { RecurringPaymentsComponent } from './recurring-payment-list/recurring-payments.component';
import { RecurringPaymentService } from './services/recurring-payment.service';
import { RecurringPaymentHeaderComponent } from './recurring-payment-header/recurring-payment-header.component';

import { ClaimTypes } from '../model/ClaimTypes';

const recurringPaymentEntityConfig = {
    claimType: ClaimTypes.RecurringPayments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'recurringPaymentId',
    service: RecurringPaymentService,
    title: 'RecurringPayment Detail',
};

const recurringPaymentListRoleGuard: any = {
    claimType: ClaimTypes.RecurringPayments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'RecurringPayments',
};

const recurringPaymentAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.RecurringPayments,
    claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
};

export const recurringPaymentPaths = {
    recurringPayments: 'recurring-payments',
    recurringPaymentsAdd: 'recurring-payments/add',
};

const recurringPaymentRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: RecurringPaymentsComponent,
        data: recurringPaymentListRoleGuard,
        path: recurringPaymentPaths.recurringPayments,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: RecurringPaymentAddComponent, path: '', pathMatch: 'full' }],
        component: RecurringPaymentHeaderComponent,
        data: recurringPaymentAddRoleGuard,
        path: recurringPaymentPaths.recurringPaymentsAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: RecurringPaymentDetailComponent, path: '', pathMatch: 'full' }],
        component: RecurringPaymentHeaderComponent,
        data: recurringPaymentEntityConfig,
        path: `recurring-payments/:${recurringPaymentEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(recurringPaymentRoutes)],
})
export class RecurringPaymentRoutingModule {}
