import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { HelpService, ICustomerContactInfo, IDisputeInvoiceInfo } from '../../common/services/help.service';
import { ICustomerDisputeInvoice } from '../../model/partials/customer-dispute-invoice';
import { CustomerDisputeInvoiceDynamicControlsPartial } from '../../model/partials/customer-dispute-invoice.form-controls';
import { Location } from '@angular/common';

@Component({
    selector: 'app-dispute-invoice',
    templateUrl: './dispute-invoice.component.html',
})
export class DisputeInvoiceComponent implements OnInit {
    abstractCustomerDisputeInvoiceControls: any;
    disputeInvoiceForm: UntypedFormGroup;
    isLoading = false;
    invoiceId: number;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private helpService: HelpService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;

        this.invoiceId = this.getIdFromRoute(this.route, 'invoiceId');
        if (this.invoiceId) {
            this.helpService
                .getInvoiceDisputeInfo(this.invoiceId)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    this.createForm(result);
                });
        } else {
            void this.router.navigate(['customer-invoices']); // if no id found, go back to list
        }
    }

    createForm(info: IDisputeInvoiceInfo): void {
        this.getControls(info);
        this.disputeInvoiceForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            disputeInvoiceForm: this.fb.group({}),
        });
    }

    getControls(info: IDisputeInvoiceInfo): void {
        this.abstractCustomerDisputeInvoiceControls = new CustomerDisputeInvoiceDynamicControlsPartial(
            {
                CustomerEmail: info ? info.Email : null,
                CustomerName: info ? info.CustomerName : null,
                CustomerPhone: info ? info.CustomerPhone : null,
                LocationName: info ? info.LocationName : null,
                Reason: null,
            },
            {
                formGroup: 'disputeInvoiceForm',
            },
        ).Form;
    }

    formSubmitted(): void {
        if (this.disputeInvoiceForm.valid) {
            const formValues = this.disputeInvoiceForm.value.disputeInvoiceForm as ICustomerDisputeInvoice;
            this.isLoading = true;
            this.helpService
                .submitInvoiceDispute(
                    {
                        CustomerPhone: formValues.CustomerPhone,
                        Email: formValues.CustomerEmail,
                        Reason: formValues.Reason,
                    },
                    this.invoiceId,
                )
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    this.notificationsService.success('Thank you.  We will get back to you as quickly as possible for your invoice dispute.');
                    this.location.back();
                });
        } else {
            common.markAllFormFieldsAsTouched(this.disputeInvoiceForm);
        }
    }

    cancel(): void {
        this.location.back();
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }
}
