export const appPaths = {
    adminAccess: 'adminaccess',
    forgotPassword: 'forgotpassword',
    help: 'help',
    home: 'home',
    login: 'login',
    myProfile: 'my-profile',
    resetPassword: 'resetpassword',
    settings: 'settings',
};
