import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ICustomer } from '@model/interfaces/customer';
import { CustomerDynamicConfig } from '../customer.dynamic-config';
import { CustomerService } from '../services/customer.service';
import { UserService } from '../../users/user.service';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-customer-basic-info',
    templateUrl: './customer-basic-info.component.html',
})
export class CustomerBasicInfoComponent implements OnInit {
    @Input() customer: ICustomer;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];
    formFactory: CustomerDynamicConfig<ICustomer>;
    doubleClickIsDisabled = false;

    get isNewCustomer(): boolean {
        return this.customer && this.customer.Id ? false : true;
    }

    constructor(
        private customerService: CustomerService,
        private notificationsService: NotificationsService,
        private router: Router,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.userService.getMyUser().subscribe((result) => {
            if (result && result.AuthUser) {
                this.formFactory = new CustomerDynamicConfig<ICustomer>(this.customer, result.AuthUser);
                if (this.isNewCustomer) {
                    this.isEditing = true;
                    const config = this.formFactory.getForCreate();
                    this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
                    this.formObject = config.formObject?.map((x) => new DynamicField(x));
                } else {
                    const config = this.formFactory.getForUpdate();
                    this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
                    this.formObject = config.formObject?.map((x) => new DynamicField(x));
                }
            }
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewCustomer) {
            void this.router.navigate(['/customers']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.customer, form.value.Customer as ICustomer);
            this.saveCustomer();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveCustomer(): void {
        if (this.isNewCustomer) {
            this.customerService.create(this.customer).subscribe((answer) => {
                this.customer.Id = answer;
                this.success(true);
            });
        } else {
            this.customerService.updateVersion(this.customer).subscribe((success) => {
                this.customer.Version = success;
                this.success();
            });
        }
    }

    private success(newCustomerSave?: boolean): void {
        if (newCustomerSave) {
            void this.router.navigate([`/customers/${this.customer.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.customerService.emitChange(this.customer);
        this.notificationsService.success('Customer saved successfully.');
    }
}
