import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IInvoice } from '@model/interfaces/invoice';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDocument } from '@mt-ng2/entity-components-documents';

export const emptyInvoice: IInvoice = {
    DateCreated: null,
    Id: 0,
    InvoiceNo: null,
    UploadedId: 0,
};

export interface IUpdateInvoiceResultDTO {
    ResultCode: number;
    CustomerName: string;
    LocationName: string;
}

@Injectable({
    providedIn: 'root',
})
export class InvoiceService extends BaseService<IInvoice> {
    constructor(public http: HttpClient) {
        super('/invoices', http);
    }

    getEmptyInvoice(): IInvoice {
        return { ...emptyInvoice };
    }

    getList(searchparameters: SearchParams): Observable<HttpResponse<IInvoice[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IInvoice[]>(`/invoices/_search/list`, {
                observe: 'response',
                params: params,
            })
            // eslint-disable-next-line @typescript-eslint/unbound-method
            .pipe(catchError(this.handleError));
    }

    getDocument(docId: number): any {
        return this.http.get(`/invoices/documents/${docId}`, {
            // responseType: 'blob' as 'blob',
            responseType: 'arraybuffer' as 'json',
        });
    }

    updateInvoiceNo(invoiceId: number, invoiceNo: string): Observable<IUpdateInvoiceResultDTO> {
        const params = {InvoiceNo: invoiceNo, InvoiceId: invoiceId};
        return this.http.put<IUpdateInvoiceResultDTO>('/invoices/update-number' , params);
    }

    convertManifestToInvoice(manifestId: number, invoiceNumber: string): any {
        const params = {InvoiceNumber: invoiceNumber, ManifestId: manifestId};
        return this.http.post('/invoices/convert' , params);
    }

    uploadAssociatedInvoiceDoc(invoiceId: number, invoiceDoc: File): Observable<number> {
        const formData: FormData = new FormData();
        formData.append('file', invoiceDoc, invoiceDoc.name);

        return this.http.post<number>(`/invoices/${invoiceId}/invoice-documents`, formData);
    }

    uploadInvoiceDoc(invoiceId: number, invoiceDoc: File): any {
        const formData: FormData = new FormData();
        formData.append('file', invoiceDoc, invoiceDoc.name);

        return this.http.post(`/invoices/${invoiceId}/admin-documents`, formData);
    }

    getAdminDocuments(invoiceId: number): Observable<IDocument[]> {
        return this.http.get<IDocument[]>(`/invoices/${invoiceId}/admin-documents`);
    }

    getAdminDocument(docId: number): any {
        return this.http.get(`/invoices/admin-documents/${docId}`, {
            responseType: 'blob' as const,
        });
    }

    deleteAdminDocument(invoiceId: number, documentId: number): any {
        return this.http.delete(`/invoices/${invoiceId}/admin-documents/${documentId}`);
    }

    moveinvoiceDocumentsToLocation(invoiceId: number, matchingInvoiceNumber: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('matchingInvoiceNumber', matchingInvoiceNumber);
        return this.http.delete(`/invoices/${invoiceId}/move-documents-to-location`, { params: httpParams });
    }
}
