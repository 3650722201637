import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { UserDynamicControls } from '@model/form-controls/user.form-controls';
import { IUser } from '@model/interfaces/user';
import {
    CustomerContactDynamicControlsPartial,
    ICustomerContactDynamicControlsParametersPartial,
} from '../../model/partials/customer-contact.form-controls';

export class CustomerContactDynamicConfig<T extends IUser> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private user: T, private configControls?: string[], additionalParams?: ICustomerContactDynamicControlsParametersPartial) {
        super();
        const dynamicControls = new CustomerContactDynamicControlsPartial(this.user, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['FirstName', 'LastName', 'Title', 'Email', 'CustomerLocationId', 'CustomerGroupId', 'IsAdmin'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
