import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { HelpService, ICustomerContactInfo, IDisputeInvoiceInfo } from '../../common/services/help.service';
import { ICustomerDisputeInvoice } from '../../model/partials/customer-dispute-invoice';
import { CustomerDisputeInvoiceDynamicControlsPartial } from '../../model/partials/customer-dispute-invoice.form-controls';
import { Location } from '@angular/common';
import { CustomerRequestServiceDynamicControlsPartial } from '../../model/partials/customer-request-service.form-controls';
import { ICustomerRequestService } from '../../model/partials/customer-request-service';
import { CustomerContactLocationsService } from '../../common/services/customer-contact-locations.service';
import { forkJoin } from 'rxjs';
import { ServiceTypesService } from '../../common/services/service-types.service';
import { UsersComponent } from '../../users/user-list/users.component';
import { UserService } from '../../users/user.service';
import { IUser } from '../../model/interfaces/user';
import { IMetaItem } from '@mt-ng2/base-service';
import { Console } from 'console';
import { CustomerService } from '../../customers/services/customer.service';
import { ICustomer } from '../../model/interfaces/customer';

@Component({
    selector: 'app-service-request',
    templateUrl: './service-request.component.html',
})
export class ServiceRequestComponent implements OnInit {
    abstractCustomerRequestServiceControls: any;
    requestServiceForm: UntypedFormGroup;
    isLoading = false;
    invoiceId: number;
    customerId: number;
    locations: IMetaItem[] = [];
    serviceTypes: IMetaItem[] = [];
    customer: ICustomer;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private helpService: HelpService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private customerContactLocationsService: CustomerContactLocationsService,
        private customerService: CustomerService,
        private serviceTypesService: ServiceTypesService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.customerId = +this.route.snapshot.paramMap.get('customerId') || null;
        const customerIds = this.customerId > 0 ? [this.customerId] : [];
        forkJoin([this.customerContactLocationsService.GetAll({ CustomerIds: customerIds, GroupIds: [] }), this.serviceTypesService.getAll(), this.userService.getMyUser()])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((result) => {
                this.locations = result[0];
                this.serviceTypes = result[1];
                if(this.customerId != null && this.customerId > 0){
                    this.customerService.getById(this.customerId).subscribe((customer) => {
                        this.customer = customer;
                        this.createForm(result[2]);
                    });
                }
                else{
                    this.createForm(result[2]);
                }
               
            });
    }

    createForm(info: IUser): void {
        this.getControls(info);
        this.requestServiceForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            requestServiceForm: this.fb.group({}),
        });
    }

    getControls(info: IUser): void {
        this.abstractCustomerRequestServiceControls = new CustomerRequestServiceDynamicControlsPartial(
            {
                CustomerContactName: info ? info.FirstName + ' ' + info.LastName : null,
                CustomerName: info && info.Customer ? info.Customer.Name : this.customer.Name,
                LocationId: 0,
                Notes: null,
                ServiceTypeId: 0,
            },
            this.locations,
            this.serviceTypes,
            {
                formGroup: 'requestServiceForm',
            },
        ).Form;
    }

    formSubmitted(): void {
        if (this.requestServiceForm.valid) {
            const formValues = this.requestServiceForm.value.requestServiceForm as ICustomerRequestService;
            this.isLoading = true;
            this.helpService
                .submitServiceRequest(formValues.LocationId, formValues.ServiceTypeId, formValues.Notes)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    this.notificationsService.success('Thank you for your service request.');
                    this.location.back();
                });
        } else {
            common.markAllFormFieldsAsTouched(this.requestServiceForm);
        }
    }

    cancel(): void {
        this.location.back();
    }
}
