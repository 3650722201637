<div class="miles-editable miles-card padded">
    <h4>
        Invoice Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
    <div style="padding-top: 5px;" *ngIf="!adminView">
        <button *ngIf="invoice" (click)="payInvoice()" style="margin: 5px;" class="btn btn-success btn-xs">Pay Invoice</button>
        <button (click)="disputeInvoice()" style="margin: 5px;" class="btn btn-danger btn-xs pull-right">Dispute Invoice</button>
    </div>
</div>
