import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { InvoicesComponent } from './invoice-list/invoices.component';
import { InvoiceService } from './services/invoice.service';
import { InvoiceHeaderComponent } from './invoice-header/invoice-header.component';

import { ClaimTypes } from '@model/ClaimTypes';

const invoiceEntityConfig = {
    claimType: ClaimTypes.Invoices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'invoiceId',
    service: InvoiceService,
    title: 'Invoice Detail',
};

const invoiceListRoleGuard: any = {
    claimType: ClaimTypes.Invoices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Invoices',
};

const invoiceAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Invoices,
    claimValues: [ClaimValues.FullAccess],
};

export const invoicePaths = {
    invoices: 'invoices',
    invoicesAdd: 'invoices/add',
};

const invoiceRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: InvoicesComponent,
        data: invoiceListRoleGuard,
        path: invoicePaths.invoices,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(invoiceRoutes)],
})
export class InvoiceRoutingModule {}
