import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { CustomerInvoicesComponent } from './customer-invoice-list/customer-invoices.component';
import { CustomerInvoiceDetailComponent } from './customer-invoice-detail/customer-invoice-detail.component';
import { CustomerInvoiceHeaderComponent } from './customer-invoice-header/customer-invoice-header.component';
import { ClaimTypes } from '../model/ClaimTypes';
import { CustomerInvoiceService } from './services/customer-invoice.service';
import { DisputeInvoiceComponent } from './dispute-invoice/dispute-invoice.component';
import { RequestDocumentsComponent } from './request-documents/request-documents.component';

const customerInvoiceEntityConfig = {
    claimType: ClaimTypes.CustomerInvoices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'invoiceId',
    service: CustomerInvoiceService,
    title: 'Invoice Detail',
};

const customerInvoiceListRoleGuard: any = {
    claimType: ClaimTypes.CustomerInvoices,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Invoices',
};

export const invoicePaths = {
    invoices: 'customer-invoices',
};

const invoiceRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CustomerInvoicesComponent,
        data: customerInvoiceListRoleGuard,
        path: invoicePaths.invoices,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: CustomerInvoiceDetailComponent, path: '', pathMatch: 'full' }],
        component: CustomerInvoiceHeaderComponent,
        data: customerInvoiceEntityConfig,
        path: `customer-invoices/:${customerInvoiceEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        component: DisputeInvoiceComponent,
        path: `customer-invoices/dispute/:${customerInvoiceEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        component: RequestDocumentsComponent,
        path: `customer-invoices/request-documents/:${customerInvoiceEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(invoiceRoutes)],
})
export class CustomerInvoiceRoutingModule {}
