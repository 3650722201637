import { Component, Input, OnInit } from '@angular/core';
import { ɵINTERNAL_BROWSER_PLATFORM_PROVIDERS } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from '@mt-ng2/auth-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, of } from 'rxjs';
import { ICompany } from '../../model/interfaces/company';
import { ICustomer } from '../../model/interfaces/customer';
import { CompanyService } from '../services/company.service';

@Component({
    selector: 'company-add-customers',
    templateUrl: './company-add-customers.component.html',
})
export class CompanyAddCustomersComponent implements OnInit {
    @Input() company: ICompany;
    @Input() canEdit: boolean;

    associatedCustomers: ICustomer[] = [];
    itemList = [];
    selectedItems = [];
    settings = {};
    searchLoading = false;
    loading = false;
    readonly itemsPerPage: number = 10;
    query = '';
    stopLoadingMore = false;
    isEditing = false;
    saveLoading = false;

    constructor(
        private companyService: CompanyService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.getAssociatedCustomers();
        this.settings = {
          autoPosition: true,
          enableCheckAll: false,
          enableFilterSelectAll: false,
          enableSearchFilter: true,
          labelKey: 'Name',
          lazyLoading: true,
          noDataLabel: 'Search Customers...',
          position: 'bottom',
          primaryKey: 'Id',
          searchBy: ['Name'],
          tagToBody: false,
          text: 'Select Customers',
        };
      }

      edit(): void {
          if (this.canEdit) {
              this.isEditing = true;
          }
      }

      cancelClick(): void {
          this.itemList = [];
          this.selectedItems = [];
          this.isEditing = false;
      }

      getAssociatedCustomers(): void {
          this.companyService.getCustomers(this.company.Id).subscribe((result) => {
              this.associatedCustomers = result;
          });
      }

      onSearch(evt: any): void {
          this.itemList = [];
          this.query = evt.target.value;
          if (this.query && this.query.length >= 3) {
              this.searchLoading = true;
              this.getCustomers()
              .subscribe((res) => {
                  this.itemList = res;
                  this.stopLoadingMore = false;
                  this.searchLoading = false;
              }, (error) => {
                  this.loading = false;
                  this.searchLoading = false;
              });
          }
      }

      getCustomers(): Observable<ICustomer[]> {
          const search = this.query;
          const _extraSearchParams: ExtraSearchParams[] = [];

          const searchEntity: IEntitySearchParams = {
              extraParams: _extraSearchParams,
              query: search && search.length > 0 ? search : '',
              skip: this.itemList.length,
              take: this.itemsPerPage,
          };

          const searchparams = new SearchParams(searchEntity);
          return this.companyService.getCustomersForCompanyDropdown(searchparams);
      }

      fetchMore(event: any): void {
          if (!this.searchLoading && !this.stopLoadingMore && !this.loading && this.itemList.length && event.endIndex === this.itemList.length - 1) {
              this.loading = true;
              this.getCustomers().subscribe((chunk) => {
                  if (!chunk.length) {
                      this.stopLoadingMore = true;
                  }
                  this.itemList = this.itemList.concat(chunk);
                  this.loading = false;
              }, () => this.loading = false);
          }
      }

      removeCustomer(customer): void {
          this.companyService.removeCustomer(this.company.Id, customer.Id as number).subscribe((result) => {
              const index = this.associatedCustomers.findIndex((c) => c.Id === customer.Id);
              this.associatedCustomers.splice(index, 1);
          });
      }

      addCustomers(): void {
          if (!this.selectedItems || !this.selectedItems.length) {
              this.notificationsService.error('Must select at least one customer to save');
              return;
          }
          const customerIds = this.selectedItems.map((i) => i.Id);
          this.saveLoading = true;
          this.companyService.addCustomers(this.company.Id, customerIds as number[]).subscribe((result) => {
              this.getAssociatedCustomers();
              this.notificationsService.success('Customers successfully saved.');
              this.cancelClick();
              this.saveLoading = false;
          });
      }

      customerSelected(customer): void {
          window.open(`#/customers/${customer.Id as number}`, '_blank');
      }

}
