<div *ngIf="recurringPayment">
    <div class="row">
        <div class="col-md-6">
            <app-recurring-payment-basic-info [recurringPayment]="recurringPayment" [canEdit]="canEdit"></app-recurring-payment-basic-info>
            <br />
        </div>
    </div>
    <a routerLink="/recurring-payments" class="btn btn-default">Close</a>
    <button
        type="button"
        [disabled]="isLoading"
        class="btn btn-default btn-danger"
        style="color: white;"
        (click)="cancelPayment()"
        *ngIf="recurringPayment && recurringPayment.Id"
    >
        Cancel Payment
    </button>
</div>
