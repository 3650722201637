import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { IInvoice } from '../interfaces/invoice';
import { IDocParserUpload } from '../interfaces/doc-parser-upload';
import { IInvoiceStatus } from '../interfaces/invoice-status';
import { ICustomerLocation } from '../interfaces/customer-location';

export interface IInvoiceDynamicControlsParameters {
    formGroup?: string;
    statuses?: IInvoiceStatus[];
    locations?: ICustomerLocation[];
}

export class InvoiceDynamicControls {
    formGroup: string;
    statuses?: IInvoiceStatus[];
    locations?: ICustomerLocation[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private invoice?: IInvoice,
        additionalParameters?: IInvoiceDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'Invoice';
        this.statuses = additionalParameters?.statuses ?? undefined;
        this.locations = additionalParameters?.locations ?? undefined;
        this.Form = {
            InvoiceNo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice No',
                name: 'InvoiceNo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.invoice?.InvoiceNo?.toString() || '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.DateCreated || null,
            }),
            UploadedId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Uploaded',
                name: 'UploadedId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.UploadedId || null,
            }),
            InvoiceDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Date',
                name: 'InvoiceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.InvoiceDate || null,
            }),
            Total: new DynamicField({
                formGroup: this.formGroup,
                label: 'Total',
                name: 'Total',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.Total || null,
            }),
            Balance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Balance',
                name: 'Balance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.Balance || null,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.StatusId || null,
            }),
            LocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Location',
                name: 'LocationId',
                options: this.locations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.LocationId || null,
            }),
            ServiceDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Date',
                name: 'ServiceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.ServiceDate || null,
            }),
            DateUpdated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Updated',
                name: 'DateUpdated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.DateUpdated || null,
            }),
            DocParserMessageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Doc Parser Message',
                name: 'DocParserMessageId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.invoice?.DocParserMessageId?.toString() || '',
            }),
        };
        this.View = {
            InvoiceNo: new DynamicLabel({
                label: 'Invoice No',
                value: this.invoice?.InvoiceNo?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.invoice?.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            UploadedId: new DynamicLabel({
                label: 'Uploaded',
                value: this.invoice?.UploadedId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            InvoiceDate: new DynamicLabel({
                label: 'Invoice Date',
                value: this.invoice?.InvoiceDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            Total: new DynamicLabel({
                label: 'Total',
                value: this.invoice?.Total || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            Balance: new DynamicLabel({
                label: 'Balance',
                value: this.invoice?.Balance || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            StatusId: new DynamicLabel({
                label: 'Status',
                value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.invoice?.StatusId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LocationId: new DynamicLabel({
                label: 'Location',
                value: getMetaItemValue(this.locations as unknown as IMetaItem[], this.invoice?.LocationId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ServiceDate: new DynamicLabel({
                label: 'Service Date',
                value: this.invoice?.ServiceDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateUpdated: new DynamicLabel({
                label: 'Date Updated',
                value: this.invoice?.DateUpdated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DocParserMessageId: new DynamicLabel({
                label: 'Doc Parser Message',
                value: this.invoice?.DocParserMessageId?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    scale: null,
                }),
            }),
        };
    }
}
