import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IInvoice } from '../../model/interfaces/invoice';
import { CustomerInvoiceService } from '../services/customer-invoice.service';

@Component({
    templateUrl: './customer-invoice-header.component.html',
})
export class CustomerInvoiceHeaderComponent implements OnInit {
    header$: Observable<string>;
    readonly invoiceService = inject(CustomerInvoiceService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('invoiceId');
        this.invoiceService.setInitialTitle(id);
        this.header$ = this.invoiceService.title$;
    }
}
