
<mt-dynamic-form *ngIf="formReady" [config]="formObject" (submitted)="formSubmitted($event)" (formCreated)="formCreated($event)">
    <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">
        Save
    </button>
    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
        Cancel
    </button>
    <button type="button" Class="btn btn-flat btn-danger pull-right" *ngIf="user.Id > 0" (click)="onDelete($event)">
        Delete
    </button>
</mt-dynamic-form>
