<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Customer Payment Sources</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br />
</div>

<mt-entity-list
    [entities]="customerPaymentSources"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getCustomerPaymentSources()"
    (itemSelectedEvent)="customerPaymentSourceSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (itemDeletedEvent)="onItemDeleted($event)"
>
</mt-entity-list>

<div *ngIf="canAddCustomerPaymentSource" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/customer-payment-sources', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
