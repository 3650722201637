import { NgModule } from '@angular/core';

import { CompanyRoutingModule } from './company-routing.module';
import { CompanyAddComponent } from './company-add/company-add.component';
import { CompanyBasicInfoComponent } from './company-basic-info/company-basic-info.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CompaniesComponent } from './company-list/companies.component';
import { CompanyHeaderComponent } from './company-header/company-header.component';
import { SharedModule } from '../common/shared.module';
import { CompanyContactsComponent } from './company-contacts/contact-list/company-contact.component';
import { CompanyContactDetailComponent } from './company-contacts/contact-detail/company-contact-detail.component';
import { CompanyContactBasicInfoComponent } from './company-contacts/contact-basic-info/company-contact-basic-info.component';
import { CompanyContactInfoFormComponent } from './company-contacts/contact-info-form/company-contact-info-form.component';
import { CompanyContactsInfoComponent } from './company-contacts/company-contacts-info/customer-contacts-info.component';
import { UserModule } from '../users/user.module';
import { CompanyAddCustomersComponent } from './company-add-customers/company-add-customers.component';
import { AngularMultiSelectModule } from '@khajegan/angular2-multiselect-dropdown';

@NgModule({
    declarations: [
        CompaniesComponent,
        CompanyHeaderComponent,
        CompanyAddComponent,
        CompanyDetailComponent,
        CompanyBasicInfoComponent,
        CompanyContactsComponent,
        CompanyContactDetailComponent,
        CompanyContactBasicInfoComponent,
        CompanyContactInfoFormComponent,
        CompanyContactsInfoComponent,
        CompanyAddCustomersComponent,
    ],
    imports: [SharedModule, CompanyRoutingModule, UserModule, AngularMultiSelectModule],
})
export class CompanyModule {}
