<div *ngIf="customer">
    <div class="row">
        <div class="col-md-6">
            <app-customer-basic-info [customer]="customer" [canEdit]="canEdit"></app-customer-basic-info>
            <br />
            <mt-common-phone [PhoneArray]="customer.CustomerPhones" (saved)="savePhones($event)" [canEdit]="canEdit"></mt-common-phone>
            <br />
            <mt-common-addresses [canEdit]="canEdit" [service]="addressesService"></mt-common-addresses>
            <br />
        </div>
        <div class="col-md-6">
            <app-customer-contacts-info [customer]="customer" [canEdit]="canEdit"> </app-customer-contacts-info>
            <br /><br />
            <app-customer-locations-info [customer]="customer" [canEdit]="canEdit"> </app-customer-locations-info>
            <br /><br />
            <app-customer-groups-info [customer]="customer" [canEdit]="canEdit"> </app-customer-groups-info>
            <br /><br />
        </div>
    </div>
    <a routerLink="/customers" class="btn btn-default">Close</a>
</div>
