import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '@model/interfaces/user';
import { IUserRole } from '@model/interfaces/user-role';
import { IAuthUser } from '@model/interfaces/auth-user';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { UserService } from '../../../users/user.service';
import { AuthEntityService } from '../../../auth-entity/auth-entity.service';
import { AuthUserDynamicConfig } from '../../../auth-entity/auth-user/auth-user.dynamic-config';
import { UserRoles } from '../../../model/UserRoles';
import { forkJoin, Subscription } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { ICustomerLocation } from '../../../model/interfaces/customer-location';
import { ICustomerGroup } from '../../../model/interfaces/customer-group';
import { CustomerContactDynamicConfig } from '../customer-contact.dynamic-config';
import { ICustomerContactDynamicControlsParametersPartial } from '../../../model/partials/customer-contact.form-controls';
import { UntypedFormGroup } from '@angular/forms';
import { CustomerContactsService } from '../../services/customer-contacts.service';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
@Component({
    selector: 'app-contact-info-form',
    templateUrl: './contact-info-form.component.html',
})
export class ContactInfoFormComponent implements OnInit {
    @Input() customerId: number;
    @Input() user: IUser;
    @Input() canEdit: boolean;

    @Output() successEmitter = new EventEmitter();
    @Output() cancelEmitter = new EventEmitter();
    @Output() deleteEmitter = new EventEmitter();

    authUser: IAuthUser;
    roles: IUserRole[];
    locations: ICustomerLocation[];
    groups: ICustomerGroup[];
    additionalConfigs: any[] = [];
    isHovered: boolean;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    userForm: any;
    formFactory: CustomerContactDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    formReady = false;
    additionalParams: ICustomerContactDynamicControlsParametersPartial = {};
    subscription: Subscription = new Subscription();

    constructor(
        private customerContactsService: CustomerContactsService,
        private customerService: CustomerService,
        private notificationsService: NotificationsService,
        private userService: UserService,
    ) {}

    get isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    ngOnInit(): void {
        forkJoin([this.customerService.getLocations(this.customerId), this.customerService.getGroups(this.customerId)]).subscribe(
            ([locations, groups]) => {
                this.additionalParams.locations = locations as any;
                this.additionalParams.groups = groups as any;
                this.setConfig();
            },
        );
    }

    setConfig(): void {
        this.formFactory = new CustomerContactDynamicConfig<IUser>(this.user, undefined, this.additionalParams);
        this.additionalConfigs = this.getAdditionalConfigs();
        if (this.isNewUser) {
            const config = this.formFactory.getForCreate(this.additionalConfigs);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            const config = this.formFactory.getForUpdate(this.additionalConfigs);
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
        this.formReady = true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const authConfigControls: string[] = ['GrantAccessEmail'];
        const authUser = this.isNewUser ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, this.roles, authConfigControls);
        return [pwConfig];
    }

    cancelClick(): void {
        this.cancelEmitter.emit();
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.user, form.value.User as IUser);
            this.user.CustomerId = this.customerId;
            this.saveContact(form);
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    private saveContact(form): void {
        if (this.isNewUser) {
            const data: ICreateUserPayload = {
                GrantAccess: form.value.AuthUser.GrantAccessEmail || false,
                SendEmail: form.value.AuthUser.GrantAccessEmail || false,
                User: this.user,
                UserRoleId: form.value.User.IsAdmin ? UserRoles.AdminContacts : UserRoles.Contacts,
            };
            this.customerContactsService.createUser(data).subscribe((user: IUser) => {
                this.user = user;
                this.customerContactsService.emitChange(this.user);
                this.success();
            });
        } else {
            this.user.AuthUser.RoleId = form.value.User.IsAdmin ? UserRoles.AdminContacts : UserRoles.Contacts;
            this.user.AuthUser.HasAccess = form.value.AuthUser.GrantAccessEmail;
            this.customerContactsService.updateVersion(this.user).subscribe((newVersion) => {
                this.user.Version = newVersion;
                this.success();
                this.setConfig();
            });
        }
    }

    formCreated(event: UntypedFormGroup): void {
        this.subscription.add(
            event.get('User.IsAdmin').valueChanges.subscribe((answer) => {
                if (answer) {
                    event.get('User.CustomerLocationId').disable();
                    event.get('User.CustomerLocationId').setValue(null);

                    event.get('User.CustomerGroupId').disable();
                    event.get('User.CustomerGroupId').setValue(null);
                } else {
                    event.get('User.CustomerLocationId').enable();
                    event.get('User.CustomerGroupId').enable();
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
        this.successEmitter.emit(this.user);
    }

    error(message = 'Save failed. Please check the form and try again.'): void {
        this.notificationsService.error(message);
    }

    onDelete(): void {
        this.userService.ArchiveUser(this.user.Id).subscribe(
            () => {
                this.notificationsService.success('User successfully deleted!');
                this.user.IsArchived = true;
                this.deleteEmitter.emit(this.user);
            },
            () => {
                this.notificationsService.error('An error occurred while deleting the user.');
            },
        );
    }
}
