import { ICustomerContactHelp } from './customer-contact-help';
import { IExpandableObject } from '../expandable-object';
import { Validators } from '@angular/forms';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

export interface ICustomerContactHelpPartialDynamicControlsParameters {
    formGroup?: string;
}

export class CustomerContactHelpDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customerContactInfo?: ICustomerContactHelp, additionalParameters?: ICustomerContactHelpPartialDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'orderContactInformationForm';

        this.Form = {
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.customerContactInfo &&
                    this.customerContactInfo.hasOwnProperty('CustomerName') &&
                    this.customerContactInfo.CustomerName !== null
                        ? this.customerContactInfo.CustomerName
                        : '',
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.customerContactInfo && this.customerContactInfo.hasOwnProperty('Email') && this.customerContactInfo.Email !== null
                        ? this.customerContactInfo.Email
                        : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.customerContactInfo && this.customerContactInfo.hasOwnProperty('FirstName') && this.customerContactInfo.FirstName !== null
                        ? this.customerContactInfo.FirstName
                        : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textbox }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.customerContactInfo && this.customerContactInfo.hasOwnProperty('LastName') && this.customerContactInfo.LastName !== null
                        ? this.customerContactInfo.LastName
                        : '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Phone }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.customerContactInfo && this.customerContactInfo.hasOwnProperty('Phone') && this.customerContactInfo.Phone !== null
                        ? this.customerContactInfo.Phone
                        : '',
            }),
            Question: new DynamicField({
                formGroup: this.formGroup,
                label: 'Question',
                name: 'Question',
                type: new DynamicFieldType({ fieldType: DynamicFieldTypes.Input, inputType: InputTypes.Textarea }),
                validation: [Validators.required],
                validators: { required: true },
                value:
                    this.customerContactInfo && this.customerContactInfo.hasOwnProperty('Question') && this.customerContactInfo.Question !== null
                        ? this.customerContactInfo.Question
                        : '',
            }),
        };
    }
}
