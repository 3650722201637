import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IMetaItem } from '../interfaces/base';
import { IExpandableObject } from '../expandable-object';
import { IUser } from '../interfaces/user';
import { IAuthUser } from '../interfaces/auth-user';
import { IImage } from '../interfaces/image';
import { IAddress } from '../interfaces/address';
import { ICustomer } from '../interfaces/customer';
import { ICustomerLocation } from '../interfaces/customer-location';
import { ICustomerGroup } from '../interfaces/customer-group';
import { ICompany } from '../interfaces/company';

export interface IUserDynamicControlsParameters {
    formGroup?: string;
    authUsers?: IAuthUser[];
    images?: IImage[];
    addresses?: IAddress[];
    customers?: ICustomer[];
    customerLocations?: ICustomerLocation[];
    customerGroups?: ICustomerGroup[];
    companies?: ICompany[];
}

export class UserDynamicControls {
    formGroup: string;
    authUsers?: IAuthUser[];
    images?: IImage[];
    addresses?: IAddress[];
    customers?: ICustomer[];
    customerLocations?: ICustomerLocation[];
    customerGroups?: ICustomerGroup[];
    companies?: ICompany[];
    Form: IExpandableObject;
    View: IExpandableObject;
    constructor(
        private user?: IUser,
        additionalParameters?: IUserDynamicControlsParameters,
    ) {
        this.formGroup = additionalParameters?.formGroup ?? 'User';
        this.authUsers = additionalParameters?.authUsers ?? undefined;
        this.images = additionalParameters?.images ?? undefined;
        this.addresses = additionalParameters?.addresses ?? undefined;
        this.customers = additionalParameters?.customers ?? undefined;
        this.customerLocations = additionalParameters?.customerLocations ?? undefined;
        this.customerGroups = additionalParameters?.customerGroups ?? undefined;
        this.companies = additionalParameters?.companies ?? undefined;
        this.Form = {
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.user?.FirstName?.toString() || '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50 },
                validation: [Validators.required, Validators.maxLength(50)],
                value: this.user?.LastName?.toString() || '',
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true, maxlength: 50, email: true },
                validation: [Validators.required, Validators.maxLength(50), Validators.email],
                value: this.user?.Email?.toString() || '',
            }),
            AuthUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auth User',
                name: 'AuthUserId',
                options: this.authUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: { required: true },
                validation: [noZeroRequiredValidator],
                value: this.user?.AuthUserId || null,
            }),
            ImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Image',
                name: 'ImageId',
                options: this.images,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.user?.ImageId || null,
            }),
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.user?.AddressId || null,
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validators: { maxlength: 50 },
                validation: [Validators.maxLength(50)],
                value: this.user?.Title?.toString() || '',
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.user?.CustomerId || null,
            }),
            CustomerLocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Location',
                name: 'CustomerLocationId',
                options: this.customerLocations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.user?.CustomerLocationId || null,
            }),
            CustomerGroupId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Group',
                name: 'CustomerGroupId',
                options: this.customerGroups,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.user?.CustomerGroupId || null,
            }),
            CompanyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company',
                name: 'CompanyId',
                options: this.companies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.user?.CompanyId || null,
            }),
            IsArchived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Archived',
                name: 'IsArchived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validators: {},
                validation: [],
                value: this.user?.IsArchived || false,
            }),
        };
        this.View = {
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.user?.FirstName?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.user?.LastName?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Email: new DynamicLabel({
                label: 'Email',
                value: this.user?.Email?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            AuthUserId: new DynamicLabel({
                label: 'Auth User',
                value: getMetaItemValue(this.authUsers as unknown as IMetaItem[], this.user?.AuthUserId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImageId: new DynamicLabel({
                label: 'Image',
                value: getMetaItemValue(this.images as unknown as IMetaItem[], this.user?.ImageId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            AddressId: new DynamicLabel({
                label: 'Address',
                value: getMetaItemValue(this.addresses as unknown as IMetaItem[], this.user?.AddressId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Title: new DynamicLabel({
                label: 'Title',
                value: this.user?.Title?.toString() || '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.user?.CustomerId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerLocationId: new DynamicLabel({
                label: 'Customer Location',
                value: getMetaItemValue(this.customerLocations as unknown as IMetaItem[], this.user?.CustomerLocationId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerGroupId: new DynamicLabel({
                label: 'Customer Group',
                value: getMetaItemValue(this.customerGroups as unknown as IMetaItem[], this.user?.CustomerGroupId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CompanyId: new DynamicLabel({
                label: 'Company',
                value: getMetaItemValue(this.companies as unknown as IMetaItem[], this.user?.CompanyId || null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsArchived: new DynamicLabel({
                label: 'Is Archived',
                value: this.user?.IsArchived || false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };
    }
}
