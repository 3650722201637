import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ICustomer } from '@model/partials/customer';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomerLocationsService } from '../../services/customer-locations.service';
import { ICustomerLocation } from '../../../model/interfaces/customer-location';

@Component({
    templateUrl: './location-detail.component.html',
})
export class LocationDetailComponent implements OnInit {
    location: ICustomerLocation;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private locationService: CustomerLocationsService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private routeLocation: Location,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get the id from the route
        const id = this.getIdFromRoute(this.route, 'locationId');
        if (id > 0) {
            this.getLocationById(id);
        } else {
            this.location = this.locationService.getEmptyCustomerLocation();
        }
    }

    getLocationById(id: number): void {
        this.locationService.getById(id).subscribe((location) => {
            // check for reponse of null, which indicates entity not found with that id
            if (location === null) {
                this.notificationsService.error('Location not found');
                this.routeLocation.back();
            }
            this.location = location;
        });
    }

    cancel(): void {
        void this.router.navigate(['customers', this.location.CustomerId, 'locations']);
    }

    // TODO CCC: move this to common functions
    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10))  ? null : parseInt(id, 10);
    }
}
