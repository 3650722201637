import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';
import { CustomerRoutingModule } from './customer-routing.module';

import { CustomerBasicInfoComponent } from './customer-basic-info/customer-basic-info.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';

import { CustomerService } from './services/customer.service';
import { CustomerAddressesService } from './services/customer-addresses.service';
import { CustomerPhonesService } from './services/customer-phones.service';
import { CustomerAddComponent } from './customer-add/customer-add.component';
import { CustomerContactsInfoComponent } from './customer-contacts/customer-contacts-info/customer-contacts-info.component';
import { UserModule } from '../users/user.module';
import { CustomerLocationsInfoComponent } from './customer-locations/customer-locations-info/customer-locations-info.component';
import { LocationsComponent } from './customer-locations/locations-list/locations.component';
import { LocationDetailComponent } from './customer-locations/location-detail/location-detail.component';
import { LocationBasicInfoComponent } from './customer-locations/location-basic-info/location-basic-info.component';
import { AddressService } from '../common/services/address.service';
import { CustomerGroupsInfoComponent } from './customer-groups/customer-groups-info/customer-groups-info.component';
import { CustomerGroupPhonesService } from './services/customer-group-phones.service';
import { ContactsComponent } from './customer-contacts/contact-list/contact.component';
import { ContactDetailComponent } from './customer-contacts/contact-detail/contact-detail.component';
import { ContactBasicInfoComponent } from './customer-contacts/contact-basic-info/contact-basic-info.component';
import { ContactInfoFormComponent } from './customer-contacts/contact-info-form/contact-info-form.component';
import { CustomerContactsService } from './services/customer-contacts.service';

@NgModule({
    declarations: [
        CustomersComponent,
        CustomerHeaderComponent,
        CustomerAddComponent,
        CustomerDetailComponent,
        CustomerBasicInfoComponent,
        CustomerContactsInfoComponent,
        CustomerLocationsInfoComponent,
        CustomerGroupsInfoComponent,
        LocationsComponent,
        LocationDetailComponent,
        LocationBasicInfoComponent,
        ContactsComponent,
        ContactDetailComponent,
        ContactBasicInfoComponent,
        ContactInfoFormComponent,
    ],
    imports: [SharedModule, CustomerRoutingModule, UserModule],
})
export class CustomerModule {
    static forRoot(): any {
        return {
            ngModule: CustomerModule,
            providers: [
                CustomerService,
                CustomerAddressesService,
                CustomerPhonesService,
                CustomerGroupPhonesService,
                AddressService,
                CustomerContactsService,
            ],
        };
    }
}
