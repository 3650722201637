import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { HelpService, ICustomerContactInfo, IDisputeInvoiceInfo } from '../../common/services/help.service';
import { ICustomerDisputeInvoice } from '../../model/partials/customer-dispute-invoice';
import { CustomerDisputeInvoiceDynamicControlsPartial } from '../../model/partials/customer-dispute-invoice.form-controls';
import { Location } from '@angular/common';
import { RequestDocumentsDynamicControlsPartial } from '../../model/partials/request-documents.form-controls';
import { IRequestDocumentsInfo } from '../../model/partials/request-documents-info.copy';

@Component({
    selector: 'app-request-documents',
    templateUrl: './request-documents.component.html',
})
export class RequestDocumentsComponent implements OnInit {
    abstractRequestDocumentControls: any;
    requestDocumentsForm: UntypedFormGroup;
    requestDocInfo: IRequestDocumentsInfo;

    isLoading = false;
    invoiceId: number;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private helpService: HelpService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;

        this.invoiceId = this.getIdFromRoute(this.route, 'invoiceId');
        if (this.invoiceId) {
            this.helpService
                .getRequestDocumentInfo(this.invoiceId)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    this.requestDocInfo = result,
                    this.createForm(result);
                });
        } else {
            void this.router.navigate(['customer-invoices']); // if no id found, go back to list
        }
    }

    createForm(info: IRequestDocumentsInfo): void {
        this.getControls(info);
        this.requestDocumentsForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            requestDocumentsForm: this.fb.group({}),
        });
    }

    getControls(info: IRequestDocumentsInfo): void {
        this.abstractRequestDocumentControls = new RequestDocumentsDynamicControlsPartial(
            {
                Balance: info ? info.Balance : 0,
                Comments: null,
                ContactEmail: info ? info.ContactEmail : null,
                ContactName: info ? info.ContactName : null,
                CustomerName: info ? info.CustomerName : null,
                InvoiceDate: info ? info.InvoiceDate : null,
                InvoiceNumber: info ? info.InvoiceNumber : null,
                LocationName: info ? info.LocationName : null,
                Total: info ? info.Total : 0,
            },
            {
                formGroup: 'requestDocumentsForm',
            },
        ).Form;
    }

    formSubmitted(): void {
        if (this.requestDocumentsForm.valid) {
            const formValues = this.requestDocumentsForm.value.requestDocumentsForm as IRequestDocumentsInfo;
            this.isLoading = true;
            this.helpService
                .submitRequestDocuments(
                    {
                        Comments: formValues.Comments,
                        ContactEmail: formValues.ContactEmail,
                        ContactName: this.requestDocInfo.ContactName,
                    },
                    this.invoiceId,
                )
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe((result) => {
                    this.notificationsService.success('Thank you.  We will send the documents to you as quickly as possible.');
                    this.location.back();
                });
        } else {
            common.markAllFormFieldsAsTouched(this.requestDocumentsForm);
        }
    }

    cancel(): void {
        this.location.back();
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10)) ? null : parseInt(id, 10);
    }
}
