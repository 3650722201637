<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Companies</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
<br>
</div>

<mt-entity-list [entities]="companies" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getCompanies()" (itemSelectedEvent)="companySelected($event)" (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
</mt-entity-list>

<div *ngIf="canAddCompany" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/companies', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>