import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { RecurringPaymentService } from '../services/recurring-payment.service';
import { IRecurringPayment } from '../../model/interfaces/recurring-payment';
import { ClaimTypes } from '../../model/ClaimTypes';
import { finalize } from 'rxjs/operators';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    templateUrl: './recurring-payment-detail.component.html',
})
export class RecurringPaymentDetailComponent implements OnInit {
    recurringPayment: IRecurringPayment;
    canEdit: boolean;
    canAdd: boolean;
    isLoading = false;

    constructor(
        private recurringPaymentService: RecurringPaymentService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.RecurringPayments, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'recurringPaymentId');
        if (id) {
            this.getRecurringPaymentById(id);
        } else {
            void this.router.navigate(['recurring-payments']); // if no id found, go back to list
        }
    }

    getRecurringPaymentById(id: number): void {
        this.recurringPaymentService.getById(id).subscribe((recurringPayment) => {
            if (recurringPayment === null) {
                this.notificationsService.error('Recurring Payment not found');
                void this.router.navigate(['recurring-payments']);
            }
            this.recurringPayment = recurringPayment;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        return isNaN(parseInt(id, 10))  ? null : parseInt(id, 10);
    }

    cancelPayment(): void {
        this.modalService
            .showModal({
                cancelButtonText: 'No',
                confirmButtonColor: '#d33',
                confirmButtonText: 'Yes, cancel',
                showCancelButton: true,
                text: 'All future scheduled payments will not be processed.',
                title: 'Are you sure?',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.isLoading = true;
                    this.recurringPaymentService
                        .cancelPayment(this.recurringPayment.Id)
                        .pipe(finalize(() => (this.isLoading = false)))
                        .subscribe((result) => {
                            this.notificationsService.success('Recurring payment cancelled');
                            void this.router.navigate(['recurring-payments']);
                        });
                }
            });
    }
}
