<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Portal Access
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Portal Access</h4>
    <mt-dynamic-form [config]="formObject" ([isEditing])="isEditing" (submitted)="formSubmitted($event)">
        <div *ngIf="authUser">
            <button type="submit" mtDisableClickDuringHttpCalls class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" class="btn btn-flat btn-default" (click)="this.isEditing = false">
                Cancel
            </button>
        </div>
    </mt-dynamic-form>
    <div *ngIf="authUser">
        <button class="btn btn-flat pull-right btn-danger" (click)="changeAccess()">
            {{authUser?.HasAccess ? 'Revoke Access' : 'Grant Access'}}
        </button>
    </div>
    <br />
</div>
