import { formatCurrency } from '@angular/common';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { AdminInvoicesListActionComponent } from './admin-invoices-list-action.component';

export class InvoicePaymentsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['CustomerName'],
                    name: 'Customer Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['PaidByUserName'],
                    name: 'Paid By',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['SageAccountNumber'],
                    name: 'Customer Sage Account Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['InvoiceNo'],
                    name: 'InvoiceNo',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (i) => formatCurrency(i.PaymentAmount as number || 0, 'en_US', '$'),
                    name: 'Payment Amount',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['PaymentDate'],
                    name: 'Payment Date',
                    pipes: ['date:mediumDate:UTC'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: AdminInvoicesListActionComponent,
                    name: '',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
