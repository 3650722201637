import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { CustomerInvoiceBasicInfoComponent } from './customer-invoice-basic-info/customer-invoice-basic-info.component';
import { CustomerInvoiceDetailComponent } from './customer-invoice-detail/customer-invoice-detail.component';
import { CustomerInvoicesDocumentsPreviewComponent } from './customer-invoice-documents-preview/customer-invoice-documents-preview.component';
import { CustomerInvoiceHeaderComponent } from './customer-invoice-header/customer-invoice-header.component';
import { CustomerInvoicesListActionComponent } from './customer-invoice-list/customer-invoices-list-action.component';
import { CustomerInvoicesComponent } from './customer-invoice-list/customer-invoices.component';
import { CustomerInvoiceRoutingModule } from './customer-invoice-routing.module';
import { CustomerInvoiceService } from './services/customer-invoice.service';
import { DragScrollModule } from 'ngx-drag-scroll';
import { CustomerContactGroupsService } from '../common/services/customer-contact-groups.service';
import { CustomerContactLocationsService } from '../common/services/customer-contact-locations.service';
import { CustomerInvoiceAdminDocumentComponent } from './customer-invoice-admin-documents/customer-invoice-admin-document.component';
import { DisputeInvoiceComponent } from './dispute-invoice/dispute-invoice.component';
import { RequestDocumentsComponent } from './request-documents/request-documents.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

@NgModule({
    declarations: [
        CustomerInvoicesComponent,
        CustomerInvoiceHeaderComponent,
        CustomerInvoiceDetailComponent,
        CustomerInvoiceBasicInfoComponent,
        CustomerInvoicesListActionComponent,
        CustomerInvoicesDocumentsPreviewComponent,
        CustomerInvoiceAdminDocumentComponent,
        DisputeInvoiceComponent,
        RequestDocumentsComponent
    ],
    imports: [SharedModule,
        CustomerInvoiceRoutingModule,
        PdfViewerModule,
        DragScrollModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.15)',
            primaryColour: '#ffffff',
            secondaryColour: '#bbb',
            tertiaryColour: '#ffffff',
        }),
    ]
})
export class CustomerInvoiceModule {
    static forRoot(): any {
        return {
            ngModule: CustomerInvoiceModule,
            providers: [CustomerInvoiceService, CustomerContactGroupsService, CustomerContactLocationsService],
        };
    }
}
