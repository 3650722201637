import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomerLocation } from '@model/interfaces/customer-location';

export const emptyCustomerLocation: ICustomerLocation = {
    AddressId: null,
    CustomerGroupId: null,
    CustomerId: 0,
    Id: 0,
    Name: null,
    SageAccountNumber: '',
};

@Injectable({
    providedIn: 'root',
})
export class CustomerLocationsService extends BaseService<ICustomerLocation> {
    constructor(public http: HttpClient) {
        super('/customerlocations', http);
    }

    getEmptyCustomerLocation(): ICustomerLocation {
        return { ...emptyCustomerLocation };
    }
}
