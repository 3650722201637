import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { RecurringPaymentDynamicControls, IRecurringPaymentDynamicControlsParameters } from '../model/form-controls/recurring-payment.form-controls';
import { IRecurringPayment } from '../model/interfaces/recurring-payment';
import { IPaymentFrequency } from '@model/interfaces/payment-frequency';
import { ICustomerPaymentSource } from '@model/interfaces/customer-payment-source';
import { IUser } from '@model/interfaces/user';
import {
    IRecurringPaymentDynamicControlsParametersPartial,
    RecurringPaymentDynamicControlsPartial,
} from '../model/partials/recurring-payment-partial.form-controls';

export class RecurringPaymentDynamicConfig<T extends IRecurringPayment> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private recurringPayment: T,
        private frequencies: IPaymentFrequency[],
        private sources: ICustomerPaymentSource[],
        private users: IUser[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IRecurringPaymentDynamicControlsParametersPartial = {
            frequencies: this.frequencies,
            sources: this.sources,
            users: this.users,
        };
        const dynamicControls = new RecurringPaymentDynamicControlsPartial(this.recurringPayment, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['FrequencyId', 'Amount', 'SourceId', 'StartDate', 'NeverEnding', 'EndDate'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
