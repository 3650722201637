import { Component, Input, OnInit } from '@angular/core';
import { CustomerInvoiceService } from '../../customer-invoices/services/customer-invoice.service';
import { IInvoice } from '../../model/interfaces/invoice';

@Component({
    selector: 'app-invoice-pdf-preview',
    templateUrl: './invoice-pdf-preview.component.html',
})
export class InvoicePdfPreviewComponent implements OnInit {
    @Input() invoiceId: number;
    invoice: IInvoice;
    manifestPdfs: Map<number, any> = new Map();
    invoicePdf: any;

    constructor(private customerInvoiceService: CustomerInvoiceService) {}

    ngOnInit(): void {
        this.invoice = null;
        if (this.invoiceId) {
            this.customerInvoiceService.getById(this.invoiceId).subscribe((result) => {
                this.invoice = result;
                this.loadPdfs();
            });
        }
    }

    loadPdfs(): void {
        if (this.invoice.InvoiceDocuments && this.invoice.InvoiceDocuments.length) {
            this.getPdfForInvoice(this.invoice.InvoiceDocuments[0].DocumentId);
        }
        if (this.invoice.InvoiceManifests && this.invoice.InvoiceManifests.length) {
            this.invoice.InvoiceManifests.forEach((invoiceManifest) => {
                if (invoiceManifest.InvoiceManifestDocuments && invoiceManifest.InvoiceManifestDocuments.length) {
                    this.getPdfDocumentForManifest(invoiceManifest.InvoiceManifestDocuments[0].DocumentId, invoiceManifest.Id);
                }
            });
        }
    }

    getPdfForInvoice(documentId: number): void {
        this.customerInvoiceService.getDocument(documentId).subscribe((result) => {
            this.invoicePdf = result;
        });
    }
    getPdfDocumentForManifest(documentId: number, manifsetId: number): void {
        this.customerInvoiceService.getDocument(documentId).subscribe((result) => {
            this.manifestPdfs.set(manifsetId, result);
        });
    }
    getPdfForManifest(manifestId: number): any {
        return this.manifestPdfs.get(manifestId);
    }
}
