import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ICustomer } from '@model/interfaces/customer';
import { CustomerService } from '../services/customer.service';

@Component({
    templateUrl: './customer-header.component.html',
})
export class CustomerHeaderComponent implements OnInit, OnDestroy {
    customer: ICustomer;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private customerService: CustomerService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('customerId');
        // set the header based on the id
        if (id > 0) {
            this.customerService.getById(id).subscribe((customer) => {
                this.setHeader(customer);
            });
        } else {
            this.setHeader(this.customerService.getEmptyCustomer());
        }
        // subscribe to any changes in the customer service
        // which should update the header accordingly
        this.subscriptions.add(
            this.customerService.changeEmitted$.subscribe((customer) => {
                this.setHeader(customer);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(customer: ICustomer): void {
        this.customer = customer;
        this.header = customer && customer.Id ? `Customer: ${customer.Name}` : 'Add Customer';
    }
}
